import React from "react";
import { useAppSelector } from "../../store/hooks";
import CreateBoardForm from "./CreateBoardForm";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateBoardModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`${lightMode} modal-overlay`} onClick={handleOverlayClick}>
      <div className={`${lightMode} modal`}>
        <div className={`${lightMode} modal-content`}>
          <h3 className={`${lightMode} create-board-modal-title`}>
            Create new board
          </h3>
          <h4 className={`${lightMode} create-board-modal-sub`}>
            Allow people to join your board with the link beloow. Anyone who
            joins with this link will have the Member role.
          </h4>
          <CreateBoardForm isOpen={isOpen} onClose={onClose} />
        </div>
      </div>
    </div>
  );
};

export default CreateBoardModal;
