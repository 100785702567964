import { getImageFromLocalStorage } from "./imagesCache";

export async function getImageFromCacheOrFromURL(
  url: string,
  id: string
): Promise<string | undefined> {
  try {
    let img = await getImageFromLocalStorage(id);
    if (img) {
      return img;
    }
    const result = await (await fetch(url)).blob();
    img = URL.createObjectURL(result);
    return img;
  } catch (e) {
    console.error(`Error during image get, screenId: ${id}, err: ${e}`);
  }
}

export const fileToBase64 = (blob: Blob): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = () => {
      const base64String = reader.result;
      if (typeof base64String === "string") {
        resolve(base64String);
      } else {
        reject(new Error("Failed to convert Blob to base64."));
      }
    };

    reader.onerror = () => {
      reject(new Error("Failed to convert Blob to base64."));
    };
  });
};

export const imageDataToBase64String = async (url: string): Promise<string> => {
  return await fileToBase64(await (await fetch(url)).blob());
};
