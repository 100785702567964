import React, { useCallback, useEffect } from "react";
import { CreateProject } from "../../utils/backend/Project/CreateSuperBoard";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import BoardPlaceholder from "../../icons/BoardPlaceholder.png";
import { asyncNotification } from "../../store/slice/notificationSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import { GetAllProjects } from "../../utils/backend/Project/GetAllProjects";
import { setProjects } from "../../store/slice/workspacesSlice";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  superBoard: {
    name?: string;
    billingEmail?: string;
  };
  board: {
    name?: string;
    image?: string;
  };
}

const CreateSuperBoard: React.FC<ModalProps> = ({ onClose, isOpen }) => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    setError,
    reset,
  } = useForm<FormValues>();

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data) => {
      const controller = new AbortController();
      const signal = controller.signal;
      if (!data.board.name || !data.superBoard.name) {
        return;
      }

      try {
        const result = await CreateProject({
          superBoard: data.superBoard,
          board: data.board,
          signal: signal,
        });

        if (result.error) {
          setError("root", {
            message:
              result.error === "Conflict"
                ? "You can't have more than one free worksapce"
                : result.error,
          });
        }

        if (!result.error) {
          GetAllProjects().then((data) => {
            if (data.error.length > 0) {
              console.error(data.error);
              return;
            }
            dispatch(setProjects(data.response));
          });
          dispatch(
            asyncNotification({
              notification: "Workspace created.",
              isActive: true,
              isErrNotification: false,
            })
          );
          onClose();
        }
      } catch (error) {
        console.error(error);
        dispatch(
          asyncNotification({
            notification: "Creation failed.",
            isActive: true,
            isErrNotification: true,
          })
        );
        setError("root", {
          message: "Creation failed.",
        });
      }
    },
    [dispatch, onClose, setError]
  );

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  const loadFile = (event: any) => {
    const img = document.getElementById("output") as HTMLImageElement;
    const newOmg = URL.createObjectURL(event.target.files[0]);
    if (img != null) {
      img.src = newOmg;
      setValue("board.image", newOmg);
    }
  };

  return (
    <div className={`${lightMode} modal-overlay`} onClick={handleOverlayClick}>
      <div className={`${lightMode} modal`}>
        <div className={`${lightMode} modal-content`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={`${lightMode} create-board-modal-container workspace`}
            >
              <h3 style={{ margin: "0 0 23px 0" }}>New Workspace</h3>
              <h3 className={`${lightMode} create-board-modal-small-title`}>
                Name:
              </h3>
              <input
                type="text"
                id="superboard.name"
                className={`${lightMode} email sign-in-input`}
                style={
                  !errors.superBoard?.name
                    ? { marginBottom: "20px" }
                    : { marginBottom: "0" }
                }
                placeholder="Enter worksapce name"
                {...register("superBoard.name", { required: true })}
              />
              {errors.superBoard?.name && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "5px",
                    marginBottom: "20px",
                    fontWeight: 500,
                  }}
                >
                  {errors.superBoard && errors.superBoard.name
                    ? "Required"
                    : null}
                </div>
              )}
            </div>
            <h3 className={`${lightMode} create-board-modal-small-title`}>
              Team image
            </h3>
            <div className={`${lightMode} img-details create-board`}>
              <img
                id="output"
                width="40px"
                alt="user-icon"
                src={getValues().board?.image || BoardPlaceholder}
              />
              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                hidden
                onChange={(e) => loadFile(e)}
              />
              <label
                htmlFor="file"
                className={`${lightMode} change-img-button`}
              >
                Change
              </label>
            </div>
            <h3 className={`${lightMode} create-board-modal-small-title`}>
              Board name
            </h3>
            <input
              type="text"
              id="board.name"
              className={`${lightMode} email sign-in-input`}
              style={
                !errors.board?.name
                  ? { marginBottom: "20px" }
                  : { marginBottom: "0" }
              }
              placeholder="Enter board name"
              {...register("board.name", { required: true })}
            />
            {errors.board?.name && (
              <div
                className="create-board-modal-small-title"
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginTop: "5px",
                  marginBottom: "20px",
                  fontWeight: 500,
                }}
              >
                {errors.board && errors.board.name ? "Required" : null}
              </div>
            )}
            {errors.root && errors.root.message && (
              <h2 className={`${lightMode} log-error`}>
                {errors.root.message ? errors.root.message : null}
              </h2>
            )}
            <button
              type="submit"
              className={`${
                lightMode === "light" ? "light" : "dark"
              } save-button no-marguns`}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Loading..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateSuperBoard;
