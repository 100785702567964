import { Fetch, FetchInput, Method } from "../../Fetch";
import { Project } from "../../types/project";

export interface CreateProjectRequest {
	superBoard: {
		name?: string;
		billingEmail?: string;
	};
	board: {
		name?: string;
		image?: string;
		website?: string;
		industry?: string;
	};
	signal?: AbortSignal;
}

export interface CreateProjectResponse {
	response: {
		id: string;
		stripeCustomerId?: string;
	};
	error: string;
}

export async function CreateProject(
	request: CreateProjectRequest
): Promise<CreateProjectResponse> {
	const CreateProject = `/me/project`;

	const empty = {
		id: "",
		name: "",
		createdAt: new Date(Date.now()),
		updatedAt: new Date(Date.now()),
	};

	const requestBody = JSON.stringify({
		superBoard: request.superBoard,
		board: request.board,
	});
	try {
		const input: FetchInput = {
			method: Method.Post,
			url: CreateProject,
			signal: request.signal,
			body: requestBody,
		};
		const result = await Fetch(input);

		if (result.isOk) {
			const response = await result;

			if (response) {
				return {
					response: response.result.result,
					error: "",
				};
			}

			return {
				response: empty,
				error: "Server side error",
			};
		} else {
			return {
				response: empty,
				error: result.error?.message ?? "",
			};
		}
	} catch (err) {
		console.error(err);
		return {
			response: empty,
			error: "error",
		};
	}
}
