import "./Flows.css";
import Flow from "../Flow/Flow";
import { useEffect, useRef, useState } from "react";
import { Flow as FlowType } from "../../../utils/types/flow";
import { Tag } from "../../../utils/types/tag";
import { useAppSelector } from "../../../store/hooks";
import { fetchFlows } from "./initialize";
import { queryData } from "../../../utils/types/queryData";
import {
	arraysHaveSameElements,
	usePrevious,
} from "../../../utils/usePrevious";
import Loader from "../../Loader/Loader";
import ErrorPage from "../../ErrorPage/ErrorPage";
import NoResultsFoundPage from "../../NoResultsFoundPage/NoResultsFoundPage";
import Filters from "../../FiltersComponent/Filters";
import { StyledGridDivFlowPage } from "./Flows.style";

const Flows = () => {
	const allTagTypesWithTags = useAppSelector(
		(state) => state.tags.allTagTypesWithTags
	);
	const [companyTags, setCompanyTags] = useState<Tag[]>([]);
	const [flows, setFlows] = useState<FlowType[]>([]);
	const [queryState, setQueryState] = useState<queryData>({
		limit: 108,
		page: 0,
	});
	const [seedValue, setSeedValue] = useState(Math.random());
	const [isLoadingMore, setIsLoadingMore] = useState(true);

	const [error, setError] = useState<string>();
	const [isLastPage, setIsLastPage] = useState<boolean>();
	const activeFilters = useAppSelector((state) => state.filters.activeFilters);
	const shouldDisplayFilters: any = useAppSelector(
		(state) => state.filters.isFilterOpened
	);

	const previousActiveFilters = usePrevious<string[]>(activeFilters);
	const previousQueryState = usePrevious<queryData>(queryState);

	const loader = useRef(null);
	const [innerWidth, setInnerWidth] = useState<number>(
		document.body.clientWidth
	);
	const isLocked = useAppSelector((state) => state.navigation.limitExceeded);

	const handleResize = () => {
		setInnerWidth(document.body.clientWidth);
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [isLoadingMore]);

	useEffect(() => {
		if (loader.current && flows.length > 0) {
			const options = {
				root: null,
				rootMargin: "20px",
				threshold: 1.0,
			};
			const observer = new IntersectionObserver(handleObserver, options);
			observer.observe(loader.current);

			return () => {
				if (loader && loader.current) {
					observer.unobserve(loader.current);
				}
			};
		}
	}, [flows]);

	const handleObserver = (entities: IntersectionObserverEntry[]) => {
		const target = entities[0];
		if (target.isIntersecting && flows.length > 0) {
			handleLoadMore();
		}
	};

	useEffect(() => {
		if (
			!!previousActiveFilters &&
			arraysHaveSameElements(previousActiveFilters, activeFilters)
		)
			return;
		setIsLastPage(false);
		if (activeFilters.length !== 0) {
			if (queryState.page !== 0) {
				setQueryState({
					page: 0,
					limit: 108,
				});
			}
			setSeedValue(Math.random());
		}
		setFlows([]);
	}, [activeFilters, previousActiveFilters]);

	const fetchFlowsByActiveFilter = async (signal: AbortSignal) => {
		setError(undefined);
		setIsLoadingMore(true);
		const filterString: Array<string> = [];
		const industryTagList = allTagTypesWithTags.find(
			(tag) => tag.tagType.name === "Industry"
		)?.tagList;
		const companiesTagList = allTagTypesWithTags.find(
			(tag) => tag.tagType.name === "Company"
		)?.tagList;
		const query: queryData = {
			limit: 108,
			page: queryState.page,
		};

		activeFilters.map((filter) => {
			if (industryTagList?.find((tag) => tag.id === filter)) {
				companiesTagList?.map((company) => {
					if (company.tagList.find((t) => t.id === filter)) {
						filterString.push(company.id);
					}
				});
			} else {
				filterString.push(filter);
			}
		});

		await fetchFlows({
			queryState: query,
			setFlows,
			activeFilters: filterString,
			signal,
			setError,
			setIsLastPage,
			seed: seedValue,
		});
	};

	useEffect(() => {
		setCompanyTags(
			allTagTypesWithTags.find((tt) => {
				return tt.tagType.name === "Company";
			})?.tagList ?? []
		);
	}, [allTagTypesWithTags]);

	useEffect(() => {
		if (
			!!previousQueryState &&
			queryState.page === previousQueryState.page &&
			queryState.limit === previousQueryState.limit &&
			!!previousActiveFilters &&
			arraysHaveSameElements(activeFilters, previousActiveFilters)
		)
			return;

		if (
			flows.length === 0 &&
			activeFilters.length > 0 &&
			previousActiveFilters === undefined
		)
			return;
		if (
			activeFilters.length === 0 &&
			(flows.length > 0 || flows.length === 0) &&
			queryState.page === flows.length / 108 - 1 &&
			!isLoadingMore
		) {
			setQueryState((prev) => {
				return {
					...prev,
					limit: 108,
				};
			});

			const controller = new AbortController();
			const signal = controller.signal;
			setIsLoadingMore(true);
			fetchFlowsByActiveFilter(signal)
				.catch((e) => {
					console.error(e);
					setError("Can't get data from server");
				})
				.finally(() => {
					setIsLoadingMore(false);
				});
		} else {
			const controller = new AbortController();
			const signal = controller.signal;
			setIsLoadingMore(true);
			fetchFlowsByActiveFilter(signal)
				.catch((e) => {
					console.error(e);
					setError("Can't get data from server");
				})
				.finally(() => {
					setIsLoadingMore(false);
				});
		}
	}, [activeFilters, queryState, previousActiveFilters, previousQueryState]);

	const handleLoadMore = () => {
		if (isLocked) return;
		if (!isLastPage)
			setQueryState((prev) => {
				return {
					...prev,
					page: prev.page + 1,
				};
			});
	};

	const displayFlows = flows.map((flow: FlowType, index) => {
		let companyName: string | undefined;
		let companyIcon: string | undefined;
		if (companyTags && companyTags.length) {
			flow.tagList.forEach((tag) => {
				const company = companyTags.find((c) => {
					return c.id === tag.id && c.noOfFlows && c.noOfFlows > 0;
				});
				if (!company) return;
				companyName = company.name;
				companyIcon = company.imageS3URL ?? undefined;
			});
		}

		flow = { ...flow, icon: companyIcon };
		flow.company = companyName;
		if (index < (queryState.page + 1) * 36)
			return (
				<Flow
					flow={flow}
					key={flow.id}
					image={
						flow.contentList.length > 0 &&
						flow.contentList[0].presignedURLResized
							? flow.contentList[0].presignedURLResized
							: flow.contentList.length > 0 && flow.contentList[0].presignedURL
							? flow.contentList[0].presignedURL
							: ""
					}
				/>
			);
	});
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<div className={`${lightMode} flows`}>
			<div
				className={`${lightMode} grid-container`}
				id="grid-container"
				style={shouldDisplayFilters ? { display: "flex" } : {}}>
				{flows.length === 0 && isLoadingMore && <Loader />}
				{flows.length === 0 && !isLoadingMore && !error && (
					<NoResultsFoundPage />
				)}
				{error && error.length > 0 && <ErrorPage />}
				{flows.length > 0 && (
					<StyledGridDivFlowPage
						areFiltersOpen={shouldDisplayFilters}
						innerWidth={innerWidth}>
						{flows && displayFlows}
					</StyledGridDivFlowPage>
				)}
				<Filters displaysFrom="flows" />
			</div>

			<div ref={loader} style={{ height: "10px" }}></div>
		</div>
	);
};
export default Flows;
