import { Fetch, FetchInput, Method } from "../../Fetch";

export interface InviteToSuperBoardRequest {
  userEmail: string;
  boardId: string;
  role: string;
  signal?: AbortSignal;
}

export interface InviteToBoardResponse {
  error: string;
  response: any;
}

export async function InviteToSuperboard(
  request: InviteToSuperBoardRequest
): Promise<InviteToBoardResponse> {
  const InviteToBoardURL = "/me/project/invite";
  const requestBody = JSON.stringify({
    userEmail: request.userEmail,
    projectId: request.boardId,
    role: request.role.toLowerCase(),
  });
  try {
    const input: FetchInput = {
      method: Method.Post,
      url: InviteToBoardURL,
      signal: request.signal,
      body: requestBody,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          error: "",
          response: response,
        };
      }

      return {
        error: "Server side error",
        response: "",
      };
    } else {
      return {
        error: result.error?.message ?? "",
        response: "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      error: "error",
      response: "",
    };
  }
}
