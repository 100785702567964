import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { closeNotification } from "../../store/slice/notificationSlice";
import closeIcon from "../../icons/close.svg";
import closeIconDark from "../../icons/closeWhite.svg";
import exclamationMark from "../../icons/exclamationMark.png";
import { useState } from "react";
import UpgradePlanModal from "../UpgradePlanModal/UpgradePlanModal";

const Notification = () => {
  const notification = useAppSelector((state) => state.notification);
  const dispatch = useAppDispatch();
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false);

  return (
    <>
      <UpgradePlanModal
        isOpen={upgradeOpen}
        onClose={() => setUpgradeOpen(false)}
        type="new"
      />
      {notification.notification.length > 0 &&
        notification.isActive &&
        (!notification.notification.includes(
          "Lifetime license ($97) available"
        ) ? (
          <div className={`${lightMode} notification-container`}>
            <div
              className={`${lightMode}  update-toggle-container`}
              style={
                notification.isErrNotification ? { backgroundColor: "red" } : {}
              }
            >
              <div className={`${lightMode}  update-toggle`}></div>
            </div>
            {notification.notification}
            <div className={`${lightMode} notification-separator`}></div>
            <div
              onClick={() => {
                dispatch(closeNotification());
              }}
            >
              <img
                className={`${lightMode} notification-close-icon`}
                src={lightMode === "light" ? closeIcon : closeIconDark}
              />
            </div>
          </div>
        ) : (
          <div className={`${lightMode} notification-container higher`}>
            <img
              className={`${lightMode} notification-close-icon`}
              src={exclamationMark}
            />
            {notification.notification}
            <button
              style={{
                backgroundColor: "#1380fc",
                color: "white",
                padding: "5px 10px",
                border: "none",
                borderRadius: "6px",
                margin: "0",
              }}
              onClick={() => {
                setUpgradeOpen(true);
                dispatch(closeNotification());
              }}
            >
              Learn more
            </button>
            <div className={`${lightMode} notification-separator`}></div>
            <div
              onClick={() => {
                dispatch(closeNotification());
              }}
            >
              <img
                className={`${lightMode} notification-close-icon`}
                src={lightMode === "light" ? closeIcon : closeIconDark}
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default Notification;
