import { Fetch, FetchInput, Method } from "../../Fetch";

export interface RemoveUserFromBoardRequest {
	userId: string;
	typeId: string;
	signal?: AbortSignal;
	type: "workspace" | "project";
}

export interface RemoveUserFromBoardResponse {
	error: string;
}

export async function RemoveUserFromBoard(
	request: RemoveUserFromBoardRequest
): Promise<RemoveUserFromBoardResponse> {
	const DeleteFromCollectionURL = `/me/${request.type}/remove_user`;

	const requestBody = JSON.stringify({
		userId: request.userId,
		[request.type + "Id"]: request.typeId,
	});

	try {
		const input: FetchInput = {
			method: Method.Post,
			body: requestBody,
			url: DeleteFromCollectionURL,
			signal: request.signal,
		};
		const result = await Fetch(input);
		if (result.isOk) {
			const response = await result;
			if (response) {
				return {
					error: "",
				};
			}
			return {
				error: "Server side error",
			};
		} else {
			return {
				error: result.error?.message ?? "",
			};
		}
	} catch (err) {
		console.error(err);
		return {
			error: "error",
		};
	}
}
