import "./ErrorPage.css";
import Cautions from "../../icons/Cautions.svg";
import CautionsDark from "../../icons/CautionsDark.svg";
import { useAppSelector } from "../../store/hooks";

const MobileOnlyErrorPage = () => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  return (
    <div className={`${lightMode} error-page-container`}>
      <div className={`${lightMode} error-page-img-container`}>
        <img
          style={{ height: "20px", width: "20px" }}
          src={lightMode === "light" ? Cautions : CautionsDark}
        />
      </div>
      <div className={`${lightMode} error-page-text`}>Desktop app only.</div>
      <div className={`${lightMode} error-page-subtext`}>
        Our application is currently available exclusively on desktop.
      </div>
    </div>
  );
};

export default MobileOnlyErrorPage;
