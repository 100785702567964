import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { CreateWorkspace } from "../../utils/backend/Workspace/CreateWorkspace";
import defaultBoardImage from "../../icons/default_board_image.png";
import { imageDataToBase64String } from "../../utils/image";
import { asyncNotification } from "../../store/slice/notificationSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import { GetAllProjects } from "../../utils/backend/Project/GetAllProjects";
import {
  setProjects,
  setSelectedProject,
} from "../../store/slice/workspacesSlice";

interface FormValues {
  name: string;
  industry: string;
  image: any;
  website: string;
  id: string;
}

const CreateBoardForm: React.FC<{
  isOpen: boolean;
  onClose(): void;
}> = ({ isOpen, onClose }) => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  const projectId = useAppSelector((state) => state.boards.selectedProject?.id);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    setError,
    reset,
  } = useForm<FormValues>();

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data) => {
      const controller = new AbortController();
      const signal = controller.signal;
      if (data.name.length === 0) {
        return;
      }

      try {
        let imageBase64: string | undefined = undefined;
        if (data.image) {
          imageBase64 = await imageDataToBase64String(data.image);
        }
        data.image = imageBase64;

        if (projectId) data.id = projectId;
        const result = await CreateWorkspace({
          ...data,
          signal: signal,
        });
        if (result) {
          GetAllProjects().then((data) => {
            if (data.error.length > 0) {
              console.error(data.error);
              return;
            }
            dispatch(setProjects(data.response));
            const updatedSelectedProject = data.response.find(
              (pr) => pr.id === projectId
            );
            if (updatedSelectedProject)
              dispatch(setSelectedProject(updatedSelectedProject));
          });
          dispatch(
            asyncNotification({
              notification: "Board created.",
              isActive: true,
              isErrNotification: false,
            })
          );
          onClose();
        }
      } catch (error) {
        setError("root", {
          message: "Failed to create board",
        });
        console.error(error);
      }
    },
    [dispatch, onClose, projectId, setError]
  );

  const loadFile = (event: any) => {
    const img = document.getElementById("output") as HTMLImageElement;
    if (img != null) {
      const newImg = URL.createObjectURL(event.target.files[0]);
      img.src = newImg;
      setValue("image", newImg);
    }
  };

  return (
    <div className="create-board-modal-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className={`${lightMode} create-board-modal-small-title`}>
          Team image
        </h3>
        <div className={`${lightMode} img-details create-board`}>
          <img
            id="output"
            width="40px"
            alt="user-icon"
            src={getValues()?.image || defaultBoardImage}
          />
          <input
            type="file"
            accept="image/*"
            name="image"
            id="file"
            hidden
            onChange={(e) => loadFile(e)}
          />
          <label htmlFor="file" className={`${lightMode} change-img-button`}>
            Change
          </label>
        </div>
        <h3 className={`${lightMode} create-board-modal-small-title`}>
          Board name
        </h3>
        <input
          type="text"
          id="superboard.name"
          className={`${lightMode} email sign-in-input`}
          style={
            !errors?.name ? { marginBottom: "20px" } : { marginBottom: "0" }
          }
          placeholder="Enter worksapce name"
          {...register("name", { required: true })}
        />
        {errors?.name && (
          <div
            style={{
              color: "red",
              fontSize: "12px",
              marginTop: "5px",
              marginBottom: "20px",
              fontWeight: 500,
            }}
          >
            {errors && errors.name ? "Required" : null}
          </div>
        )}
        {errors.root && errors.root.message && (
          <h2 className={`${lightMode} log-error`}>
            {errors.root.message ? errors.root.message : null}
          </h2>
        )}
        <button
          type="submit"
          disabled={isSubmitting}
          className={`${lightMode} save-button no-margins`}
        >
          {isSubmitting ? "Loading..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default CreateBoardForm;
