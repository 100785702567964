import threeDots from "../../../icons/three-dots.svg";
import threeDotsDark from "../../../icons/three-dots-dark.svg";
import { Member } from "../../../utils/types/member";
import { useAppSelector } from "../../../store/hooks";
import Avatar from "react-avatar";
import { useEffect, useRef, useState } from "react";

import "./DropdownFromButton.css";
import SingleMember from "./SingleMember";
interface MemberListProps {
  members: Member[];
  invites: any[];
  error?: string;
  selected: string;
  userRole?: string;
  removeInvitation: (member: any) => void;
}
const Members = ({
  members,
  selected,
  removeInvitation,
  userRole,
}: MemberListProps) => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);

  const displayMembers = members.map((member: Member | any, index) => {
    return (
      <SingleMember
        member={member}
        userRole={userRole}
        selected={selected}
        removeInvitation={removeInvitation}
      />
    );
  });

  const displayEmptyMemberList = () => {
    return (
      <div className={`${lightMode} no-members-found-text`}>
        No {selected === "Members" ? "members" : "invites"} found
      </div>
    );
  };

  return (
    <div>{members.length > 0 ? displayMembers : displayEmptyMemberList()}</div>
  );
};
export default Members;
