import { Fetch, FetchInput, Method } from "../../Fetch";

export interface FetchPaymentPlanResponse {
	response: string;
	error: string;
}
export interface SendPaymentPlanRequest {
	SuperBoardID: string;
	signal?: AbortSignal;
}

export async function FetchPaymentPlan(
	request: SendPaymentPlanRequest
): Promise<FetchPaymentPlanResponse> {
	const SendPlan = `/me/payment_plan/current?projectId=${request.SuperBoardID}`;

	try {
		const input: FetchInput = {
			method: Method.Get,
			url: SendPlan,
			signal: request.signal,
		};
		const result = await Fetch(input);

		if (result.isOk) {
			const response = await result;
			if (response) {
				return {
					response: response.result.result,
					error: "",
				};
			}

			return {
				response: "",
				error: "Server side error",
			};
		} else {
			return {
				response: "",
				error: result.error?.message ?? "",
			};
		}
	} catch (err) {
		console.error(err);
		return {
			response: "",
			error: "error",
		};
	}
}
