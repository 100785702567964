import { useAppSelector } from "../../store/hooks";
import { SwitcherState } from "./SignPage";

interface SignSwitcherProps {
  setSwitcher: (switchState: string) => void;
  switchState: string;
}

const SignInSwitcher = ({ setSwitcher, switchState }: SignSwitcherProps) => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);

  return (
    <div className={`${lightMode} switcher-container-sign-page`}>
      <button
        id={SwitcherState.SignUp}
        onClick={() => setSwitcher(SwitcherState.SignUp)}
        className={
          switchState === SwitcherState.SignUp
            ? "switcher-all-screens-sign-page active"
            : "switcher-all-screens-sign-page"
        }
      >
        Sign Up
      </button>
      <button
        id="log-in"
        onClick={() => setSwitcher(SwitcherState.Login)}
        className={
          switchState === SwitcherState.Login
            ? "switcher-all-screens-sign-page active"
            : "switcher-all-screens-sign-page"
        }
      >
        Log In
      </button>
    </div>
  );
};

export default SignInSwitcher;
