import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AcceptInvite,
  AcceptInviteRequest,
} from "../../utils/backend/Invites/AcceptInvite";
import { Fragment, useEffect, useState } from "react";

const AcceptInvitation = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined>();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const email = searchParams.get("email");
    const boardId = searchParams.get("boardId");
    const newUser = searchParams.get("newUser");
    if (email && boardId) {
      const handleAcceptInvite = async (req: AcceptInviteRequest) => {
        const result = await AcceptInvite(req);
        if (result.error.length > 0) {
          setError(
            "Something unexpected happened, try to reload page or contact us."
          );
          console.error(result.error);
          return;
        }
        newUser === "true" ? navigate("/") : navigate("/SignIn");
      };

      const req: AcceptInviteRequest = {
        userEmail: email,
        boardId: boardId,
        signal,
      };
      try {
        handleAcceptInvite(req);
      } catch (e) {
        setError(
          "Something unexpected happened, try to reload page or contact us."
        );
        console.error(e);
      }
    }
  }, []);

  return <Fragment></Fragment>;
};
export default AcceptInvitation;
