import { Fetch, FetchInput, Method } from "../../Fetch";

export interface UpdateProjectRequest {
	id: string | undefined;
	name?: string | undefined | null;
	image?: string | undefined | null;
	website?: string | undefined | null;
	signal?: AbortSignal;
}

export interface UpdateProjectResponse {
	error: string;
}

export async function UpdateProject(
	request: UpdateProjectRequest
): Promise<UpdateProjectResponse> {
	const UpdateProjectURL = "/me/project";
	const requestBody = JSON.stringify({
		id: request.id,
		name: request.name,
		image: request.image,
		website: request.website,
	});

	try {
		const input: FetchInput = {
			method: Method.Put,
			url: UpdateProjectURL,
			signal: request.signal,
			body: requestBody,
		};
		const result = await Fetch(input);

		if (result.isOk) {
			const response = await result;
			if (response) {
				return {
					error: "",
				};
			}

			return {
				error: "Server side error",
			};
		} else {
			return {
				error: result.error?.message ?? "",
			};
		}
	} catch (err) {
		console.error(err);
		return {
			error: "error",
		};
	}
}
