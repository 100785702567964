import Avatar from "react-avatar";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import "./SelectWorkspace.css";
import {
	setSelectedBoard,
	setSelectedProject,
} from "../../store/slice/workspacesSlice";
import arrowRight from "../../icons/arrow-right-workspace.svg";
import arrowRightDark from "../../icons/arrow-right-workspace-dark.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Project } from "../../utils/types/project";
import CreateSuperBoard from "../ProfileSettings/CreateSuperBoard";

const SelectWorkspace = (props: any) => {
	const lightMode = useAppSelector((state: any) => state.navigation.lightMode);
	const user = useAppSelector((state: any) => state.auth);
	const workspaces = useAppSelector((state: any) => state.boards.projects);
	const currentWorkspace = useAppSelector(
		(state: any) => state.boards.selectedProject
	);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [shouldShowMore, setShouldShowMore] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	return (
		<>
			<div>
				<CreateSuperBoard isOpen={isOpen} onClose={() => setIsOpen(false)} />
				<h1 className={`${lightMode} workspace-page-header`}>
					Choose your workspace
				</h1>
				<h4 className={`${lightMode} workspace-page-subheader`}>
					Choose a superboard below to get back to working with your team.
				</h4>
				<div className={`${lightMode} select-workspace-container`}>
					<span className={`${lightMode} select-workspace-header`}>
						Workspaces for <b>{user.email}</b>
					</span>
					<div>
						{workspaces.map((workspace: Project, index: any) => (
							<>
								{index < 5 && (
									<div
										className={`${lightMode} workspace-select-row ${
											currentWorkspace?.id === workspace.id ? "selected" : ""
										}`}>
										<Avatar
											name={
												workspace.name ? workspace.name[0].toUpperCase() : ""
											}
											initials={
												workspace.name ? workspace.name[0].toUpperCase() : ""
											}
											className={`${lightMode} workspace-avatar`}
											value="1"
											maxInitials={1}
											textSizeRatio={2}
											color="#f2f4f7"
											size="46px"
											fgColor="rgba(71, 84, 103, 1)"
											src={workspace.image ?? ""}
										/>
										<span className={`${lightMode} workspace-header`}>
											{workspace.name}
											<div className={`${lightMode} workspace-subheader`}>
												{workspace.name}
											</div>
										</span>
										<button
											onClick={() => {
												if (workspace.boards && workspace.boards?.length > 0)
													dispatch(setSelectedBoard(workspace.boards[0]));
												navigate(`/Navigation/Superboard/${workspace.id}`);
												dispatch(setSelectedProject(workspace));
											}}
											className={`${lightMode} workspace-view-button`}>
											{currentWorkspace?.id === workspace.id
												? "Selected"
												: "Open	"}{" "}
											{currentWorkspace?.id !== workspace.id && (
												<img
													src={
														lightMode === "light" ? arrowRight : arrowRightDark
													}
												/>
											)}
										</button>
									</div>
								)}
								{index >= 5 && shouldShowMore && (
									<div
										className={`${lightMode} workspace-select-row ${
											currentWorkspace?.id === workspace.id ? "selected" : ""
										}`}>
										<Avatar
											name={
												workspace.name ? workspace.name[0].toUpperCase() : ""
											}
											initials={
												workspace.name ? workspace.name[0].toUpperCase() : ""
											}
											className={`${lightMode} workspace-avatar`}
											value="1"
											maxInitials={1}
											textSizeRatio={2}
											color="#f2f4f7"
											size="46px"
											fgColor="rgba(71, 84, 103, 1)"
											src={workspace.image ?? ""}
										/>
										<span className={`${lightMode} workspace-header`}>
											{workspace.name}
											<div className={`${lightMode} workspace-subheader`}>
												{workspace.name}
											</div>
										</span>
										<button
											onClick={() => {
												if (workspace.boards && workspace.boards?.length > 0)
													dispatch(setSelectedBoard(workspace.boards[0]));
												navigate(`/Navigation/Superboard/${workspace.id}`);
												dispatch(setSelectedProject(workspace));
											}}
											className={`${lightMode} workspace-view-button`}>
											{currentWorkspace?.id === workspace.id
												? "Selected"
												: "Open	"}{" "}
											{currentWorkspace?.id !== workspace.id && (
												<img
													src={
														lightMode === "light" ? arrowRight : arrowRightDark
													}
												/>
											)}
										</button>
									</div>
								)}
								{index < workspaces.length - 1 && (
									<div className={`${lightMode} workspace-separator`}></div>
								)}
							</>
						))}
					</div>
					<div className={`${lightMode} workspace-show-more-btn-container`}>
						<p
							onClick={() => setShouldShowMore(true)}
							className={`${lightMode} workspace-show-more-btn`}>
							{workspaces.length - 1 > 5 &&
								!shouldShowMore &&
								`Show ${workspaces.length - 1} more superboars`}
						</p>
					</div>
				</div>
				<button
					onClick={() => setIsOpen(true)}
					className={`${lightMode} workspace-create-more-btn`}>
					Create new workspace
				</button>
			</div>
		</>
	);
};
export default SelectWorkspace;
