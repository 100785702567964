import { useEffect, useRef, useState } from "react";
import threeDots from "../../../icons/three-dots.svg";
import threeDotsDark from "../../../icons/three-dots-dark.svg";
import { Member } from "../../../utils/types/member";
import { useAppSelector } from "../../../store/hooks";
import Avatar from "react-avatar";
import "../../../components/ProfileSettings/ProfileSettings.css";
import EditImg from "../../../icons/edit-3.svg";
import EditImgDark from "../../../icons/edit-3-dark.svg";
import Trash from "../../../icons/trash-2.svg";
import EditRoleModal from "./EditRoleModal";
import { asyncNotification } from "../../../store/slice/notificationSlice";
import {
	RemoveUserFromBoard,
	RemoveUserFromBoardRequest,
} from "../../../utils/backend/Workspace/RemoveUserFromBoard";

interface SingleMemberProps {
	member: Member;
	selected: string;
	userRole?: string;
	removeInvitation: (member: any) => void;
}

const SingleMember = ({
	member,
	selected,
	userRole,
	removeInvitation,
}: SingleMemberProps) => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const currentUser = useAppSelector((state) => state.auth.id);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const board = useAppSelector((state) => state.boards.selectedBoard);
	const project = useAppSelector((state) => state.boards.selectedProject);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside);

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const toggleDropdown = () => {
		setIsOpen(true);
	};

	const handleRemoveBoardUser = async (member: Member) => {
		setIsOpen(false);
		try {
			const controller = new AbortController();
			const signal = controller.signal;
			if ((project || board) && (board?.id || project?.id)) {
				const req: RemoveUserFromBoardRequest = {
					userId: member.id,
					type: window.location.pathname.includes("BoardSettings")
						? "workspace"
						: "project",
					signal: signal,
					typeId: window.location.pathname.includes("BoardSettings")
						? board?.id!
						: project?.id!,
				};
				const result = await RemoveUserFromBoard(req);

				if (result) {
					asyncNotification({
						notification: "User removed",
						isActive: true,
						isErrNotification: false,
					});
				}
			}
		} catch (error) {
			asyncNotification({
				notification: "Failed to remove user",
				isActive: true,
				isErrNotification: true,
			});
		}
	};

	return (
		<>
			<EditRoleModal
				member={member}
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			/>
			<div className={`${lightMode} member`}>
				<div className={`${lightMode} flex-table-left`}>
					<Avatar
						key={"output"}
						name={
							member.fullName
								? member.fullName[0].toUpperCase()
								: member.email
								? member.email[0].toUpperCase()
								: ""
						}
						initials={
							member.fullName
								? member.fullName[0].toUpperCase()
								: member.email
								? member.email[0].toUpperCase()
								: ""
						}
						value="1"
						className={`${lightMode} workspace-avatar small-rounded`}
						maxInitials={1}
						textSizeRatio={2}
						color="#f2f4f7"
						size="40px"
						fgColor="rgba(71, 84, 103, 1)"
						src={member.imageS3URL ?? ""}
					/>
					<div className={`${lightMode} member-info`}>
						<h3 className={`${lightMode} member-name`}>{member.fullName}</h3>
						<h4 className={`${lightMode} member-email`}>{member.email}</h4>
					</div>
				</div>
				<div className={`${lightMode} flex-table-center`}>{member.role}</div>
				<div className={`${lightMode} flex-table-right`}>
					{selected == "Members" ? (
						<>
							{userRole === "admin" &&
								currentUser !== member.id &&
								member.role !== "admin" && (
									<div className="dropdown" ref={dropdownRef}>
										<div
											onClick={toggleDropdown}
											className={`${lightMode} img three-dot-contaienr`}>
											<img
												src={lightMode === "light" ? threeDots : threeDotsDark}
												alt=""
											/>
											{isOpen && (
												<div className={`${lightMode} dropdown-content`}>
													<div
														className={`${lightMode} dropdown-content-div`}
														onClick={() => setIsModalOpen(true)}>
														<img
															src={
																lightMode === "light" ? EditImg : EditImgDark
															}
														/>{" "}
														Edit role
													</div>
													<div
														className={`${lightMode} dropdown-content-div`}
														style={{ color: "#EB5757" }}
														onClick={() => handleRemoveBoardUser(member)}>
														<img src={Trash} />
														Deactivate member
													</div>
												</div>
											)}
										</div>
									</div>
								)}
						</>
					) : (
						<>
							{userRole === "admin" && (
								<button
									onClick={() => removeInvitation(member)}
									className={`${lightMode} remove-invitation-btn`}>
									Remove invitation
								</button>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default SingleMember;
