import React, { FC } from "react";
import copy from "../../icons/copy.svg";
import visit from "../../icons/visit.svg";
import copyDark from "../../icons/copyDark.svg";
import visitDark from "../../icons/visitDark.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { asyncNotification } from "../../store/slice/notificationSlice";

interface MoreDropdownProps {
  website: string;
  closeDropdown: () => void;
  isOpen: boolean;
}

const MoreDropdown: FC<any> = ({
  website,
  closeDropdown,
  isOpen,
}: MoreDropdownProps) => {
  const dispatch = useAppDispatch();

  const openNewTab = () => {
    window.open(website, "_blank");
  };

  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  return (
    <>
      <div className={`${lightMode} options-3`}>
        <button
          onClick={async () => {
            navigator.clipboard
              .writeText(window.location.href)
              .then(() => {
                dispatch(
                  asyncNotification({
                    notification: "Copied to clipboard.",
                    isActive: true,
                  })
                );
                closeDropdown();
              })
              .catch((err) => {
                asyncNotification({
                  notification: err,
                  isActive: true,
                  isErrNotification: true,
                });
                closeDropdown();
              });
          }}
          className={`${lightMode} option-dropdown`}
        >
          <img src={lightMode === "light" ? copy : copyDark} alt="copy-icon" />
          Copy link
        </button>
        {website && (
          <button
            className={`${lightMode} option-dropdown`}
            onClick={() => openNewTab()}
            style={{ borderRadius: "0 0 8px 8px" }}
          >
            <img
              src={lightMode === "light" ? visit : visitDark}
              alt="visit-icon"
            />
            Visit website
          </button>
        )}
      </div>
    </>
  );
};

export default MoreDropdown;
