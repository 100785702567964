import "./Navigation.css";
import Logo from "../../icons/favicon.png";

import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { GetUser } from "../../utils/backend/Profile/GetUser";
import { useEffect, useMemo, useRef, useState } from "react";
import upDown from "../../icons/up-down.svg";
import { GetAllProjects } from "../../utils/backend/Project/GetAllProjects";
import { DynamicIsland } from "../DynamicIsland";
import { FetchTags } from "../../utils/backend/Tag/FetchTags";
import { FetchAllTagTypes } from "../../utils/backend/TagType/FetchAllTagTypes";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getInitialAllTagTypesWithTags,
  getTags,
  getTagsByType,
  getTagTypes,
  setAllTagTypesWithTags,
} from "../../store/slice/tagSlice";
import {
  historyPush,
  setBoardId,
  setIfLimitIsExceeded,
  setLockLimit,
  setProjectId,
  setShouldBlockKeyboardNavigation,
} from "../../store/slice/navigationSlice";
import Avatar from "react-avatar";
import { removeUser, setUser } from "../../store/slice/authSlice";
import { Workspace } from "../../utils/types/workspace";
import {
  setProjects,
  setSelectedBoard,
  setSelectedPaymentPlan,
  setSelectedProject,
  setWorkspaces,
} from "../../store/slice/workspacesSlice";
import { getImageFromCacheOrFromURL } from "../../utils/image";
import { setActiveFilters } from "../../store/slice/filtersSlice";
import DarkLogo from "../../icons/DarkLogo.svg";
import LightingModeSwitcher from "./LightingModeSwitcher";
import CreateBoardModal from "../ProfileSettings/CreateBoardModal";
import blueArrow from "../../icons/blueArrow.svg";
import UpgradePlanModal from "../UpgradePlanModal/UpgradePlanModal";
import ThankYouModal from "../UpgradePlanModal/ThankYouModal";
import { usePrevious } from "../../utils/usePrevious";
import { FetchPaymentPlan } from "../../utils/backend/SendPlan/PaymentPlan";
import defaultBoardImage from "../../icons/default_board_image.png";
import { asyncNotification } from "../../store/slice/notificationSlice";

const Navigation = () => {
  const [searchParams] = useSearchParams();
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  const [upgradePaymentPlanModalOpen, setUpgradePaymentPlanModalOpen] =
    useState<boolean>(false);
  const allBoards = useAppSelector(
    (state) => state.boards.selectedProject?.boards
  );
  const allProjects = useAppSelector((state) => state.boards.projects);
  const selectedBoard = useAppSelector((state) => state.boards.selectedBoard);
  const selectedProject = useAppSelector(
    (state) => state.boards.selectedProject
  );
  const [isAvatarOpen, setIsAvatarOpen] = useState(false);
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth);
  const eTag = useAppSelector((state) => state.tags.tags);
  const allTagTypesWithTags = useAppSelector(
    (state) => state.tags.allTagTypesWithTags
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const tagTypes = useAppSelector((state) => state.tags.tagTypes);
  const dispatch = useAppDispatch();
  const ref: any = useRef(null);
  const boardDropdownRef: any = useRef(null);
  const historyStore = useAppSelector((state) => state.navigation.history);
  const [boardDropdownOpen, setBoardDropdownOpen] = useState<boolean>(false);
  const [thankYouModalOpen, setThankYouModalOpen] = useState<boolean>(false);
  const isExceeded = useAppSelector((state) => state.navigation.limitExceeded);
  const [exceeded, setExceeded] = useState<boolean>(false);
  useAppSelector((state) => state.navigation.browserBackButton);
  const modalOpenRedirect = searchParams.get("success");
  const currentPaymentPlan = useAppSelector(
    (state) => state.boards.selectedProject?.paymentPlan
  );
  const previousProject = usePrevious(selectedProject);

  const isUserChoosingPlan = window.location.pathname.includes("Plan");

  useEffect(() => {
    const getPlan = async () => {
      const controller = new AbortController();
      const signal = controller.signal;
      const result = await FetchPaymentPlan({
        SuperBoardID: selectedProject!.id,
        signal: signal,
      });

      if (result.response) {
        dispatch(setSelectedPaymentPlan(result.response));
      }
    };

    if (selectedProject && selectedProject !== previousProject) {
      getPlan();
    }
  }, [selectedProject]);

  useEffect(() => {
    if (exceeded !== isExceeded) {
      setExceeded(isExceeded);
    }
  }, [isExceeded]);

  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false);

  useEffect(() => {
    if (upgradePaymentPlanModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [upgradePaymentPlanModalOpen]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        boardDropdownRef.current &&
        !boardDropdownRef.current.contains(event.target as Node)
      ) {
        setBoardDropdownOpen((prev) => !prev);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (
      historyStore.length > 0 &&
      historyStore[historyStore.length - 1] === window.location.pathname
    ) {
      return;
    } else {
      dispatch(setActiveFilters([]));
      dispatch(historyPush(window.location.pathname));
    }
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsAvatarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("projectId");
    localStorage.removeItem("boardId");
    localStorage.removeItem("allTagTypesWithTags");
    dispatch(removeUser());
    navigate("/SignIn");
  };

  useEffect(() => {
    if (allTagTypesWithTags.length === 0) {
      getInitialAllTagTypesWithTags().then((res) => {
        dispatch(setAllTagTypesWithTags(res));
      });
    }

    // reset tags every 5 minutes
    const interval = setInterval(() => {
      getInitialAllTagTypesWithTags().then((res) => {
        dispatch(setAllTagTypesWithTags(res));
      });
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [allTagTypesWithTags, dispatch]);

  useEffect(() => {
    if (user.id.length === 0) {
      GetUser()
        .then(async (data) => {
          if (!data.user) {
            navigate("/SignIn");
            return;
          }

          if (data.user.image) {
            data.user.imageData = await getImageFromCacheOrFromURL(
              data.user.image,
              data.user.id
            );
          }

          dispatch(setUser(data.user));
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [dispatch, navigate, user.id.length]);

  const location = useLocation();

  useEffect(() => {
    const tagList = searchParams.get("tagList")?.split(",") || [];
    dispatch(setActiveFilters(tagList));
  }, [dispatch, location.pathname, searchParams]);

  useEffect(() => {
    if (modalOpenRedirect && modalOpenRedirect === "false") {
      setExceeded(true);
    }
    if (modalOpenRedirect && modalOpenRedirect === "true") {
      dispatch(setLockLimit());
      dispatch(setIfLimitIsExceeded(false));
      setExceeded(false);
      setThankYouModalOpen(true);
    }

    if (allProjects.length > 0 && !modalOpenRedirect) return;
    GetAllProjects()
      .then((data) => {
        if (data.error.length > 0) {
          console.error(data.error);
          return;
        }

        if (data.response.length === 0) {
          navigate("/SetupTeam");
          return;
        }

        const prjId = localStorage.getItem("projectId");
        const brdId = localStorage.getItem("boardId");

        const project =
          data.response.find((project) => project.id === prjId) ||
          data.response[0];
        dispatch(setProjects(data.response));
        dispatch(setProjectId(project.id));

        dispatch(setSelectedProject(project));
        dispatch(setWorkspaces(project.boards ? project.boards : []));
        const boards = project.boards;
        let currentBoard;

        if (boards)
          currentBoard =
            boards.find((board) => board.id === brdId) || boards[0];

        if (currentBoard) {
          dispatch(setBoardId(currentBoard.id));
          dispatch(setSelectedBoard(currentBoard));
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [allProjects.length, dispatch, modalOpenRedirect, navigate]);

  useEffect(() => {
    if (eTag.length < 1) {
      FetchTags().then((data) => {
        if (data.response) {
          dispatch(getTags(data.response));
        }
        if (data.error) {
          console.error(data.error);
        }
      });
    }
  }, [dispatch, eTag]);

  useEffect(() => {
    if (tagTypes.length < 1) {
      FetchAllTagTypes().then((data) => {
        if (data.response) {
          dispatch(getTagTypes(data.response));
        }
        if (data.error) {
          console.error(data.error);
        }
      });
    }
  }, [dispatch, tagTypes]);

  useEffect(() => {
    if (eTag.length > 0 && tagTypes.length > 0) {
      if (eTag.length > 0) {
        let tagByType: Array<any> = [];
        tagTypes.forEach((tagType: any) => {
          let filteredTags = eTag.filter(
            (tag: any) => tag.tagTypeId === tagType.id
          );
          let newTagByType = {
            type: tagType.name,
            tags: filteredTags,
          };
          tagByType.push(newTagByType);
        });
        dispatch(getTagsByType(tagByType));
      }
    }
  }, [dispatch, eTag, tagTypes]);

  useEffect(() => {
    if (!selectedBoard) {
      return;
    }

    dispatch(setBoardId(selectedBoard.id));
  }, [dispatch, selectedBoard]);

  useEffect(() => {
    const allLinks = document.querySelectorAll(".sublink");
    const splittedUrl = window.location.href.split("/");
    allLinks.forEach((link) => {
      if (link.classList.contains("current-page")) {
        link.classList.remove("current-page");
      }

      for (let i = 0; i < splittedUrl.length; i++) {
        if (link.classList[0] === splittedUrl[i]) {
          link.classList.add("current-page");
        }
      }
    });
  }, []);

  window.addEventListener("keydown", function (e: any) {
    const searchElement = document.querySelector(".search") as HTMLInputElement;
    if (
      !(
        e.key === "a" ||
        (e.key === "A" && document.activeElement !== searchElement)
      )
    ) {
      return;
    }

    const inputs = document.querySelectorAll("input");
    let isInInput = false;
    inputs.forEach((input) => {
      if (input.contains(e.target)) {
        isInInput = true;
      }
    });

    if (!isInInput) {
      e.preventDefault();
    }
  });

  const handleBoardClicked = (board: Workspace) => {
    dispatch(setSelectedBoard(board));
    dispatch(setBoardId(board.id));

    navigate(`/Navigation/BoardSettings/${board.id}`);
  };

  const pagesDropdown = document.querySelector(".pages-dropdown");
  const settingsDropdown = document.querySelector(".settings-dropdown");
  const navbar2 = document.querySelector(".navbar-2");
  const navbar1 = document.querySelector(".navbar-1");
  if (pagesDropdown) {
    pagesDropdown.addEventListener("click", () => {
      navbar1?.classList.toggle("hide");
      navbar2?.classList.toggle("hide");
    });
  }

  if (settingsDropdown) {
    settingsDropdown.addEventListener("click", () => {
      navbar2?.classList.toggle("hide");
      navbar1?.classList.toggle("hide");
    });
  }

  const isUserOnFreePlan = useMemo(() => {
    return (
      currentPaymentPlan &&
      currentPaymentPlan.plan &&
      !currentPaymentPlan.plan.stripePriceId
    );
  }, [currentPaymentPlan]);

  useEffect(() => {
    if (
      isUserOnFreePlan &&
      !window.location.href.includes("/Navigation/Plan")
    ) {
      dispatch(
        asyncNotification({
          notification: "Lifetime license ($97) available.",
          isActive: true,
        })
      );
    }
  }, [dispatch, isUserOnFreePlan]);

  if (
    !selectedBoard ||
    !user ||
    (allBoards && allBoards.length === 0) ||
    allProjects.length === 0
  ) {
    return null;
  }

  const onModalClose = () => {
    dispatch(setShouldBlockKeyboardNavigation(false));
    setIsModalOpen(false);
  };

  const openModal = () => {
    dispatch(setShouldBlockKeyboardNavigation(true));
    setIsModalOpen(true);
  };

  return (
    <div className={`base ${lightMode}`}>
      <div className={`${lightMode} header-container`}>
        <div className={`${lightMode} header`}>
          <div className={`${lightMode} left-side`}>
            <UpgradePlanModal
              isOpen={upgradeOpen}
              onClose={() => setUpgradeOpen(false)}
              type="boardLimit"
            />
            <CreateBoardModal
              isOpen={isModalOpen}
              onClose={() => onModalClose()}
            />
            <ThankYouModal
              isOpen={thankYouModalOpen}
              onClose={() => setThankYouModalOpen(false)}
            />
            <img
              src={lightMode === "light" ? Logo : DarkLogo}
              alt="uxmaps-logo"
              className={`${lightMode} uxmaps-logo`}
              onClick={() => navigate("/Navigation/Screens")}
            />
            {!isUserChoosingPlan && (
              <div
                style={{ color: lightMode === "light" ? "#eaedf1" : "#3A3A3A" }}
              >
                /
              </div>
            )}
            {!isUserChoosingPlan && (
              <div className={`${lightMode} left-side-sub`}>
                <div
                  className={`${lightMode} head-1`}
                  onClick={() => setBoardDropdownOpen(!boardDropdownOpen)}
                >
                  <img
                    src={
                      selectedBoard.image
                        ? selectedBoard.image
                        : defaultBoardImage
                    }
                    className={`${lightMode} current-board-image`}
                    alt=""
                    key={selectedBoard.image}
                    id={selectedBoard.image}
                  />

                  <div className={`${lightMode} current-board-name`}>
                    {selectedBoard?.name}
                  </div>
                  <img
                    src={upDown}
                    className={`${lightMode} chevron-up-down`}
                    alt=""
                  />
                </div>
                {boardDropdownOpen && !isUserChoosingPlan && (
                  <div
                    className={`${lightMode} options-1`}
                    ref={boardDropdownRef}
                  >
                    <div className={`${lightMode} boards-header`}>
                      <h4>Boards</h4>
                    </div>
                    <div className={`${lightMode} boards`}>
                      {boardDropdownOpen &&
                        allBoards &&
                        allBoards.map((board) => {
                          return (
                            <button
                              key={board.id}
                              className={`${lightMode} board board-item`}
                              onClick={() => {
                                handleBoardClicked(board);
                                setBoardDropdownOpen((prev) => !prev);
                              }}
                            >
                              <img
                                src={board.image ?? defaultBoardImage}
                                alt="board-icon"
                                key={board.image}
                                id={board.image}
                              />
                              <p className={`${lightMode} board-item-name`}>
                                {" "}
                                {board.name}
                              </p>
                            </button>
                          );
                        })}
                    </div>
                    <div
                      className={`${lightMode} boards-footer`}
                      onClick={() =>
                        selectedProject &&
                        currentPaymentPlan &&
                        currentPaymentPlan.remainingLimits &&
                        (currentPaymentPlan.remainingLimits.numberOfBoards <
                          0 ||
                          currentPaymentPlan.remainingLimits.numberOfBoards >
                            selectedProject.boards!.length)
                          ? openModal()
                          : setUpgradeOpen(true)
                      }
                    >
                      <h4>Add new board</h4>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className={`${lightMode} right-side-nav`}>
              <UpgradePlanModal
                type="new"
                isOpen={upgradePaymentPlanModalOpen}
                onClose={() => setUpgradePaymentPlanModalOpen(false)}
              />
              <div style={{ marginLeft: "auto", display: "flex" }}>
                {!isUserChoosingPlan && <LightingModeSwitcher />}
                {!isUserChoosingPlan && isUserOnFreePlan && (
                  <div
                    className={`${lightMode} pill blue`}
                    style={{
                      marginRight: "17px",
                    }}
                    onClick={() => setUpgradePaymentPlanModalOpen(true)}
                  >
                    <img alt="upgrade plan" src={blueArrow} />{" "}
                    <span>Upgrade</span>
                  </div>
                )}

                {!isUserChoosingPlan &&
                  (user.imageData ? (
                    <img
                      onClick={() => setIsAvatarOpen((prev) => !prev)}
                      className={`${lightMode}
										  ${isAvatarOpen ? "avatar active image" : "avatar image"}
									  `}
                      alt="user"
                      src={user.imageData}
                    />
                  ) : (
                    <Avatar
                      name={
                        user.id.length === 0 && !user.image
                          ? ""
                          : user.fullName
                          ? user.fullName[0].toUpperCase()
                          : user?.email[0].toUpperCase()
                      }
                      initials={
                        user.id.length === 0 && !user.image
                          ? ""
                          : user.fullName
                          ? user.fullName[0].toUpperCase()
                          : user?.email[0].toUpperCase()
                      }
                      className={isAvatarOpen ? "avatar active ium" : "avatar"}
                      maxInitials={1}
                      textSizeRatio={2}
                      color="#f2f4f7"
                      size="32.6px"
                      style={{ borderRadius: "16.8px" }}
                      fgColor="rgba(71, 84, 103, 1)"
                      src={user.imageData ?? ""}
                      onClick={() => {
                        setIsAvatarOpen((prev) => !prev);
                      }}
                    />
                  ))}
                {isAvatarOpen && !isUserChoosingPlan && (
                  <>
                    <div
                      className={`${lightMode} avatar-dropdown-menu`}
                      ref={ref}
                    >
                      <div className={`${lightMode} avatar-dropdown-email`}>
                        {user.email}
                      </div>
                      <div
                        className={`${lightMode} avatar-dropdown-fields`}
                        onClick={() => {
                          navigate("/Navigation/Collections");
                          setIsAvatarOpen(false);
                        }}
                      >
                        Collections
                      </div>
                      <div
                        className={`${lightMode} avatar-dropdown-fields`}
                        onClick={() => {
                          navigate("/Navigation/ProfileSettings");
                          setIsAvatarOpen(false);
                        }}
                      >
                        Settings
                      </div>
                      <div
                        style={{ borderRadius: "0 0 8px 8px" }}
                        className={`${lightMode} avatar-dropdown-fields`}
                        data-tally-open="wbed71"
                        data-tally-layout="modal"
                        data-tally-width="650"
                        data-tally-emoji-text="👋"
                        data-tally-emoji-animation="wave"
                        data-tally-auto-close="0"
                      >
                        Feedback
                      </div>
                      <div
                        style={{ borderRadius: "0 0 8px 8px" }}
                        className={`${lightMode} avatar-dropdown-fields`}
                        onClick={handleLogOut}
                      >
                        Log out
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {!isUserChoosingPlan && <DynamicIsland />}
        </div>
      </div>
      <div className={`${lightMode} page`}>
        <Outlet />
      </div>
    </div>
  );
};
export default Navigation;
