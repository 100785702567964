import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Workspace } from "../../utils/types/workspace";
import { Project } from "../../utils/types/project";

interface state {
	workspaces: Workspace[];
	projects: Project[];
	selectedBoard?: Workspace;
	selectedProject?: Project;
	selectedPaymentPlan?: any;
}

const initialState: state = {
	workspaces: [],
	projects: [],
	selectedPaymentPlan: {},
};

export const boardsSlice = createSlice({
	name: "boards",
	initialState,
	reducers: {
		setWorkspaces: (state, action: PayloadAction<Workspace[]>) => {
			state.workspaces = action.payload;
		},
		setProjects: (state, action: PayloadAction<Project[]>) => {
			state.projects = action.payload;
		},
		setSelectedPaymentPlan: (state, action: PayloadAction<any>) => {
			state.selectedPaymentPlan = action.payload;
		},
		setSelectedProject: (state, action: PayloadAction<Project>) => {
			localStorage.setItem("projectId", action.payload.id);
			state.selectedProject = action.payload;
		},
		setSelectedBoard: (state, action: PayloadAction<Workspace>) => {
			localStorage.setItem("boardId", action.payload.id);
			state.selectedBoard = action.payload;
		},
		updateSelectedProjectWithStripeCustomerId: (
			state,
			action: PayloadAction<string>
		) => {
			if (!state.selectedProject) throw new Error("no selected project");
			state.selectedProject = {
				...state.selectedProject,
				stripeCustomerId: action.payload,
			};
			state.projects = state.projects.map((project) => {
				if (project.id !== state.selectedProject?.id) return project;
				return {
					...project,
					stripeCustomerId: action.payload,
				};
			});
		},
	},
});

export const {
	setWorkspaces,
	setProjects,
	setSelectedProject,
	setSelectedBoard,
	updateSelectedProjectWithStripeCustomerId,
	setSelectedPaymentPlan,
} = boardsSlice.actions;

export default boardsSlice.reducer;
