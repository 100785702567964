import Collection from "../collection/Collection";
import "./Collections.css";
import { useEffect, useState } from "react";
import { GetAllCollections } from "../../../utils/backend/Collection/GetAllCollections";
import { Collection as CollectionType } from "../../../utils/types/collection";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Loader from "../../Loader/Loader";
import ErrorPage from "../../ErrorPage/ErrorPage";
import NoCollectionsFound from "../../NoCollections/NoCollectionsFound";
import { setShouldBlockKeyboardNavigation } from "../../../store/slice/navigationSlice";

const Collections = () => {
  const [collections, setCollections] = useState<CollectionType[]>([]);
  const [error, setError] = useState<string | undefined>("");
  const boardId = useAppSelector((state) => state.navigation.boardId);
  const dispatch = useAppDispatch();
  const [isNewCreated, setIsNewCreated] = useState<boolean>(false);

  const [isPlanLimitExceeded, setIsPlanLimitExceeded] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (boardId) {
      const init = function () {
        try {
          handleGetData();
        } catch (e) {
          setError(
            "Something unexpected happened, try to reload page or contact us."
          );
          console.error(e);
        }
      };

      const handleGetData = async function () {
        try {
          const result = await GetAllCollections(signal);
          if (result.error.length > 0) {
            setError(
              "Something unexpected happened, try to reload page or contact us."
            );
            console.error(result.error);
            setIsLoading(false);
            return;
          }

          setIsPlanLimitExceeded(result.planLimitExceeded);

          setCollections(
            result.response.sort((a: CollectionType, b: CollectionType) => {
              if (a.name > b.name) return 1;
              return -1;
            })
          );

          dispatch(setShouldBlockKeyboardNavigation(false));
          setIsLoading(false);
        } catch (e) {
          setError(
            "Something unexpected happened, try to reload page or contact us."
          );
          console.error(e);
          setIsLoading(false);
        }
      };

      init();

      return () => {
        controller.abort();
      };
    }
  }, [boardId, dispatch, isNewCreated, setIsNewCreated]);

  const createCollection = () => {
    setIsNewCreated(true);
    dispatch(setShouldBlockKeyboardNavigation(false));
  };

  if (error) {
    return <ErrorPage />;
  }

  if (collections.length === 0 && !isLoading) {
    return (
      <NoCollectionsFound
        createCollection={createCollection}
        isPlanLimitExceeded={isPlanLimitExceeded}
      />
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  if (error && error.length > 0) {
    return <ErrorPage />;
  }

  const removeCollection = (id: string) => {
    setCollections(collections.filter((col) => col.id !== id));
  };

  const updateCollection = (id: string, name: string) => {
    setCollections((prev) => {
      return prev.map((collection) => {
        if (collection.id !== id) return collection;
        return {
          ...collection,
          name: name,
        };
      });
    });
  };

  return (
    <CollectionsDisplay
      collections={collections}
      error={error}
      removeCollection={removeCollection}
      updateCollection={updateCollection}
    />
  );
};

interface CollectionListProps {
  collections: CollectionType[];
  error?: string;
  removeCollection: (id: string) => void;
  updateCollection: (id: string, name: string) => void;
}

const CollectionsDisplay = ({
  error,
  collections,
  removeCollection,
  updateCollection,
}: CollectionListProps) => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);

  return (
    <div className={`${lightMode} companies`}>
      <div className={`${lightMode} companies-grid`}>
        {error
          ? error
          : collections.map((collection) => (
              <Collection
                collection={collection}
                key={collection.id}
                removeCollectionCb={removeCollection}
                updateCollection={updateCollection}
              />
            ))}
      </div>
    </div>
  );
};

export default Collections;
