import React, { useEffect, useRef } from "react";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import "./Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import DocImgLightInactive from "../../icons/FolderImgInactive.svg";
import DocImgDark from "../../icons/FolderImgDark.svg";
import DocImgDarkInactive from "../../icons/FolderImgDarkInactive.svg";
import DocImgLight from "../../icons/FolderImg.svg";
import { setSelectedBoard } from "../../store/slice/workspacesSlice";

const Sidebar = () => {
	const [active, setActive] = React.useState("");
	const navigate = useNavigate();
	const projectId = useAppSelector((state) => state.boards.selectedProject?.id);
	const boards = useAppSelector(
		(state) => state.boards.selectedProject?.boards
	);
	const disaptch = useAppDispatch();

	useEffect(() => {
		if (window.location.pathname.includes("BoardSettings")) {
			if (window.location.pathname.includes("BoardSettings/")) {
				setActive(
					"board" +
						window.location.pathname.split("/")[
							window.location.pathname.split("/").length - 1
						]
				);
			} else {
				setActive("board");
			}
		} else if (window.location.pathname.includes("ProfileSettings")) {
			setActive("profile");
		} else if (window.location.pathname.includes("Superboard")) {
			setActive("superboard");
		}
	}, [window.location]);
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<>
			<div className={`${lightMode} sidebar-container`}>
				<div style={{ color: lightMode === "light" ? "#475467" : "#fff" }}>
					<FontAwesomeIcon
						style={{
							margin: 0,
							paddingRight: "12px",
							color: lightMode === "light" ? "black" : "white",
						}}
						icon={faGear}
					/>{" "}
					Settings
				</div>
				<div className={`${lightMode} element-container`}>
					<div
						onClick={() => navigate("/Navigation/ProfileSettings")}
						className={`
							${lightMode}
							${active === "profile" ? "element active" : "element inactive"}`}>
						Profile
					</div>
					<div
						onClick={() => navigate(`/Navigation/Superboard/${projectId}`)}
						className={`${lightMode} ${
							active === "superboard" ? "element active" : "element inactive"
						}`}>
						Workspace
					</div>
					<div>
						{boards &&
							boards.map((board) => (
								<div
									className={`${lightMode} ${
										active === "board" + board.id
											? "element active"
											: "element inactive"
									}`}
									onClick={() => {
										setActive("board" + board.id);
										disaptch(setSelectedBoard(board));
										navigate("/Navigation/BoardSettings/" + board.id);
									}}>
									<img
										style={{ marginRight: "10px" }}
										src={
											lightMode === "light" && active === "board" + board.id
												? DocImgLight
												: lightMode === "light" && active !== "board" + board.id
												? DocImgLightInactive
												: lightMode === "dark" && active === "board" + board.id
												? DocImgDark
												: DocImgDarkInactive
										}
									/>
									<p>{board.name}</p>
								</div>
							))}
					</div>
				</div>
			</div>
		</>
	);
};

export default Sidebar;
