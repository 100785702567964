import { Tag } from "../../../utils/types/tag";
import {
  FetchFlow,
  FetchFlowRequest,
} from "../../../utils/backend/Flow/FetchFlow";
import { Flow } from "../../../utils/types/flow";
import React from "react";
import { GetAllCollections } from "../../../utils/backend/Collection/GetAllCollections";
import { Collection } from "../../../utils/types/collection";

export interface getCompaniesTagsInput {
  setCompanyTags: (data: Tag[]) => void;
  setError: (error: string | undefined) => void;
}

interface getFlowInput {
  setError: (error: string | undefined) => void;
  setFlow: React.Dispatch<React.SetStateAction<Flow | undefined>>;
  flowId: string;
}

export const getFlow = async ({ flowId, setFlow, setError }: getFlowInput) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const req: FetchFlowRequest = {
    id: flowId,
    signal: signal,
  };
  try {
    const result = await FetchFlow(req);
    if (result.error.length > 0) {
      setError(
        "Something unexpected happened, try to reload page or contact us."
      );
      console.error(result.error);
      return;
    }
    if (!result.response) {
      setError(
        "Something unexpected happened, try to reload page or contact us."
      );
      return;
    }

    setFlow((prev) => {
      if (!prev) return result.response;
      return {
        ...prev,
        contentList: [
          ...prev.contentList,
          ...[...(result.response?.contentList || [])],
        ],
      };
    });
  } catch (e) {
    setError(
      "Something unexpected happened, try to reload page or contact us."
    );
    console.error(e);
  }
};

interface getAllCollectionsInput {
  setError: (err: string) => void;
  setCollections: (data: Collection[]) => void;
}

export async function getAllCollections({
  setCollections,
  setError,
}: getAllCollectionsInput) {
  const controller = new AbortController();
  const signal = controller.signal;
  try {
    const result = await GetAllCollections(signal);
    if (result.error.length > 0) {
      setError(result.error);
      return;
    }

    setCollections(result.response);
  } catch (e) {
    setError(
      "Something unexpected happened, try to reload page or contact us."
    );
    console.error(e);
  }
}
