import { store } from "../../../store/store";
import { Fetch, FetchInput, Method } from "../../Fetch";
import { Collection, toCollectionArray } from "../../types/collection";

export interface GetAllCollectionsResponse {
  error: string;
  planLimitExceeded: boolean;
  response: Collection[];
}

export async function GetAllCollections(
  signal?: AbortSignal
): Promise<GetAllCollectionsResponse> {
  const state = store.getState();
  const boardId = state.navigation.boardId;
  if (!boardId || boardId.length === 0) {
    return {
      response: [],
      error: "No board id selected",
      planLimitExceeded: false,
    };
  }
  const GetAllCollectionsURL = `/me/collection/all?workspaceId=${boardId}`;
  try {
    const input: FetchInput = {
      signal: signal,
      method: Method.Get,
      url: GetAllCollectionsURL,
    };
    const result = await Fetch(input);
    if (result.isOk) {
      const response = await result;

      if (response) {
        return {
          response: await toCollectionArray(
            response.result.result !== null ? response.result.result : []
          ),
          error: "",
          planLimitExceeded: result.result.planLimitExceeded,
        };
      }
      return {
        response: [],
        error: "Server side error",
        planLimitExceeded: false,
      };
    } else {
      return {
        response: [],
        error: result.error?.message ?? "",
        planLimitExceeded: false,
      };
    }
  } catch (err) {
    console.error(err);
    return {
      error: "error",
      response: [],
      planLimitExceeded: false,
    };
  }
}
