import React, { useEffect } from "react";
import "./App.css";
import Verification from "./components/Verification/Verification";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import Flows from "./components/flows/Flows/Flows";
import Collections from "./components/collections/Collections/Collections";
import CollectionInfo from "./components/collections/CollectionInfo/CollectionInfo";
import BoardSettings from "./components/BoardSettings/BoardSetings";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import CreateNewPassword from "./components/CreateNewPassword/CreateNewPassword";
import Screens from "./components/screens/Screens/Screens";
import ProfileSettings from "./components/ProfileSettings/ProfileSettings";
import SetupTeam from "./components/SetupTeam/SetupTeam";
import Notification from "./components/Navigation/Notification";
import ProtectedRoute from "./utils/ProtectedRoute";
import ReactGA from "react-ga4";
import SourcePage from "./components/common/SourcePage/SourcePage";
import ProductPage from "./components/common/ProductPage/ProductPage";
import SignPage from "./components/SignIn/SignPage";
import Superboard from "./components/BoardSettings/Superboard";
import AcceptInvitation from "./components/AcceptInvitation/AcceptInvitation";
import ChangeSuperBoardPage from "./pages/ChangeSuperBoardPage";
import InitialBuyPlanPage from "./components/SetupProfile/InitialBuyPlanPage";
import MobileOnlyErrorPage from "./components/ErrorPage/MobileOnlyErrorPage";
import { isMobile } from "react-device-detect";
import posthog from "posthog-js";
import { useAppSelector } from "./store/hooks";

posthog.init(
  process.env.REACT_APP_POSTHOG_API_KEY ??
    "phc_c7PJQ7Ddk9FwL1ymONMwySj5RNxwij2tKcKlatzB463",
  {
    api_host: "https://eu.i.posthog.com", // PostHog server URL
    capture_pageview: false,
  }
);

ReactGA.initialize(process.env.GA_MEASUREMENT_ID ?? "G-Q69NX5C5NM");

function App() {
  const location = useLocation();

  const user = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      posthog.identify(user.id, {
        email: user.email,
        name: user.fullName,
      });
    }

    const pageTitle = document.title || "Default Title";
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: pageTitle,
    });

    posthog.capture("$pageview", {
      path: location.pathname,
      title: pageTitle,
    });

    const handlePageLeave = () => {
      posthog.capture("$pageleave", {
        path: location.pathname,
        title: pageTitle,
      });
    };

    window.addEventListener("beforeunload", handlePageLeave);

    return () => {
      window.removeEventListener("beforeunload", handlePageLeave);
    };
  }, [location.pathname, user]);

  return (
    <div>
      <Routes>
        <Route path="/" index element={<SignPage />} />
        <Route
          path="/SetupTeam"
          element={
            isMobile ? (
              <MobileOnlyErrorPage />
            ) : (
              <ProtectedRoute>
                <SetupTeam />
              </ProtectedRoute>
            )
          }
        />
        <Route path="/verification" element={<Verification />} />
        <Route path="/SignIn" element={<SignPage />} />
        <Route path="/SignIn/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/SignIn/ResetPassword" element={<CreateNewPassword />} />
        <Route
          path="/invitation"
          element={isMobile ? <MobileOnlyErrorPage /> : <AcceptInvitation />}
        />
        <Route
          path="/Navigation"
          element={
            isMobile ? (
              <MobileOnlyErrorPage />
            ) : (
              <ProtectedRoute>
                <Navigation />
              </ProtectedRoute>
            )
          }
        >
          <Route
            path="/Navigation/Plan"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <InitialBuyPlanPage />
                </ProtectedRoute>
              )
            }
          />
          <Route
            index
            path="/Navigation/Flows"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <Flows />
                </ProtectedRoute>
              )
            }
          />
          <Route
            path="/Navigation/Flows/FlowInfo"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <ProductPage />
                </ProtectedRoute>
              )
            }
          />
          <Route
            path="/Navigation/Screens"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <Screens />
                </ProtectedRoute>
              )
            }
          />
          <Route
            path="/Navigation/Companies"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <SourcePage />
                </ProtectedRoute>
              )
            }
          />

          <Route
            path="/Navigation/Companies/CompanyInfo"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <ProductPage />
                </ProtectedRoute>
              )
            }
          />
          <Route
            path="/Navigation/Design"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <SourcePage />
                </ProtectedRoute>
              )
            }
          />
          <Route
            path="/Navigation/Design/Element"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <ProductPage />
                </ProtectedRoute>
              )
            }
          />
          <Route
            path="/Navigation/Superboard/:id"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <Superboard />
                </ProtectedRoute>
              )
            }
          />
          <Route
            path="/Navigation/BoardSettings"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <BoardSettings />
                </ProtectedRoute>
              )
            }
          />
          <Route
            path="/Navigation/BoardSettings/:id"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <BoardSettings />
                </ProtectedRoute>
              )
            }
          />
          <Route
            path="/Navigation/ProfileSettings"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <ProfileSettings />
                </ProtectedRoute>
              )
            }
          />
          <Route
            path="/Navigation/Collections"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <Collections />
                </ProtectedRoute>
              )
            }
          />
          <Route
            path="/Navigation/Collections/CollectionInfo"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <CollectionInfo />
                </ProtectedRoute>
              )
            }
          />
          <Route
            path="/Navigation/ChangeWorkspace"
            element={
              isMobile ? (
                <MobileOnlyErrorPage />
              ) : (
                <ProtectedRoute>
                  <ChangeSuperBoardPage />
                </ProtectedRoute>
              )
            }
          />
        </Route>
        <Route
          path="*"
          element={
            isMobile ? (
              <MobileOnlyErrorPage />
            ) : (
              <ProtectedRoute>
                <Navigate to="/Navigation/Screens" />
              </ProtectedRoute>
            )
          }
        />
      </Routes>
      <Notification />
    </div>
  );
}

export default App;
