import { useEffect } from "react";
import { Tag } from "../../utils/types/tag";
import "./FiltersDropdowns.css";
import isChecked from "../../icons/FiltersIsChecked.svg";
import isCheckedWhite from "../../icons/FiltersIsCheckedWhite.svg";
import Switcher from "../Switcher/Switcher";
import { useAppSelector } from "../../store/hooks";

interface setTagsCb {
	(tagId: string): void;
}

interface FiltersDropdownsProps {
	tagType: string;
	tagList: Tag[];
	selectedTags: string[];
	setSelectedTagsCb: setTagsCb;
	searchValue: string;
	modes: boolean;
}

const FiltersDropdowns = ({
	tagList,
	tagType,
	selectedTags,
	setSelectedTagsCb,
	searchValue,
	modes,
}: FiltersDropdownsProps) => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<>
			<div
				className={`${lightMode} filters-dropdown-containers`}
				style={tagType === "Design elements" ? { width: "100%" } : {}}>
				{tagList
					?.filter((el: Tag) =>
						el.name
							.toLowerCase()
							.split(" ")
							.some((word: any) => {
								const startsWithSearchValue = word.startsWith(
									searchValue.toLowerCase()
								);
								return startsWithSearchValue;
							})
					)
					.map((tag: Tag) => {
						return (
							<>
								<div
									className={`${lightMode} filters-dropdown-row`}
									key={tag.id}
									onClick={() => setSelectedTagsCb(tag.id)}>
									{(tagType === "Company" || tagType === "Industry") &&
										tag.imageS3URL && (
											<img
												className={`${lightMode} filters-image-company`}
												src={tag.imageS3URL}
											/>
										)}
									{tag.name}
									{selectedTags.includes(tag.id) && (
										<img
											className={`${lightMode} filters-is-checked-icon`}
											src={lightMode === "light" ? isChecked : isCheckedWhite}
										/>
									)}
								</div>
							</>
						);
					})}
			</div>
		</>
	);
};

export default FiltersDropdowns;
