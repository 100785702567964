import "./BoardSettings.css";
import { useEffect, useMemo, useState } from "react";
import Sidebar from "../SettingsSideBar/Sidebar";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setSelectedBoard,
  setSelectedProject,
} from "../../store/slice/workspacesSlice";
import { asyncNotification } from "../../store/slice/notificationSlice";
import ErrorPage from "../ErrorPage/ErrorPage";
import InvitePeople from "./InvitePeople";
import Team from "./Team/Team";
import plus from "../../icons/plus.svg";
import plusDark from "../../icons/plusDark.svg";
import CreateBoardModal from "../ProfileSettings/CreateBoardModal";
import { RemoveSuperboardInvitation } from "../../utils/backend/Project/RemoveSuperboardInvitation";
import SelectWorkspaceModal from "./SelectWorkspaceModal";
import {
  UpdateProject,
  UpdateProjectRequest,
} from "../../utils/backend/Project/UpdateProject";
import { imageDataToBase64String } from "../../utils/image";
import {
  InviteToSuperBoardRequest,
  InviteToSuperboardWithLink,
} from "../../utils/backend/Invites/InviteToSuperboardWithLink";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import UpgradePlanModal from "../UpgradePlanModal/UpgradePlanModal";
import blueArrow from "../../icons/blueArrow.svg";

const Superboard = () => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  const workspace = useAppSelector((state) => state.boards.selectedProject);
  const [workspaceDTO, setWorkspaceDTO] = useState(workspace);
  const selectedProject = useAppSelector(
    (state) => state.boards.selectedProject
  );
  const [project, setProject] = useState(selectedProject);

  const [upgradePaymentPlanModalOpen, setUpgradePaymentPlanModalOpen] =
    useState<boolean>(false);

  const [error, setError] = useState<string>();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWorkspaceModal, setIsOpenWorkspaceModal] = useState(false);
  const workspaces = useAppSelector((state) => state.boards.projects);
  const userId = useAppSelector((state) => state.auth.id);
  const userRole = useAppSelector(
    (state) =>
      state.boards.selectedProject?.members.find((u) => u.id === userId)?.role
  );
  const navigate = useNavigate();
  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false);

  const closeModal = () => setIsOpen(false);

  const closeWorkspaceModal = () => setIsOpenWorkspaceModal(false);
  const currentPaymentPlan = useAppSelector(
    (state) => state.boards.selectedProject?.paymentPlan
  );

  useEffect(() => {
    setProject(selectedProject);
  }, [selectedProject]);

  const isUserOnFreePlan = useMemo(() => {
    return (
      currentPaymentPlan &&
      currentPaymentPlan.plan &&
      !currentPaymentPlan.plan.stripePriceId
    );
  }, [currentPaymentPlan]);

  useEffect(() => {
    const pathname = window.location.pathname.split("/");

    setWorkspaceDTO(
      workspaces.find((ws) => ws.id === pathname[pathname.length - 1])
    );
  }, [window.location]);

  const saveChanges = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    let imageBase64: string | null = "";

    if (workspaceDTO?.image) {
      imageBase64 = await imageDataToBase64String(workspaceDTO.image);
    }

    try {
      const UpdateProjectReq: UpdateProjectRequest = {
        id: workspaceDTO?.id,
        name: workspaceDTO?.name,
        image: imageBase64,
        website: workspaceDTO?.website,
        signal: signal,
      };
      if (workspace === workspaceDTO) {
        return;
      }
      await UpdateProject(UpdateProjectReq);
      dispatch(
        asyncNotification({
          notification: "Workspace successfully updated!",
          isActive: true,
          isErrNotification: false,
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  const removeInv = async (member: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      if (project && member) {
        await RemoveSuperboardInvitation(member.id, project.id, signal)
          .then(() => {
            if (workspace) {
              dispatch(
                setSelectedProject({
                  ...workspace,
                  invites: workspace?.invites.filter(
                    (inv: any) => inv.id !== member.id
                  ),
                })
              );
              setWorkspaceDTO({
                ...workspace,
                invites: workspace?.invites.filter(
                  (inv: any) => inv.id !== member.id
                ),
              });
            }
            dispatch(
              asyncNotification({
                notification: "Invitation removed.",
                isActive: true,
              })
            );
          })
          .catch((err) => {
            dispatch(
              asyncNotification({
                notification: "Invitation removal failed.",
                isActive: true,
              })
            );
          });
      }
    } catch (error) {}
  };

  const loadFile = async (event: any) => {
    const image = document.getElementById("output") as HTMLImageElement;
    const newImg = URL.createObjectURL(event.target.files[0]);
    if (image !== null) {
      image.src = newImg;
    }
    setProject((prevState) => {
      if (!prevState) return prevState;
      return { ...prevState, image: newImg };
    });
    setWorkspaceDTO((prev) => {
      if (!prev) return undefined;
      return {
        ...prev,
        image: newImg,
      };
    });
  };

  const onInviteWithLink = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (!workspace) return;
    try {
      const req: InviteToSuperBoardRequest = {
        boardId: workspace.id,
        role: "Member",
        signal: signal,
      };

      InviteToSuperboardWithLink(req);
    } catch (error) {}
  };

  const onInvite = async (users: any) => {
    if (workspace) {
      dispatch(
        setSelectedProject({
          ...workspace,
          invites: [...workspace.invites, ...users],
        })
      );
      setWorkspaceDTO({
        ...workspace,
        invites: [...workspace.invites, ...users],
      });
    }
  };

  return (
    <div className={`${lightMode} board-settings`}>
      <Sidebar />
      <SelectWorkspaceModal
        isOpen={isOpenWorkspaceModal}
        onClose={closeWorkspaceModal}
      />
      <CreateBoardModal isOpen={isOpen} onClose={closeModal} />
      <UpgradePlanModal
        type="new"
        isOpen={upgradePaymentPlanModalOpen}
        onClose={() => setUpgradePaymentPlanModalOpen(false)}
      />
      {(error && error.length > 0) || !project ? (
        <ErrorPage />
      ) : (
        <div style={{ marginLeft: "20px", display: "grid", gap: "25px" }}>
          <div className={`${lightMode} customize-board-section`}>
            <UpgradePlanModal
              isOpen={upgradeOpen}
              onClose={() => setUpgradeOpen(false)}
              type="boardLimit"
            />
            <div className={`${lightMode} intro change-workspace `}>
              <div
                className={`${lightMode} intro change-workspace-header `}
                style={{ padding: 0 }}
              >
                <h2>Current workspace</h2>
                <h3>Customize your workspace.</h3>
              </div>
              {userRole === "admin" && !isUserOnFreePlan && (
                <div
                  className={`${lightMode} pill blue`}
                  onClick={() => setUpgradePaymentPlanModalOpen(true)}
                  style={{
                    paddingTop: "3px",
                    paddingBottom: "3px",
                  }}
                >
                  <img alt="upgrade plan" src={blueArrow} />{" "}
                  <span>Purchase additional licenses</span>
                </div>
              )}
              {workspaces.length > 1 && (
                <div>
                  <button
                    onClick={() => navigate("/Navigation/ChangeWorkspace")}
                    className={`${lightMode} change-img-button change-workspace `}
                  >
                    Change workspace
                  </button>
                </div>
              )}
            </div>
            <h2 className={`${lightMode} settings-header-photo`}>
              Workspace photo
            </h2>
            <div className={`${lightMode} img-details`}>
              {project.image ? (
                <img
                  id="output"
                  width="40px"
                  alt="user-icon"
                  src={project.image}
                  className={`${lightMode} workspace-image`}
                />
              ) : (
                <Avatar
                  key={"output"}
                  name={project.name ? project.name[0].toUpperCase() : ""}
                  initials={project.name ? project.name[0].toUpperCase() : ""}
                  className={`${lightMode} workspace-avatar small-rounded`}
                  value="1"
                  maxInitials={1}
                  textSizeRatio={2}
                  color="#f2f4f7"
                  size="40px"
                  fgColor="rgba(71, 84, 103, 1)"
                  src={project.image ?? ""}
                />
              )}

              <input
                type="file"
                accept="image/*"
                name="image"
                id="file"
                hidden
                onChange={(e) => loadFile(e)}
              />
              {userRole === "admin" && (
                <label
                  htmlFor="file"
                  className={`${lightMode} change-img-button`}
                >
                  Change
                </label>
              )}
            </div>
            <label htmlFor="name" className={`${lightMode} label-class`}>
              Name
            </label>
            <input
              disabled={userRole === "member"}
              type="text"
              id="name"
              placeholder="UXMaps"
              className={`${lightMode} board-name-input`}
              onChange={(e) => {
                setWorkspaceDTO((prev) => {
                  if (!prev) return undefined;
                  return {
                    ...prev,
                    name: e.target.value,
                  };
                });
              }}
              value={workspaceDTO?.name || ""}
            />
            <h4 className={`${lightMode} just-another-random-div`}>
              Names can be up to 32 characters
            </h4>
            <label htmlFor="url" className={`${lightMode} label-class`}>
              URL slug
            </label>
            <div className={`${lightMode} website-input-container`}>
              <h3 className={`${lightMode} left-part`}>uxmaps.app/</h3>
              <input
                disabled={userRole === "member"}
                type="text"
                id="url"
                className={`${lightMode} right-part`}
                placeholder="UXMaps"
                onChange={(e) => {
                  setWorkspaceDTO((prev) => {
                    if (!prev) return undefined;
                    return {
                      ...prev,
                      website: e.target.value,
                    };
                  });
                }}
                defaultValue={
                  workspaceDTO && workspaceDTO.website
                    ? workspaceDTO.website
                    : ""
                }
              />
            </div>
            <div className={`${lightMode} save-section-dash`}></div>

            {userRole === "admin" && (
              <div className={"workspace-options"}>
                <div className={`${lightMode} save-button-container`}>
                  <button
                    className={`${lightMode} save-button `}
                    onClick={saveChanges}
                    disabled={isLoading || userRole !== "admin"}
                    style={{ marginBottom: "10px" }}
                  >
                    {!isLoading ? "Save" : "Loading..."}
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className={`${lightMode} invite-section`}>
            {workspace && (
              <Team
                userRole={userRole}
                teamType="workspace"
                invites={workspace.invites ?? []}
                removeInvitation={(member) => removeInv(member)}
                members={workspace.members ?? []}
              />
            )}
            {userRole === "admin" && (
              <InvitePeople
                boardId={project.id}
                boardType="workspace"
                onInvite={(user) => onInvite(user)}
                onInviteWithLink={() => onInviteWithLink()}
              />
            )}
          </div>
          <div className={`${lightMode} my-boards-section section`}>
            <div className={`${lightMode} top-sect`}>
              <div className={`${lightMode} intro`}>
                <h2>My boards</h2>
                <h3>Choose your board.</h3>
              </div>
              {userRole === "admin" && (
                <button
                  className={`${lightMode} change-img-button invite-with-link`}
                  onClick={() =>
                    currentPaymentPlan &&
                    currentPaymentPlan.remainingLimits &&
                    (currentPaymentPlan.remainingLimits.numberOfBoards < 0 ||
                      currentPaymentPlan.remainingLimits.numberOfBoards >
                        project.boards!.length)
                      ? setIsOpen(true)
                      : setUpgradeOpen(true)
                  }
                  style={{ marginRight: "15px" }}
                >
                  <img
                    src={lightMode === "light" ? plus : plusDark}
                    alt="plus-icon"
                  />{" "}
                  New board
                </button>
              )}
            </div>

            {project.boards &&
              project.boards.map((board) => (
                <div id={board.id} className={`${lightMode} single-superboard`}>
                  <div className="" style={{ display: "flex", gap: "12px" }}>
                    <Avatar
                      key={"output"}
                      name={board.name ? board.name[0].toUpperCase() : ""}
                      initials={board.name ? board.name[0].toUpperCase() : ""}
                      className={`${lightMode} workspace-avatar small-rounded`}
                      value="1"
                      maxInitials={1}
                      textSizeRatio={1.5}
                      color="#f2f4f7"
                      size="18px"
                      fgColor="rgba(71, 84, 103, 1)"
                      src={board.image ?? ""}
                    />
                    {board.name}
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        dispatch(setSelectedBoard(board));
                        navigate(`/Navigation/BoardSettings/${board.id}`);
                      }}
                      className={`${lightMode} superboard-btn-view`}
                    >
                      View
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default Superboard;
