import { store } from "../../../store/store";
import { Fetch, FetchInput, Method } from "../../Fetch";
import { Screen, toScreenList } from "../../types/screen";
import { mockedScreen } from "../../../mocks/mockedScreenForLocks";

export interface FetchScreensByTagListGroupRequest {
  tags: string[];
  varsNumber: number;
  signal?: AbortSignal;
}

export interface FetchScreensByTagListGroupResponse {
  response: Map<string, Screen[]>;
  error: string;
}

export async function FetchScreensByTagListGroup(
  request: FetchScreensByTagListGroupRequest
): Promise<FetchScreensByTagListGroupResponse> {
  const state = store.getState();
  const projectId = state.navigation.projectId;

  if (!projectId || projectId.length === 0) {
    return {
      response: new Map<string, Screen[]>(),
      error: "No project id selected",
    };
  }
  const FetchScreensByTagListURL = `/me/variations/group_by_tag_list?tagIdList=${request.tags}&noOfVariations=${request.varsNumber}&projectId=${projectId}`;

  try {
    const input: FetchInput = {
      method: Method.Get,
      url: FetchScreensByTagListURL,
      signal: request.signal,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      if (result && result.result && result.result.result) {
        const res = new Map<string, Screen[]>();

        for (const [key, value] of Object.entries(result.result.result)) {
          res.set(key, await toScreenList(value));
        }

        if (result.result.planLimitExceeded) {
          for (let i = 0; i < request.tags.length; i++) {
            if (!res.get(request.tags[i])) {
              res.set(request.tags[i], [
                mockedScreen,
                mockedScreen,
                mockedScreen,
                mockedScreen,
              ]);
            }
          }
        }

        return {
          response: res,
          error: "",
        };
      }

      if (result.result.planLimitExceeded) {
        const noOfResultsToAdd = request.tags.length;

        const resultMap = new Map<string, Screen[]>();

        for (let i = 0; i < noOfResultsToAdd; i++) {
          resultMap.set(request.tags[i], [
            mockedScreen,
            mockedScreen,
            mockedScreen,
            mockedScreen,
          ]);
        }

        return {
          response: resultMap,
          error: "",
        };
      }

      return {
        response: new Map<string, Screen[]>(),
        error: "Server side error",
      };
    } else {
      return {
        response: new Map<string, Screen[]>(),
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      response: new Map<string, Screen[]>(),
      error: "error",
    };
  }
}
