import { Fetch, FetchInput, Method } from "../../Fetch";

export interface LoginRequest {
  Email: string;
  Password: string;
  signal?: AbortSignal;
}

export interface LoginResponse {
  token: string;
  error: string;
}

export async function FetchSignIn(
  request: LoginRequest
): Promise<LoginResponse> {
  const loginURL = "/public/user/login";
  const requestBody = JSON.stringify({
    email: request.Email,
    password: request.Password,
  });
  try {
    const input: FetchInput = {
      method: Method.Post,
      url: loginURL,
      signal: request.signal,
      body: requestBody,
    };
    const result = await Fetch(input);
    if (result.isOk) {
      const response = await result.result;
      if (response.token) {
        return {
          token: response.token.toString(),
          error: "",
        };
      }

      return {
        token: "",
        error: "Server side error",
      };
    } else {
      if (result.error?.code === 401) {
        return {
          token: "",
          error: "Unauthorized",
        };
      }
      return {
        token: "",
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      token: "",
      error: "error",
    };
  }
}
