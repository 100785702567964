import { useEffect } from "react";
import { Tag } from "../../utils/types/tag";
import "./FiltersDropdowns.css";
import isChecked from "../../icons/FiltersIsChecked.svg";
import isCheckedWhite from "../../icons/FiltersIsCheckedWhite.svg";
import Switcher from "../Switcher/Switcher";
import { useAppSelector } from "../../store/hooks";

interface setTagsCb {
	(tagId: string): void;
}

interface FiltersDropdownsProps {
	tagType: string;
	tagList: Tag[];
	selectedTags: string[];
	setSelectedTagsCb: setTagsCb;
	searchValue: string;
	modes: boolean;
	activeFilters: string[];
}

const FiltersDropdownsModes = ({
	tagList,
	tagType,
	selectedTags,
	setSelectedTagsCb,
	searchValue,
	modes,
	activeFilters,
}: FiltersDropdownsProps) => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	return (
		<>
			<div
				className={`${lightMode} filters-dropdown-containers`}
				style={tagType === "Design elements" ? { width: "100%" } : {}}>
				{modes && (
					<Switcher
						setSelectedTagsCb={(tagId: string) => setSelectedTagsCb(tagId)}
						tagList={tagList}
						activeFilters={activeFilters}
					/>
				)}
			</div>
		</>
	);
};

export default FiltersDropdownsModes;
