import { Fetch, FetchInput, Method } from "../../Fetch";

export interface FetchPlanResponse {
	response: string;
	error: string;
}
export interface SendPlanRequest {
	CancelURL: string;
	quantity: number;
	SuccessURL: string;
	SuperBoardID: string;
	signal?: AbortSignal;
}

export async function SendPlan(
	request: SendPlanRequest
): Promise<FetchPlanResponse> {
	const SendPlan = `/me/payments/checkout_session`;
	const reqBody = {
		cancelURL: request.CancelURL,
		quantity: request.quantity,
		successURL: request.SuccessURL,
		superBoardID: request.SuperBoardID,
	};
	try {
		const input: FetchInput = {
			method: Method.Post,
			url: SendPlan,
			signal: request.signal,
			body: JSON.stringify(reqBody),
		};
		const result = await Fetch(input);

		if (result.isOk) {
			const response = await result;
			if (response) {
				return {
					response: response.result.redirectURL,
					error: "",
				};
			}

			return {
				response: "",
				error: "Server side error",
			};
		} else {
			return {
				response: "",
				error: result.error?.message ?? "",
			};
		}
	} catch (err) {
		console.error(err);
		return {
			response: "",
			error: "error",
		};
	}
}
