import React, { useEffect, useRef, useState } from "react";
import {
  StyledCompanyElement,
  StyledDivWhenSearching,
  StyledDynamicIsland,
  StyledDynamicIslandTopContent,
  StyledElementsContainer,
  StyledElementsHeader,
  StyledElementsLink,
  StyledElementsSpan,
  StyledHotkey,
  StyledImageHolder,
  StyledSearchCommands,
  StyledSearchCompanyImage,
  StyledSearchContainer,
  StyledSearchElement,
  StyledSearchElementSubDiv,
  StyledSearchHeader,
  StyledSearchInput,
  StyledSearchSpan,
  StyledSeeMoreDesigins,
  StyledSettingsImageHolder,
  StyledSettingsLink,
  StyledSpan,
} from "./DynamicIsland.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import BoardIcon from "../../icons/BoardDynamic.svg";
import EyeIcon from "../../assets/Eye.svg";
import FilterIcon from "../../icons/FiltersNewIcon.svg";
import ProfileIcon from "../../icons/ProfileFinal.svg";
import SearchIcon from "../../assets/Search.svg";
import SettingsIcon from "../../assets/Settings.svg";
import ScreenIcon from "../../icons/ScreensImg.svg";
import CollectionIcon from "../../icons/CollectionDynamicIcon.svg";
import CompaniesIcon from "../../icons/CompaniesDynamicIcon.svg";
import ElementsIcon from "../../icons/ElementsIcon.svg";
import FlowIcon from "../../icons/FlowIcon.svg";
import arrLeft from "../../icons/arrow-left.svg";
import arrRight from "../../icons/arrow-left-white.svg";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  resetActiveFilters,
  setActiveFilters,
  shouldFiltersOpen,
} from "../../store/slice/filtersSlice";
import { useNavigate } from "react-router-dom";
import {
  historyBackButtonHandle,
  historyPop,
  setShouldDisableFilters,
} from "../../store/slice/navigationSlice";
import { Tag } from "../../utils/types/tag";

const StyledDynamicIslandMotion = motion(StyledDynamicIsland);
const StyledElementsSpanMotion = motion(StyledElementsSpan);
const StyledElementsLinkMotion = motion(StyledElementsLink);
const StyledSettingsLinkMotion = motion(StyledSettingsLink);

const DynamicIsland = () => {
  const [isOpen, setIsOpen] = React.useState({
    isOpen: false,
    component: "",
    width: 152.5,
  });
  const [isSearching, setIsSearching] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [isSearchFocused, setIsSearchFocused] = React.useState(false);
  const [areFiltersOpen, setAreFiltersOpen] = React.useState(false);
  const [elementsTag, setElementsTag] = React.useState<String>("Screens");
  const shouldDisplayFilters: any = useAppSelector(
    (state) => state.filters.isFilterOpened
  );
  const navFiltersSlice = useAppSelector(
    (state: any) => state.navigation.isFilterSideBarOpen
  );
  const allTagTypesWithTags = useAppSelector(
    (state) => state.tags.allTagTypesWithTags
  );
  const isPreviewOpened = useAppSelector(
    (state) => state.navigation.isPreviewScreenOpen
  );
  const historyStore = useAppSelector((state) => state.navigation.history);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const searchRef: any = useRef(null);
  const [indexFilters, setIndexFilters] = useState<Array<any>>([]);
  const [focusedIndex, setFocusedIndex] = useState({ index: -1, id: "" });
  const shouldBlockNavigation = useAppSelector(
    (state) => state.navigation.shouldBlockKeyboardNavigation
  );
  const activeFilters = useAppSelector((state) => state.filters.activeFilters);
  const disableFilters = useAppSelector(
    (state) => state.navigation.shouldDisableFilters
  );

  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    dispatch(setActiveFilters([]));
  }, [useNavigate]);

  useEffect(() => {
    if (shouldDisplayFilters !== navFiltersSlice) {
      //setAreFiltersOpen(navFiltersSlice)
      //dispatch(shouldFiltersOpen(navFiltersSlice))
    }
  }, [
    shouldDisplayFilters,
    setAreFiltersOpen,
    areFiltersOpen,
    setAreFiltersOpen,
    navFiltersSlice,
  ]);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen({ isOpen: false, component: "", width: 152.5 });
          setIsSearching(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);
  }

  useEffect(() => {
    dispatch(setShouldDisableFilters(false));
    if (window.location.pathname.includes("Flows")) {
      setElementsTag("Flows");
    } else if (window.location.pathname.includes("Companies")) {
      setElementsTag("Companies");
    } else if (window.location.pathname.includes("Design")) {
      setElementsTag("Elements");
    } else if (window.location.pathname.includes("Screens")) {
      setElementsTag("Screens");
    } else if (window.location.pathname.includes("Collections")) {
      setElementsTag("Collections");
    } else {
      setElementsTag("Screens");
    }
  }, [navigate]);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape" && isOpen.isOpen) {
      if (isSearching) {
        setIsSearching(false);
      }
      setIsOpen({ isOpen: false, component: "", width: 152.5 });
    } else if (
      event.code === "KeyF" &&
      !isOpen.isOpen &&
      !isPreviewOpened &&
      !shouldBlockNavigation
    ) {
      setAreFiltersOpen(!areFiltersOpen);
      dispatch(shouldFiltersOpen());
    } else if (
      event.code === "KeyS" &&
      !isOpen.isOpen &&
      navFiltersSlice === shouldDisplayFilters &&
      !isPreviewOpened &&
      !shouldBlockNavigation
    ) {
      setIsOpen({ isOpen: true, component: "search", width: 212.5 });
      //in order to not start searching with letter "s" =>
      event.preventDefault();
    } else if (event.code === "KeyS" && isSearching && !isSearchFocused) {
      searchRef.current.focus();
      setIsSearchFocused(true);
      //in order to not continue searching with letter "s" =>
      event.preventDefault();
    } else if (event.key === "ArrowDown" && isSearching) {
      event.preventDefault();
      if (focusedIndex.index === -1 || focusedIndex.index === 0) {
        setFocusedIndex({
          index: indexFilters.length - 1,
          id: indexFilters[indexFilters.length - 1],
        });
      } else {
        setFocusedIndex((prev) => {
          return {
            index: prev.index - 1,
            id: indexFilters[prev.index - 1],
          };
        });
      }
    } else if (event.key === "ArrowUp" && isSearching) {
      event.preventDefault();
      if (
        focusedIndex.index === -1 ||
        focusedIndex.index === indexFilters.length - 1
      ) {
        setFocusedIndex({
          index: 0,
          id: indexFilters[0],
        });
      } else {
        setFocusedIndex((prev) => {
          return {
            index: prev.index + 1,
            id: indexFilters[prev.index + 1],
          };
        });
      }
    } else if (event.key === "Enter" && isSearching) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      if (focusedIndex && focusedIndex.id) {
        document.getElementById(focusedIndex.id)?.click();
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  useEffect(() => {
    if (
      !window.location.pathname.includes("Profile") &&
      !window.location.pathname.includes("Board") &&
      !window.location.pathname.includes("Superboard") &&
      !window.location.pathname.includes("ChangeWorkspace")
    ) {
      document.addEventListener("keydown", handleHideDropdown, true);
    }
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
    };
  });

  const handleSearchChange = (event: any) => {
    if (event.target.value !== searchValue) {
      setIndexFilters([]);
      setFocusedIndex({ id: "", index: -1 });
    }
    setSearchValue(event.target.value);
    if (event.target.value === "") {
      setIsSearching(false);
    }
    if (event.target.value.length > 0) {
      setIsSearching(true);
    }
  };

  const onSearchClose = () => {
    setSearchValue("");
    setIsSearching(false);
    setIsOpen({ isOpen: !isOpen.isOpen, component: "", width: 152.5 });
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        duration: 1,
      },
    },
  };
  const containerSettings = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        duration: 0.5,
      },
    },
  };

  const variants = {
    closed: {
      width: "305px",
      height: "36px",
      borderRadius: "33px",
      left: `calc(50% - 152.5px)`,
      transition: {
        delayChildren: 0,
        staggerChildren: 0,
        duration: 0.15,
      },
    },
    search: {
      width: "425px",
      height: isSearching ? "430px" : "36px",
      borderRadius: "16px",
      left: `calc(50% - 212.5px)`,
      transition: {
        delayChildren: 0,
        staggerChildren: 0,
        duration: 0.15,
      },
    },
    elements: {
      width: "503px",
      height: "150px",
      borderRadius: "22px",
      left: `calc(50% - 251.5px)`,
      transition: {
        delayChildren: 0.05,
        staggerChildren: 0.05,
        duration: 0.15,
      },
    },
  };
  const filterArray = (tagList: Tag[] | undefined) => {
    const dataArray: Tag[] = [];
    if (tagList && tagList?.length > 0) {
      tagList.map((tag: Tag) =>
        tag.name
          .toLowerCase()
          .split(" ")
          .map((word: any) => {
            if (word.startsWith(searchValue.toLowerCase())) {
              dataArray.push(tag);
              return true;
            }

            return false;
          })
      );
      return dataArray;
    } else {
      return [];
    }
  };
  const searchedData = () => {
    return (
      <>
        {searchElementsData(
          filterArray(
            allTagTypesWithTags.find(
              (t) => t.tagType.name === "Design elements"
            )?.tagList
          )
        )}
        {searchCompanyData(
          filterArray(
            allTagTypesWithTags.find((t) => t.tagType.name === "Company")
              ?.tagList
          )
        )}
        {searchIndustryData(
          filterArray(
            allTagTypesWithTags.find((t) => t.tagType.name === "Industry")
              ?.tagList
          )
        )}
      </>
    );
  };

  const searchElementsData = (data: any) => {
    if (data.length > 0) {
      function compare(a: any, b: any) {
        if (a.noOfScreens < b.noOfScreens) {
          return 1;
        }
        if (a.noOfScreens > b.noOfScreens) {
          return -1;
        }
        return a.name > b.name;
      }

      data.sort(compare);
      if (data) {
        const dataCopy = [...data];
        const indexes = dataCopy.splice(0, 2);

        indexes.map((index: any) => {
          if (indexFilters.includes(index.id)) {
            return;
          } else {
            setIndexFilters([...indexFilters, index.id]);
          }
        });
      }
      return (
        <>
          {data.length > 0 && (
            <>
              <StyledSearchContainer>
                <StyledSearchElement
                  id={data[0].id}
                  focused={focusedIndex.id === data[0].id}
                  onClick={() => {
                    setIsOpen({
                      isOpen: !isOpen.isOpen,
                      component: "",
                      width: 152.5,
                    });
                    setIsSearching(false);
                    navigate(
                      `/Navigation/Design/Element?design-el-id=${data[0].id}`
                    );
                  }}
                >
                  {data[0].name}
                  <StyledSearchElementSubDiv>
                    {data[0].noOfScreens} examples
                  </StyledSearchElementSubDiv>
                </StyledSearchElement>
                {data.length > 1 && (
                  <StyledSearchElement
                    id={data[1].id}
                    focused={focusedIndex.id === data[1].id}
                    onClick={() => {
                      setIsOpen({
                        isOpen: !isOpen.isOpen,
                        component: "",
                        width: 152.5,
                      });
                      setIsSearching(false);
                      navigate(
                        `/Navigation/Design/Element?design-el-id=${data[1].id}`
                      );
                    }}
                  >
                    {data[1].name}
                    <StyledSearchElementSubDiv>
                      {data[1].noOfScreens} examples
                    </StyledSearchElementSubDiv>
                  </StyledSearchElement>
                )}
                {data.length > 2 && (
                  <StyledSeeMoreDesigins
                    onClick={() => {
                      setIsOpen({
                        isOpen: !isOpen.isOpen,
                        component: "",
                        width: 152.5,
                      });
                      setIsSearching(false);
                      navigate(
                        `/Navigation/Design?search-value=${searchValue}`
                      );
                    }}
                  >
                    See {data.length - 2} more design elements
                  </StyledSeeMoreDesigins>
                )}
              </StyledSearchContainer>
              <span
                style={{
                  width: "97%",
                  borderBottom: "1px solid #54545899",
                  display: "block",
                  marginTop: "5px",
                  marginBottom: "3px",
                  marginLeft: "10px",
                }}
              ></span>
            </>
          )}
        </>
      );
    }
  };

  const searchCompanyData = (data: any) => {
    if (data) {
      const dataCopy = [...data];
      const indexes = dataCopy.splice(0, 2);

      indexes.map((index: any) => {
        if (indexFilters.includes(index.id)) {
          return;
        } else {
          setIndexFilters([...indexFilters, index.id]);
        }
      });
    }
    if (data.length > 0) {
      return (
        <>
          {data.length > 0 && (
            <>
              <StyledSearchContainer>
                <StyledSearchHeader>Companies</StyledSearchHeader>
                <StyledCompanyElement
                  id={data[0].id}
                  focused={data[0].id === focusedIndex.id}
                  onClick={() => {
                    setIsOpen({
                      isOpen: !isOpen.isOpen,
                      component: "",
                      width: 152.5,
                    });

                    setIsSearching(false);
                    navigate(
                      `/Navigation/Companies/CompanyInfo?company-id=${data[0].id}`
                    );
                  }}
                >
                  <StyledSearchCompanyImage
                    src={data[0].imageS3URL}
                    placeholder=""
                  />
                  <span style={{ marginLeft: "0.7rem" }}>
                    {data[0].name}
                    <StyledSearchElementSubDiv>
                      {data[0].description}
                    </StyledSearchElementSubDiv>
                  </span>
                </StyledCompanyElement>
                {data.length > 1 && (
                  <StyledCompanyElement
                    id={data[1].id}
                    focused={data[1].id === focusedIndex.id}
                    onClick={() => {
                      setIsOpen({
                        isOpen: !isOpen.isOpen,
                        component: "",
                        width: 152.5,
                      });
                      setIsSearching(false);

                      navigate(
                        `/Navigation/Companies/CompanyInfo?company-id=${data[1].id}`
                      );
                    }}
                  >
                    <StyledSearchCompanyImage
                      src={data[1].imageS3URL}
                      placeholder=""
                    />
                    <span style={{ marginLeft: "0.7rem" }}>
                      {data[1].name}
                      <StyledSearchElementSubDiv>
                        {data[1].description}
                      </StyledSearchElementSubDiv>
                    </span>
                  </StyledCompanyElement>
                )}
              </StyledSearchContainer>
              <span
                style={{
                  width: "97%",
                  borderBottom: "1px solid #54545899",
                  display: "block",
                  marginTop: "5px",
                  marginBottom: "3px",
                  marginLeft: "10px",
                }}
              ></span>
            </>
          )}
        </>
      );
    }
  };

  const handleIndustryClick = (industry: any) => {
    setIsOpen({ isOpen: !isOpen.isOpen, component: "", width: 152.5 });
    setIsSearching(false);
    dispatch(resetActiveFilters);
    dispatch(setActiveFilters([industry.id]));
    navigate(`/Navigation/Companies?tagList=${industry.id}`);
  };

  const searchIndustryData = (data: Tag[]) => {
    const generateIndustryList = (industryId: string) => {
      const companyList: Array<Tag> = [];
      allTagTypesWithTags
        .find((t) => t.tagType.name === "Company")
        ?.tagList.map((comp) => {
          if (comp.tagList.find((c) => c.id === industryId))
            companyList.push(comp);
        });
      return companyList;
    };

    if (data.length > 0) {
      if (data) {
        const dataCopy = [...data];
        const indexes = dataCopy.splice(0, 2);

        indexes.map((index: any) => {
          if (indexFilters.includes(index.id)) {
            return;
          } else {
            setIndexFilters([...indexFilters, index.id]);
          }
        });
      }
      return (
        <>
          {data.length > 0 && (
            <StyledSearchContainer>
              <StyledSearchHeader>Industry</StyledSearchHeader>
              <StyledSearchElement
                id={data[0].id}
                focused={focusedIndex.id === data[0].id}
                onClick={() => {
                  handleIndustryClick(data[0]);
                }}
              >
                {data[0].name}
                <StyledSearchElementSubDiv>
                  {generateIndustryList(data[0].id).length} companies
                </StyledSearchElementSubDiv>
              </StyledSearchElement>
              {data.length > 1 && (
                <StyledSearchElement
                  id={data[1].id}
                  focused={focusedIndex.id === data[1].id}
                  onClick={() => {
                    handleIndustryClick(data[1]);
                  }}
                >
                  {data[1].name}
                  <StyledSearchElementSubDiv>
                    {generateIndustryList(data[1].id).length} companies
                  </StyledSearchElementSubDiv>
                </StyledSearchElement>
              )}
            </StyledSearchContainer>
          )}
          {data.length > 2 && (
            <StyledSeeMoreDesigins
              onClick={() => {
                setIsOpen({
                  isOpen: !isOpen.isOpen,
                  component: "",
                  width: 152.5,
                });
                setIsSearching(false);
                navigate(`/Navigation/Companies?search-value=${searchValue}`);
              }}
            >
              See {data.length - 2} more industries
            </StyledSeeMoreDesigins>
          )}
        </>
      );
    }
  };

  window.onpopstate = () => {
    setTimeout(() => {
      dispatch(historyBackButtonHandle());
    }, 1500);
  };

  const goBackFunction = () => {
    if (
      historyStore.length > 0 &&
      historyStore.length !== 1 &&
      !historyStore[historyStore.length - 1].includes("SignIn")
    ) {
      dispatch(historyPop());
      navigate(`${historyStore[historyStore.length - 2]}`);
    } else if (
      historyStore.length === 1 &&
      historyStore[0] !== window.location.pathname
    ) {
      dispatch(historyPop());
      navigate(`${historyStore[historyStore.length - 2]}`);
    }
  };
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  return (
    <>
      <StyledDynamicIslandMotion
        ref={wrapperRef}
        initial={false}
        animate={isOpen.isOpen ? isOpen.component : "closed"}
        variants={variants}
        isOpen={isOpen.isOpen}
        widthToReduce={isOpen.width}
      >
        {historyStore.length > 1 && (
          <button
            className={`${lightMode} back-button-dynamic`}
            onClick={() => goBackFunction()}
          >
            <img src={lightMode === "light" ? arrLeft : arrRight} />
          </button>
        )}
        <StyledDynamicIslandTopContent
          isOpen={!isOpen.isOpen}
          isSearching={isSearching}
        >
          {!isOpen.isOpen && (
            <>
              <StyledSpan
                onClick={() =>
                  setIsOpen({
                    isOpen: !isOpen.isOpen,
                    component: "search",
                    width: 212.5,
                  })
                }
              >
                <img style={{ color: "#fff" }} src={SearchIcon} /> Search{" "}
                <StyledHotkey>S</StyledHotkey>{" "}
              </StyledSpan>
              <StyledSpan
                onClick={() =>
                  setIsOpen({
                    isOpen: !isOpen.isOpen,
                    component: "elements",
                    width: 251.5,
                  })
                }
              >
                <img style={{ margin: 0 }} src={EyeIcon} />
                {elementsTag}
              </StyledSpan>
              <StyledSpan
                onClick={() => dispatch(shouldFiltersOpen())}
                className={
                  window.location.pathname.includes("Flows/FlowInfo") ||
                  window.location.pathname.includes("ProfileSettings") ||
                  window.location.pathname.includes("BoardSettings") ||
                  window.location.pathname.includes("Superboard") ||
                  window.location.pathname.includes(
                    "/Navigation/Collections"
                  ) ||
                  disableFilters
                    ? "dynamic-island-disabled"
                    : ""
                }
              >
                {activeFilters.length > 0 && (
                  <span
                    className={`${lightMode} filters-active-indicator`}
                  ></span>
                )}
                <img style={{ margin: 0 }} src={FilterIcon} />
                Filters <StyledHotkey>F</StyledHotkey>
              </StyledSpan>
              {/* <StyledSpan
								onClick={() =>
									setIsOpen({ isOpen: !isOpen.isOpen, component: "settings" })
								}>
								<img style={{ margin: 0 }} src={SettingsIcon} />
								Settings
							</StyledSpan> */}
            </>
          )}
          {isOpen.isOpen && isOpen.component === "search" && (
            <>
              <StyledSearchSpan isSearching={isSearching}>
                <img style={{ color: "#fff", margin: 0 }} src={SearchIcon} />
                <StyledSearchInput
                  ref={searchRef}
                  onFocus={() => setIsSearchFocused(true)}
                  onBlur={() => setIsSearchFocused(false)}
                  placeholder="Search"
                  onChange={(event) => handleSearchChange(event)}
                  autoFocus
                />

                <FontAwesomeIcon
                  style={{
                    float: "right",
                    color: "#fff",
                    marginRight: "8px",
                    marginLeft: "auto",
                  }}
                  onClick={() => onSearchClose()}
                  icon={faClose}
                />
              </StyledSearchSpan>

              {isSearching && (
                <>
                  <StyledDivWhenSearching>
                    <>{searchedData()}</>
                  </StyledDivWhenSearching>
                  <span
                    style={{
                      borderTop: "0.6px solid #54545899",
                      width: "99.6%",
                      position: "absolute",
                      bottom: "30px",
                    }}
                  ></span>
                  <StyledSearchCommands>
                    Open Command{" "}
                    <StyledHotkey
                      style={{
                        marginLeft: "0.5rem",
                        marginRight: "10px",
                        color: "#A4A4A7",
                      }}
                    >
                      S
                    </StyledHotkey>
                    <span style={{ marginRight: "10px", color: "#54545899" }}>
                      {" "}
                      |{" "}
                    </span>
                    Close{" "}
                    <StyledHotkey
                      style={{ marginLeft: "0.5rem", color: "#A4A4A7" }}
                    >
                      ESC
                    </StyledHotkey>
                  </StyledSearchCommands>
                </>
              )}
            </>
          )}
          {/* <AnimatePresence> */}
          {isOpen.isOpen && isOpen.component === "elements" && (
            <StyledElementsSpanMotion
              key="elements"
              variants={container}
              initial="hidden"
              animate="show"
            >
              <StyledElementsHeader>
                <img style={{ margin: 0, marginRight: "5px" }} src={EyeIcon} />{" "}
                Show
                <FontAwesomeIcon
                  style={{
                    marginLeft: "auto",
                    float: "right",
                  }}
                  onClick={() =>
                    setIsOpen({
                      isOpen: !isOpen.isOpen,
                      component: "",
                      width: 152.5,
                    })
                  }
                  icon={faClose}
                />
              </StyledElementsHeader>
              <StyledElementsContainer>
                <StyledElementsLinkMotion
                  style={
                    window.location.pathname.includes("/Navigation/Screens")
                      ? { outline: "1px solid #0A8BFE" }
                      : ""
                  }
                  onClick={() =>
                    setIsOpen({
                      isOpen: !isOpen.isOpen,
                      component: "",
                      width: 152.5,
                    })
                  }
                  to="/Navigation/Screens"
                >
                  <StyledImageHolder>
                    <img src={ScreenIcon} />
                  </StyledImageHolder>
                  Screens
                </StyledElementsLinkMotion>
                <StyledElementsLinkMotion
                  style={
                    window.location.pathname.includes("/Navigation/Companies")
                      ? { outline: "1px solid #0A8BFE" }
                      : ""
                  }
                  onClick={() =>
                    setIsOpen({
                      isOpen: !isOpen.isOpen,
                      component: "",
                      width: 152.5,
                    })
                  }
                  to="/Navigation/Companies"
                >
                  <StyledImageHolder>
                    <img src={CompaniesIcon} />
                  </StyledImageHolder>
                  Companies
                </StyledElementsLinkMotion>
                <StyledElementsLinkMotion
                  style={
                    window.location.pathname.includes("/Navigation/Design")
                      ? { outline: "1px solid #0A8BFE" }
                      : ""
                  }
                  onClick={() =>
                    setIsOpen({
                      isOpen: !isOpen.isOpen,
                      component: "",
                      width: 152.5,
                    })
                  }
                  to="/Navigation/Design"
                >
                  <StyledImageHolder>
                    <img
                      onClick={() =>
                        setIsOpen({
                          isOpen: !isOpen.isOpen,
                          component: "",
                          width: 152.5,
                        })
                      }
                      src={ElementsIcon}
                    />
                  </StyledImageHolder>
                  Elements
                </StyledElementsLinkMotion>
                <StyledElementsLinkMotion
                  style={
                    window.location.pathname.includes("/Navigation/Flows")
                      ? { outline: "1px solid #0A8BFE" }
                      : ""
                  }
                  onClick={() =>
                    setIsOpen({
                      isOpen: !isOpen.isOpen,
                      component: "",
                      width: 152.5,
                    })
                  }
                  to="/Navigation/Flows"
                >
                  <StyledImageHolder>
                    <img src={FlowIcon} alt={"flows"} />
                  </StyledImageHolder>
                  Flows
                </StyledElementsLinkMotion>

                <StyledElementsLinkMotion
                  style={
                    window.location.pathname.includes("/Navigation/Collections")
                      ? { outline: "1px solid #0A8BFE" }
                      : ""
                  }
                  onClick={() =>
                    setIsOpen({
                      isOpen: !isOpen.isOpen,
                      component: "",
                      width: 152.5,
                    })
                  }
                  to="/Navigation/Collections"
                >
                  <StyledImageHolder>
                    <img style={{ width: "12px" }} src={CollectionIcon} />
                  </StyledImageHolder>
                  Collections
                </StyledElementsLinkMotion>
              </StyledElementsContainer>
            </StyledElementsSpanMotion>
          )}
          {/* </AnimatePresence> */}
          {isOpen.isOpen && isOpen.component === "settings" && (
            <StyledElementsSpanMotion
              variants={containerSettings}
              initial="hidden"
              animate="show"
            >
              <StyledElementsHeader>
                <img
                  style={{ margin: 0, marginRight: "5px" }}
                  src={SettingsIcon}
                />
                Settings
                <FontAwesomeIcon
                  style={{
                    marginLeft: "auto",
                    float: "right",
                  }}
                  onClick={() =>
                    setIsOpen({
                      isOpen: !isOpen.isOpen,
                      component: "",
                      width: 152.5,
                    })
                  }
                  icon={faClose}
                />
              </StyledElementsHeader>
              <StyledElementsContainer>
                <StyledSettingsLinkMotion
                  style={
                    window.location.pathname.includes(
                      "/Navigation/BoardSettings"
                    )
                      ? { outline: "1px solid #0A8BFE" }
                      : ""
                  }
                  onClick={() =>
                    setIsOpen({
                      isOpen: !isOpen.isOpen,
                      component: "",
                      width: 152.5,
                    })
                  }
                  to="/Navigation/BoardSettings"
                >
                  <StyledSettingsImageHolder>
                    <img src={BoardIcon} />
                  </StyledSettingsImageHolder>
                  <p style={{ marginTop: "5px", marginBottom: "10px" }}>
                    Board Settings
                  </p>
                </StyledSettingsLinkMotion>
                <StyledSettingsLinkMotion
                  style={
                    window.location.pathname.includes(
                      "/Navigation/ProfileSettings"
                    )
                      ? { outline: "1px solid #0A8BFE" }
                      : ""
                  }
                  onClick={() =>
                    setIsOpen({
                      isOpen: !isOpen.isOpen,
                      component: "",
                      width: 152.5,
                    })
                  }
                  to="/Navigation/ProfileSettings"
                >
                  <StyledSettingsImageHolder>
                    <img src={ProfileIcon} />
                  </StyledSettingsImageHolder>
                  <p style={{ marginTop: "5px", marginBottom: "10px" }}>
                    Profile Settings
                  </p>
                </StyledSettingsLinkMotion>
              </StyledElementsContainer>
            </StyledElementsSpanMotion>
          )}
        </StyledDynamicIslandTopContent>
      </StyledDynamicIslandMotion>
    </>
  );
};

DynamicIsland.displayName = "DynamicIsland";

export default DynamicIsland;
