import "./Flow.css";
import defaultImg from "../../../icons/image-loader.png";
import { Flow as FlowType } from "../../../utils/types/flow";
import { StyledScreenImage } from "../../common/ProductPage/ProductPage.styles";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";
import LockImg from "../../../icons/lock.svg";
import LockImgDark from "../../../icons/lock-dark.svg";
import LockedIconCompany from "../../../icons/LockScreenIcon.png";
import UpgradePlanModal from "../../UpgradePlanModal/UpgradePlanModal";

interface FlowProps {
  flow: FlowType;
  image?: string;
}

const Flow = ({ flow, image }: FlowProps) => {
  const [innerWidth, setInnerWidth] = useState<number>(
    document.body.clientWidth
  );
  const areFiltersOpen = useAppSelector(
    (state) => state.filters.isFilterOpened
  );
  const navigate = useNavigate();
  const handleResize = () => {
    setInnerWidth(document.body.clientWidth);
  };
  const locked = flow.id === "flow-locked-123";

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isUpgradePlanModalOpened, setIsUpgradePlanModalOpened] =
    useState<boolean>(false);

  const lightMode = useAppSelector((state) => state.navigation.lightMode);

  useEffect(() => {
    if (isUpgradePlanModalOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isUpgradePlanModalOpened]);

  return (
    <div
      className={`${lightMode} flow-link company item`}
      style={locked ? { cursor: "default" } : {}}
      onClick={(e) =>
        !locked
          ? navigate(
              `/Navigation/Flows/FlowInfo?flow-id=${flow.id}&flow-name=${flow.name}`
            )
          : e.preventDefault()
      }
    >
      <UpgradePlanModal
        onClose={() => setIsUpgradePlanModalOpened(false)}
        isOpen={isUpgradePlanModalOpened}
        type="limit"
      />
      <StyledScreenImage
        areFiltersOpen={areFiltersOpen}
        innerWidth={innerWidth}
        // locked={true}
        style={
          !locked
            ? {
                backgroundImage: `url(${image ?? defaultImg})`,
                borderRadius: "6px",
                border: 0,
              }
            : lightMode === "light"
            ? {
                backgroundColor: "#EAECF0",
              }
            : {
                backgroundColor: "#1E1E1E",
              }
        }
        className={`${lightMode} screen-image with-border`}
      >
        {locked && (
          <button
            className={`${lightMode} purchase-plan-locked screen-flow-locked`}
            onClick={() => setIsUpgradePlanModalOpened(true)}
          >
            <img
              src={lightMode === "light" ? LockImg : LockImgDark}
              alt={"locked"}
            />
            Upgrade to see this flow
          </button>
        )}
      </StyledScreenImage>
      <div className={`${lightMode} flow-info`}>
        <img
          src={flow.icon ?? LockedIconCompany}
          alt=""
          className={`${lightMode} flow-icon`}
        />
        <div className={`${lightMode} flow-text`}>
          <h3 className={`${lightMode} flow-name`}>{flow.name}</h3>
          <h3 className={`${lightMode} flow-company`}>{flow.company}</h3>
        </div>
      </div>
    </div>
  );
};
export default Flow;
