import React, { useState } from "react";
import Logo from "../../icons/favicon.png";
import arrow from "../../icons/arrow-right-white.svg";
import { useNavigate } from "react-router-dom";
import "./SetupTeam.css";
import { useAppSelector } from "../../store/hooks";
import {
  FetchSetupProfile,
  SetupProfileRequest,
} from "../../utils/backend/Profile Passwords/FetchSetupProfile";

interface UploadProps {
  onFileUpload(fileData: string): void;

  fileData: string;
}

const Upload = ({ onFileUpload, fileData }: UploadProps) => {
  const [highlight, setHighlight] = useState(false);
  const [drop, setDrop] = useState(false);

  const handleEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    fileData === "" && setHighlight(true);
  };

  const handleOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    fileData === "" && setHighlight(true);
  };

  const handleLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setHighlight(false);
  };

  const handleUpload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setHighlight(false);
    setDrop(true);

    const [file] = e.target.files || e.dataTransfer.files;

    uploadFile(file);
  };

  function uploadFile(file: any) {
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () => {
      const csv: string = reader.result as string;
      let fileRes = "";
      if (csv !== null) {
        fileRes = window.btoa(csv);
      }

      onFileUpload(`data:image/jpg;base64,${fileRes}`);
    };

    reader.onerror = () => {
      console.error("There is a problem while uploading...");
    };
  }

  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  return (
    <>
      <div
        onDragEnter={(e) => handleEnter(e)}
        onDragLeave={(e) => handleLeave(e)}
        onDragOver={(e) => handleOver(e)}
        onDrop={(e) => handleUpload(e)}
        className={`upload${
          highlight ? " is-highlight" : drop ? " is-drop" : ""
        }`}
        style={{ backgroundImage: `url(${fileData})` }}
      >
        <form className={`${lightMode} my-form`}>
          <div className={`${lightMode} upload-button`}>
            <input
              type="file"
              className={`${lightMode} upload-file`}
              accept="image/*"
              onChange={(e) => handleUpload(e)}
            />
            <div className={`${lightMode} upload-part`}>
              {!fileData && (
                <p>
                  <span className={`${lightMode} button`}>
                    Click to upload{" "}
                  </span>
                  or drag & drop here <br />
                  <span className={`${lightMode} supported`}>
                    Supported: JPG, JPEG, PNG
                  </span>
                </p>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const SetupTeam = () => {
  /*
                                                  const [invites, setInvites] = useState<String[]>([]);
                                                  const [invite, setInvite] = useState("");
                                                  const inputInvite = document.querySelector(".invite") as HTMLInputElement;
            
            
                                                  const handleInviteChange = (event: ChangeEvent<HTMLInputElement>) => {
                                                    setInvite(event.target.value);
                                                  };
            
                                                  const addInvite = () => {
                                                    if (invite) {
                                                      setInvites((prevState: String[]) => [...prevState, invite]);
                                                      setInvite("");
                                                      inputInvite.value = "";
                                                    }
                                                  };
            
                                                  const displayEmails = invites.map((invite: String, index: number) => {
                                                    return <EmailElement email={invite} key={index} />;
                                                  });
            
                                                  const EmailElement = (props: any) => {
                                                    const removeEmail = () => {
                                                      let updated = invites.filter((email: String) => {
                                                        return email !== props.email;
                                                      });
                                                      setInvites(updated);
                                                    };
                                                    return (
                                                      <div className={`${lightMode} email-el`}>
                                                        <h3>{props.email}</h3>
                                                        <i
                                                          className={`${lightMode} fa-solid fa-circle-xmark`}
                                                          onClick={() => {
                                                            removeEmail();
                                                          }}
                                                        ></i>
                                                      </div>
                                                    );
                                                  };
            
                                                   */

  const navigate = useNavigate();

  const [teamName, setTeamName] = useState("");
  const [teamImage, setTeamImage] = useState("");
  const [error, setError] = useState<string>();

  const handleContinue = async () => {
    if (teamName.length === 0) {
      setError("Team name can't be empty.");
      return;
    }

    try {
      const setupProfileRequest: SetupProfileRequest = {
        ...data,
        workspaceName: teamName,
        workspaceImage: teamImage,
      };
      const result = await FetchSetupProfile(setupProfileRequest);

      if (result.error.length > 0) {
        setError(
          "Something unexpected happened, try to reload page or contact us."
        );
        console.error(result.error);
        return;
      }

      navigate("/Navigation/Plan");
    } catch (e) {
      setError("something happened");
    }
  };

  const data = useAppSelector((state) => state.setup.setupData);

  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  return (
    <div className={`${lightMode} setup-team-page`}>
      <img src={Logo} className={`${lightMode} uxmaps-logo sign-page`}></img>
      <div className={`${lightMode} setup`}>
        <div className={`${lightMode} top`}>
          <h1 className={`${lightMode} setup-title`}>Set up your team</h1>
          <h2 className={`${lightMode} wb`}>
            First things, tell us a bit about yourself.
          </h2>
        </div>

        <div className={`${lightMode} form`}>
          <div style={{ marginBottom: "20px" }}>
            <label htmlFor="team-name" className={`${lightMode} label`}>
              Team Name
            </label>
            <input
              type="text"
              id="team-name"
              className={`${lightMode} team-name input set-up-profile`}
              onChange={(e) => {
                setTeamName(e.target.value);
              }}
              placeholder="Enter your team name"
            />
            <div className="error">{error}</div>
          </div>
          <label htmlFor="team-img" className={`${lightMode} label`}>
            Team Image
          </label>
          <Upload onFileUpload={setTeamImage} fileData={teamImage} />

          {/*<label htmlFor="invite" className={`${lightMode} label l1`}>
            Invite to my team
            <span className={`${lightMode} optional`}>
              <div className={`${lightMode} dash`}></div> Optional
            </span>
          </label>
          <div className={`${lightMode} input invite-container`}>
            <div className={`${lightMode} cont`}>
              {displayEmails}
              <input
                type="text"
                id="invite"
                className={`${lightMode} invite`}
                onChange={handleInviteChange}
                placeholder="name@example.com"
              />
            </div>

            <i className={`${lightMode} fa-solid fa-plus`} onClick={addInvite}></i>
          </div>
          */}
        </div>
        <button
          className={`${lightMode} continue-btn`}
          onClick={handleContinue}
        >
          Continue <img src={arrow} alt="arrow-right" />
        </button>
      </div>
    </div>
  );
};

export default SetupTeam;
