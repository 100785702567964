import threeDots from "../../../icons/wierdIconBlack.svg";
import threeDotsWhite from "../../../icons/wierdIconWhite.svg";
import close from "../../../icons/close.svg";
import "../../companies/CompanyInfo/CompanyInfo.css";
import { useSearchParams } from "react-router-dom";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Screen } from "../../../utils/types/screen";
import { Tag } from "../../../utils/types/tag";
import searchFilters from "../../../icons/SearchFilters.svg";
import searchFiltersDark from "../../../icons/SearchFiltersWhite.svg";

import MoreDropdown from "../../Dropdowns/MoreDropdown";

import "./ProductPage.css";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setShouldBlockKeyboardNavigation,
  setShouldDisableFilters,
} from "../../../store/slice/navigationSlice";
import { setActiveFilters } from "../../../store/slice/filtersSlice";

interface ProductPageHeaderProps {
  screens: Screen[];
  tags: Tag | undefined;
  industryTags: Tag[];
  selectingMode: boolean;
  screensSwitcher: boolean;
  website: string | null;

  setSelectingMode(newValue: boolean): void;

  headerSearchCb(searchValue: string): void;

  screensSwitcherCb(screens: boolean): void;
}

const ProductPageHeader = ({
  screens,
  tags,
  industryTags,
  selectingMode,
  screensSwitcher,
  website,
  setSelectingMode,
  headerSearchCb,
  screensSwitcherCb,
}: ProductPageHeaderProps) => {
  const [openOptionsDropdown, setOpenOptionsDropdown] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const flowName = searchParams.get("flow-name");
  const ref = useRef<HTMLDivElement>(null);
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  const isOnFlowPage: boolean =
    window.location.pathname.includes("/Flows/FlowInfo");

  useEffect(() => {
    dispatch(setShouldBlockKeyboardNavigation(isSearchFocused));
  }, [isSearchFocused]);

  const searchOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    headerSearchCb(e.target.value);
  };

  const handleKeyboardEvent = (event: KeyboardEvent) => {
    //this is the easiest way
    if (event.key === "/") {
      event.preventDefault();
      document.getElementById("input-search-id")?.focus();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboardEvent, true);

    return () => {
      document.removeEventListener("keydown", handleKeyboardEvent, true);
    };
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpenOptionsDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`${lightMode} tags-info-header`}>
      <div className={`${lightMode} info`}>
        {!window.location.pathname.includes("/Navigation/Design/Element") && (
          <img
            className={`${lightMode} info image`}
            src={tags?.imageS3URL ?? undefined}
            alt="tags-icon"
          />
        )}

        <span className={`${lightMode} details`}>
          <h2 style={{ marginBottom: "2px" }}>{tags?.name}</h2>
          <h3>{tags?.description}</h3>
        </span>

        <div className={`${lightMode} path`}>
          <div className={`${lightMode} types`}>
            {!window.location.pathname.includes("/Flows/FlowInfo") &&
              tags?.tagList.map((tag) => {
                return industryTags.some((t) => t.id === tag.id) ? (
                  <a
                    onClick={() => dispatch(setActiveFilters([tag.id]))}
                    href={`/Navigation/Companies?tagList=${tag.id}`}
                  >
                    <h4
                      key={tag.id + "desc"}
                      className={`${lightMode} h4-tag-list-item`}
                    >
                      {tag.name}
                    </h4>
                  </a>
                ) : (
                  <h4
                    className={`${lightMode} h4-tag-list-item-no-hover`}
                    key={tag.id + "desc"}
                  >
                    {tag.name}
                  </h4>
                );
              })}
          </div>
        </div>
      </div>

      <div className={`${lightMode} under-info`}>
        <span className={`${lightMode} tags`}>
          {!flowName && (
            <span
              className={`${lightMode}
								${
                  screensSwitcher
                    ? "header-screens-number active"
                    : "header-screens-number inactive"
                }`}
            >
              <span
                className={`${lightMode} header-screens-number-text`}
                onClick={() => {
                  screensSwitcherCb(true);
                  dispatch(setShouldDisableFilters(false));
                }}
              >
                Screens
              </span>
              <span className={`${lightMode} header-screens-number-bubble`}>
                {isOnFlowPage ? screens.length : tags?.noOfScreens}
              </span>
            </span>
          )}

          <span
            className={`${lightMode} ${
              !screensSwitcher || flowName
                ? "header-screens-number active"
                : "header-screens-number"
            }`}
            onClick={() => {
              screensSwitcherCb(false);
              dispatch(setShouldDisableFilters(true));
            }}
          >
            {flowName ? flowName : "Flows"}
          </span>

          {isOnFlowPage && (
            <span className={`${lightMode} header-screens-number`}>
              <a
                className={`${lightMode} header-all-flows`}
                onClick={() => dispatch(setShouldDisableFilters(true))}
                href={`/Navigation/Companies/CompanyInfo?company-id=${tags?.id}&flows=true`}
              >
                All Flows
              </a>
            </span>
          )}
        </span>

        <div></div>
        <div className={`${lightMode} actionss`}>
          {selectingMode && (
            <div
              onClick={() => {
                setSelectingMode(false);
              }}
              className={`${lightMode} header-close-icon-for-screens`}
            >
              <img
                className={`${lightMode} close-collection-img`}
                src={close}
              />
            </div>
          )}
          <div className={`${lightMode} dropdown-container-2`}>
            {/* <Switcher /> */}
            <div className={`${lightMode} filters-search-container-header`}>
              <img
                src={lightMode === "light" ? searchFilters : searchFiltersDark}
                style={{ marginRight: "8px", cursor: "pointer" }}
              />
              <input
                id="input-search-id"
                className={`${lightMode} input-search-product-page`}
                type="text"
                placeholder="Search page"
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
                onChange={(e) => searchOnChange(e)}
              />
              <span className={`${lightMode} span-button-like`}>/</span>
            </div>
            <div
              ref={ref}
              className={`${lightMode} head-3`}
              onClick={() => {
                setOpenOptionsDropdown((prevState) => {
                  return !prevState;
                });
              }}
              style={
                openOptionsDropdown && lightMode === "light"
                  ? { outline: "#EAECF0 solid 2px" }
                  : {}
              }
            >
              <img
                src={lightMode === "light" ? threeDots : threeDotsWhite}
                alt="three-dots-icon"
              />
              {openOptionsDropdown && (
                <MoreDropdown
                  website={website}
                  closeDropdown={() => setOpenOptionsDropdown(false)}
                  isOpen={openOptionsDropdown}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPageHeader;
