import collectionImg from "../../../icons/CollectionImg.svg";
import "./Collection.css";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../icons/image-loader.png";
import { Collection as CollectionType } from "../../../utils/types/collection";
import { StyledSourceImageBackground } from "../../common/SourcePage/SourcePage.styles";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ContextMenu } from "../../companies/CompanyItem/StyledContextMenu";
import useContextMenu from "../../companies/CompanyItem/ContextMenuHook";
import Trash from "../../../icons/trash-2.svg";
import EditImg from "../../../icons/edit-3.svg";
import EditImgDark from "../../../icons/edit-3-dark.svg";
import EditCollectionModal from "../CollectionInfo/EditCollectionModal";
import { setShouldBlockKeyboardNavigation } from "../../../store/slice/navigationSlice";
import {
  DeleteCollection,
  DeleteCollectionRequest,
} from "../../../utils/backend/Collection/DeleteCollection";
import { asyncNotification } from "../../../store/slice/notificationSlice";

interface CollectionProps {
  collection: CollectionType;
  removeCollectionCb: (id: string) => void;
  updateCollection: (id: string, name: string) => void;
}

const Collection = ({
  collection,
  removeCollectionCb,
  updateCollection,
}: CollectionProps) => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  const navigate = useNavigate();
  const [innerWidth, setInnerWidth] = useState<number>(
    document.documentElement.clientWidth
  );
  const { clicked, setClicked, points, setPoints } = useContextMenu();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setShouldBlockKeyboardNavigation(isModalOpen));
  }, [isModalOpen]);

  const handleResize = () => {
    setInnerWidth(document.documentElement.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const deleteCollection = async (collectionId: string) => {
    try {
      const controller = new AbortController();
      const signal = controller.signal;

      const req: DeleteCollectionRequest = {
        signal: signal,
        collectionId: collectionId,
      };

      if (!collection.id) return;

      const result = await DeleteCollection(req);

      if (!result.error) {
        removeCollectionCb(collection.id);
        dispatch(
          asyncNotification({
            notification: "Collection removed",
            isActive: true,
            isErrNotification: false,
          })
        );
      } else {
        asyncNotification({
          notification: "Failed to remove collection",
          isActive: true,
          isErrNotification: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={isModalOpen ? { cursor: "default" } : {}}
      onMouseLeave={(e) => {
        setClicked(false);
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        if (!clicked && !isModalOpen) setClicked(true);
        setPoints({
          x: e.pageX,
          y: e.pageY,
        });
      }}
      className={`${lightMode} collection-item`}
      onClick={(e) => {
        e.preventDefault();
        if (!clicked && !isModalOpen) {
          navigate(
            `/Navigation/Collections/CollectionInfo?collection-id=${collection.id}`
          );
          window.scrollTo(0, 0);
        }
      }}
    >
      {isModalOpen && (
        <EditCollectionModal
          collection={collection}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onUpdateSuccessfully={(newCollection: CollectionType) => {
            updateCollection(collection.id, newCollection.name);
            dispatch(
              asyncNotification({
                notification: "Collection updated successfully.",
                isActive: true,
              })
            );
          }}
        />
      )}
      {clicked && (
        <ContextMenu top={points.y} left={points.x} theme={lightMode}>
          <div
            className={`${lightMode} dropdown-content-div collections-context-menu`}
            onClick={() => setIsModalOpen(true)}
          >
            <img
              src={lightMode === "light" ? EditImg : EditImgDark}
              alt={"edit"}
            />{" "}
            Edit collection
          </div>
          <div
            className={`${lightMode} dropdown-content-div collections-context-menu`}
            style={{ color: "#EB5757" }}
            onClick={() => deleteCollection(collection.id)}
          >
            <img src={Trash} />
            Remove collection
          </div>
        </ContextMenu>
      )}
      <div className={`${lightMode} company-screens`}>
        <StyledSourceImageBackground
          innerWidth={innerWidth}
          areFiltersOpen={false}
          className={`${lightMode} four-image-background`}
          style={{
            backgroundImage: `url(${
              collection.contentList &&
              collection.contentList.length > 0 &&
              collection.contentList[0].presignedURLResized
                ? collection.contentList[0].presignedURLResized
                : defaultImage
            })`,
          }}
        />
        <StyledSourceImageBackground
          innerWidth={innerWidth}
          areFiltersOpen={false}
          className={`${lightMode} four-image-background`}
          style={{
            backgroundImage: `url(${
              collection.contentList &&
              collection.contentList.length > 1 &&
              collection.contentList[1].presignedURLResized
                ? collection.contentList[1].presignedURLResized
                : defaultImage
            })`,
          }}
        />

        <StyledSourceImageBackground
          innerWidth={innerWidth}
          areFiltersOpen={false}
          className={`${lightMode} four-image-background`}
          style={{
            backgroundImage: `url(${
              collection.contentList &&
              collection.contentList.length > 2 &&
              collection.contentList[2].presignedURLResized
                ? collection.contentList[2].presignedURLResized
                : defaultImage
            })`,
          }}
        />

        <StyledSourceImageBackground
          innerWidth={innerWidth}
          areFiltersOpen={false}
          className={`${lightMode} four-image-background`}
          style={{
            backgroundImage: `url(${
              collection.contentList &&
              collection.contentList.length > 3 &&
              collection.contentList[3].presignedURLResized
                ? collection.contentList[3].presignedURLResized
                : defaultImage
            })`,
          }}
        />
      </div>

      <div className={`${lightMode} collection-info`}>
        <img src={collectionImg} alt="" />
        <div className={`${lightMode} collection-details`}>
          <h2>{collection.name}</h2>
          <h3>
            {collection.contentList ? collection.contentList.length : 0} screens
          </h3>
        </div>
      </div>
    </div>
  );
};
export default Collection;
