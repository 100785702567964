import Joi from "joi";
import { Member } from "./member";
import { Workspace } from "./workspace";

export interface PaymantPlan {
	plan: {
		id: string;
		purchasable: boolean;
		name: string;
		description: string;
		stripePriceId?: string;
		numberOfCollections: number;
		numberOfUsers: number;
		numberOfContentPerMonth: number;
		numberOfBoards: number;
		priceUSCents: number;
		type: string;
	};
	remainingLimits: {
		numberOfCollections: number;
		numberOfUsers: number;
		numberOfContentPerMonth: number;
		numberOfBoards: number;
	};
}

export interface Project {
	name?: string | null;
	id: string;
	isAdmin: boolean;
	createdAt: Date;
	updatedAt: Date;
	billingEmail?: string;
	stripeCustomerId?: string;
	maxNoOfUsers: number;
	members: Member[];
	invites: any[];
	boards?: Workspace[];
	website?: string;
	image?: string | undefined;
	imageData?: string | null;
	paymentPlan: PaymantPlan;
}

const ProjectSchema = Joi.object({
	name: Joi.string().allow(null, "").optional(),
	id: Joi.string().required().uuid(),
}).unknown();

const ProjectSchemaArray = Joi.array().items(ProjectSchema);

export async function toProjects(data: any[]): Promise<Project[]> {
	const validationResult = await ProjectSchemaArray.validate(data);
	if (validationResult.error) {
		throw new Error(validationResult.error.message);
	}

	return validationResult.value.map((data) => {
		return Object.assign({}, data) as Project;
	});
}
