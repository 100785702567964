import React, { useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { Member } from "../../../utils/types/member";
import Avatar from "react-avatar";
import "./EditRoleModal.css";
import down from "../../../icons/chevron-right.svg";
import downDark from "../../../icons/chevron-right-dark.svg";

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	children?: React.ReactNode;
	member: Member;
}

interface FormValues {
	name: string;
	industry: string;
	image: any;
	website: string;
	id: string;
	signal: any;
}

const initialValues: FormValues = {
	name: "",
	industry: "",
	image: null,
	website: "",
	id: "",
	signal: null,
};
const EditRoleModal: React.FC<ModalProps> = ({
	isOpen,
	onClose,
	children,
	member,
}) => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (event.target === event.currentTarget) {
			setIsDropdownOpen(false);
			onClose();
		}
	};

	if (!isOpen) return null;

	return (
		<div className={`${lightMode} modal-overlay`} onClick={handleOverlayClick}>
			<div className={`${lightMode} modal email`}>
				<div className={`${lightMode} modal-content`}>
					<h3 className={`${lightMode} create-board-modal-title email`}>
						Edit member role
					</h3>
					<div className={`${lightMode} role-modal-container`}>
						<div className={`${lightMode} role-modal-header`}>
							{member.imageS3URL ? (
								<img
									id="output"
									width="40px"
									alt="user-icon"
									src={member.imageS3URL || ""}
								/>
							) : (
								<Avatar
									key={"output"}
									name={member.fullName ? member.fullName[0].toUpperCase() : ""}
									initials={
										member.fullName ? member.fullName[0].toUpperCase() : ""
									}
									className={`${lightMode} workspace-avatar small-rounded`}
									value="1"
									maxInitials={1}
									textSizeRatio={2}
									color="#f2f4f7"
									size="40px"
									fgColor="rgba(71, 84, 103, 1)"
									src={member.imageS3URL ?? ""}
								/>
							)}
							<div className="role-modal-mail">
								<p>{member.fullName || "UXMaps User"}</p>
								<p>{member.email}</p>
							</div>
						</div>
						<div className={`${lightMode} role-modal-changer`}>
							<h3 className={`${lightMode} create-board-modal-small-title`}>
								Role
							</h3>
							<div className={`${lightMode} member-role-container`}>
								<div
									onClick={() => setIsDropdownOpen((prevState) => !prevState)}
									className={`${lightMode} board-role-input board-role-input`}>
									<h3 className={`selected-board-role selected-board-role`}>
										Member
									</h3>
									<img
										className={`${lightMode} down`}
										src={lightMode === "light" ? down : downDark}
										alt=""
									/>
								</div>
								{isDropdownOpen && (
									<div className={`board-role-options board-role-options`}>
										<h3 className={`board-role-option board-role-option`}>
											Member
										</h3>
										<h3 className={`board-role-option board-role-option`}>
											Admin
										</h3>
									</div>
								)}
							</div>
						</div>
						<div className="role-modal-btn-container">
							{" "}
							<button type="submit" className={`${lightMode} save-button `}>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditRoleModal;
