import { store } from "../../../store/store";
import { Fetch, FetchInput, Method } from "../../Fetch";
import { Flow } from "../../types/flow";
import { mockedFlowWithScreens } from "../../../mocks/mockedScreenForLocks";

export interface FetchFlowsByTagsRequest {
  tags: string[];
  page: number;
  limit: number;
  shouldBeRandom: boolean;
  seed: number;
  signal?: AbortSignal;
}

export interface FetchFlowsByTagsResponse {
  response: Array<Flow>;
  error: string;
  planLimitExceeded: boolean;
}

export async function FetchFlowsByTags(
  request: FetchFlowsByTagsRequest
): Promise<FetchFlowsByTagsResponse> {
  const state = store.getState();
  const projectId = state.navigation.projectId;
  if (!projectId || projectId.length === 0) {
    return {
      response: [],
      error: "No project id selected",
      planLimitExceeded: false,
    };
  }

  const FetchSearchTagsURL = `/me/example/by_tag_list?tagIdList=${request.tags}&limit=${request.limit}&page=${request.page}&projectId=${projectId}&shouldBeRandom=${request.shouldBeRandom}&seed=${request.seed}`;

  try {
    const input: FetchInput = {
      method: Method.Get,
      url: FetchSearchTagsURL,
      signal: request.signal,
    };

    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      const finalResponse = response.result.result ?? [];
      if (response.result.planLimitExceeded) {
        for (let i = 0; i < 10; i++) {
          finalResponse.push(mockedFlowWithScreens);
        }
      }

      if (response) {
        return {
          response: finalResponse,
          error: "",
          planLimitExceeded: response.result.planLimitExceeded,
        };
      }

      return {
        response: [],
        error: "Server side error",
        planLimitExceeded: false,
      };
    }

    return {
      response: [],
      error: result.error?.message ?? "",
      planLimitExceeded: false,
    };
  } catch (err) {
    console.error(err);
    return {
      response: [],
      error: "error",
      planLimitExceeded: false,
    };
  }
}
