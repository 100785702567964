import "../InvitePeople.css";
import "./Team.css";
import { useEffect, useState } from "react";

import Members from "./Members";
import { Member } from "../../../utils/types/member";
import { useAppSelector } from "../../../store/hooks";

interface MemberListProps {
	members: Member[];
	invites: any[];
	error?: string;
	teamType: string;
	removeInvitation: (member: any) => void;
	userRole?: string;
}
const Team = ({
	members,
	invites,
	removeInvitation,
	teamType,
	userRole,
}: MemberListProps) => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const [selected, setSelected] = useState("Members");
	useEffect(() => {
		const categoryLinks = document.querySelectorAll(".category-link");
		categoryLinks.forEach((link: Element) => {
			link.addEventListener("click", () => {
				categoryLinks.forEach((link) => {
					if (link.classList.contains("selected-category")) {
						link.classList.remove("selected-category");
					}
				});
				if (link.textContent) {
					setSelected(link.textContent);
				}
				if (link !== null) {
					link.classList.add("selected-category");
				}
			});
		});
	}, []);
	return (
		<>
			<div className={`${lightMode} team-wrapper`}>
				<div className={`${lightMode} intro team`}>
					<h2 className="capitilize-me">{teamType} team</h2>
					<div className={`${lightMode} category`}>
						<h3
							className={`${lightMode} category-link selected-category`}
							id="Members">
							Members
						</h3>
						<h3 className={`${lightMode} category-link`} id="Invited">
							Invited
						</h3>
						{/* 
					<h3 className={`${lightMode} category-link`} id="Deactivated">
						Deactivated
					</h3> */}
					</div>
				</div>

				<Members
					userRole={userRole}
					members={selected === "Invited" ? invites : members}
					selected={selected}
					invites={invites}
					removeInvitation={(member: any) => removeInvitation(member)}
				/>
			</div>
		</>
	);
};
export default Team;
