import React, { useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { CreateWorkspace } from "../../utils/backend/Workspace/CreateWorkspace";
import { imageDataToBase64String } from "../../utils/image";
import SelectWorkspace from "./SelectWorkspace";

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	children?: React.ReactNode;
}

interface FormValues {
	name: string;
	industry: string;
	image: any;
	website: string;
	id: string;
	signal: any;
}

const initialValues: FormValues = {
	name: "",
	industry: "",
	image: null,
	website: "",
	id: "",
	signal: null,
};
const SelectWorkspaceModal: React.FC<ModalProps> = ({
	isOpen,
	onClose,
	children,
}) => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const [values, setValues] = useState<FormValues>(initialValues);
	const projectId = useAppSelector((state) => state.boards.selectedProject?.id);

	const handleChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>
	) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// Handle image upload
		const file = e.target.files && e.target.files[0];
		if (file) {
			setValues({
				...values,
				image: file,
			});
		}
	};

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	if (!isOpen) return null;

	const loadFile = (event: any) => {
		const img = document.getElementById("output") as HTMLImageElement;
		if (img != null) {
			img.src = URL.createObjectURL(event.target.files[0]);
			setValues({ ...values, image: img.src });
		}
	};

	return (
		<div className={`${lightMode} modal-overlay`} onClick={handleOverlayClick}>
			<SelectWorkspace />
		</div>
	);
};

export default SelectWorkspaceModal;
