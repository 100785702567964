import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Tag } from "../../utils/types/tag";
import { TagType, TagTypeWithTags } from "../../utils/types/tagType";
import { FetchAllTagTypesWithTags } from "../../utils/backend/TagType/AllWithTags";

// Define a type for the slice state
interface Tags {
  tags: Tag[];
  tagTypes: TagType[];
  tagsByType: Tag[];
  allTagTypesWithTags: TagTypeWithTags[];
}

const ONE_MINUTE_MILLISECONDS = 60000;
// one day
const DEFAULT_TAGS_CACHE_TTL = 10 * ONE_MINUTE_MILLISECONDS;
const ALL_TAG_TYPES_LOCAL_STORAGE_KEY = "allTagTypesWithTags";

export const getInitialAllTagTypesWithTags = async (): Promise<
  TagTypeWithTags[]
> => {
  try {
    const localStorageData = JSON.parse(
      localStorage.getItem(ALL_TAG_TYPES_LOCAL_STORAGE_KEY) ?? "{}"
    );

    if (
      !isNaN(localStorageData.time) &&
      localStorageData.time > new Date().getTime()
    ) {
      return localStorageData.data as TagTypeWithTags[];
    }

    const result = await FetchAllTagTypesWithTags();
    return result.response;
  } catch (e) {
    console.error(e);
    return [];
  }
};

// Define the initial state using that type
const initialState: Tags = {
  tags: [],
  tagTypes: [],
  tagsByType: [],
  allTagTypesWithTags: [],
};

export const tagsSlice = createSlice({
  name: "tagsSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    getTags: (state, action: PayloadAction<Tag[]>) => {
      state.tags = action.payload;
    },
    getTagTypes: (state, action: PayloadAction<TagType[]>) => {
      state.tagTypes = action.payload;
    },
    getTagsByType: (state, action: PayloadAction<Tag[]>) => {
      state.tagsByType = action.payload;
    },
    setAllTagTypesWithTags: (
      state,
      action: PayloadAction<TagTypeWithTags[]>
    ) => {
      localStorage.setItem(
        ALL_TAG_TYPES_LOCAL_STORAGE_KEY,
        JSON.stringify({
          data: action.payload,
          time: new Date().getTime() + DEFAULT_TAGS_CACHE_TTL,
        })
      );
      state.allTagTypesWithTags = action.payload;
    },
  },
});

export const { getTags, getTagTypes, getTagsByType, setAllTagTypesWithTags } =
  tagsSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default tagsSlice.reducer;
