import { Flow } from "../utils/types/flow";
import { Screen } from "../utils/types/screen";

export const mockedScreen: Screen = {
  id: "screen-12345",
  name: "Locked Screen",
  tagList: [],
  presignedURLResized: "../icons/LightLocked.png",
  presignedURL: "https://example.com/original-image.png",
  collectionIdList: [],
  locked: true,
};

export const mockedFlow: Flow = {
  approved: true,
  contentList: [],
  id: "flow-locked-123",
  name: "Locked Flow",
  tagTypeId: "1",
  tagList: [],
};

export const mockedFlowWithScreens: Flow = {
  approved: true,
  contentList: [mockedScreen, mockedScreen, mockedScreen, mockedScreen],
  id: "flow-locked-123",
  name: "Locked Flow",
  tagTypeId: "1",
  tagList: [],
};
