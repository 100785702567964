import { mockedScreen } from "../../../mocks/mockedScreenForLocks";
import { store } from "../../../store/store";
import { Fetch, FetchInput, Method } from "../../Fetch";
import { Screen, toScreenList } from "../../types/screen";

export interface FetchScreensByTagsRequest {
  tags: string[];
  page: number;
  limit: number;
  shouldBeRandom: boolean;
  seedValue: number;
  signal?: AbortSignal;
}

export interface FetchScreensByTagsResponse {
  response: Screen[];
  error: string;
  planLimitExceeded: boolean;
}

export async function FetchScreensByTags(
  request: FetchScreensByTagsRequest
): Promise<FetchScreensByTagsResponse> {
  const state = store.getState();
  const projectId = state.navigation.projectId;

  if (!projectId || projectId.length === 0) {
    return {
      response: [],
      error: "No project id selected",
      planLimitExceeded: false,
    };
  }
  const FetchSearchTagsURL = `/me/variations/by_tag_list?tagIdList=${request.tags}&page=${request.page}&limit=${request.limit}&projectId=${projectId}&shouldBeRandom=${request.shouldBeRandom}&seed=${request.seedValue}`;
  try {
    const input: FetchInput = {
      method: Method.Get,
      url: FetchSearchTagsURL,
      signal: request.signal,
    };
    const result = await Fetch(input);

    if (result.isOk) {
      const response = await result;

      if (response && !response.result.planLimitExceeded) {
        return {
          response: await toScreenList(response.result.result),
          error: "",
          planLimitExceeded: false,
        };
      } else if (response && response.result.planLimitExceeded) {
        const newScreens = await toScreenList(response.result.result ?? []);

        for (let i = newScreens.length; i < 50; i++) {
          newScreens.push(mockedScreen);
        }

        return {
          response: newScreens,
          error: "",
          planLimitExceeded: true,
        };
      }

      return {
        response: await toScreenList(response.result.result),
        error: "Server side error",
        planLimitExceeded: true,
      };
    } else {
      return {
        response: [],
        error: result.error?.message ?? "Ding dong",
        planLimitExceeded: true,
      };
    }
  } catch (err) {
    return {
      response: [],
      error: "Ding dong",
      planLimitExceeded: false,
    };
  }
}
