import { Tag, tagListScheme } from "./tag";
import Joi from "joi";

export interface Screen {
	id: string;
	name?: string;
	tagList: Tag[];
	presignedURLResized?: string | null;
	presignedURL?: string | null;
	collectionIdList: string[];
	locked?: boolean;
}

export const screenSchema = Joi.object({
	id: Joi.string().required().uuid(),
	name: Joi.string().allow(null, "").optional(),
	tagList: tagListScheme,
	presignedURL: Joi.string().allow("", null).optional(),
	presignedURLResized: Joi.string().allow("", null).optional(),
	collectionIdList: Joi.array().items(Joi.string()).optional().allow(null),
}).unknown();

export const screenListSchema = Joi.array().items(screenSchema);

export async function toScreenList(data: unknown): Promise<Screen[]> {
	const validationResult = await screenListSchema.validate(data);
	if (validationResult.error) {
		// throw new Error(validationResult.error.message);
		return [];
	}

	return validationResult.value.map((data) => {
		return Object.assign({}, data) as Screen;
	});
}
