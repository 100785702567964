import "./InvitePeople.css";
import { useState } from "react";
import plus from "../../icons/plus.svg";
import plusDark from "../../icons/plusDark.svg";
import SingleInvite from "./SingleInvite";
import {
  InviteToBoard,
  InviteToBoardRequest,
} from "../../utils/backend/Invites/InviteToBoard";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  InviteToSuperboard,
  InviteToSuperBoardRequest,
} from "../../utils/backend/Invites/InviteToSuperboard";
import { asyncNotification } from "../../store/slice/notificationSlice";
import UpgradePlanModal from "../UpgradePlanModal/UpgradePlanModal";

interface InvitePeopleProps {
  boardId: string;
  boardType: string;
  onInvite: (users: any) => void;
  onInviteWithLink: () => void;
}

const InvitePeople = ({
  boardId,
  boardType,
  onInviteWithLink,
  onInvite,
}: InvitePeopleProps) => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  const [invites, setInvites] = useState<Object[]>([{ id: 0 }]);
  const [invitesData, setInvitesData] = useState<any>({});
  const [restartState, setRestartState] = useState<any>(false);
  const [index, setIndex] = useState(1);
  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false);
  const paymentPlan = useAppSelector(
    (state) => state.boards.selectedProject?.paymentPlan
  );
  const dispatch = useAppDispatch();

  const newInvite = () => {
    setIndex((prev) => prev + 1);
    setInvites((prev: Object[]) => [...prev, { id: index }]);
  };

  const sendInvites = async () => {
    if (
      paymentPlan &&
      paymentPlan.remainingLimits &&
      paymentPlan.remainingLimits.numberOfUsers > 0 &&
      paymentPlan.remainingLimits.numberOfUsers >= invites.length
    ) {
      const pushUsers = [];
      let shouldSendUsers = true;

      for (let i = 0; i < index; i++) {
        if (invitesData[i].email === "") continue;

        const reqBoard: InviteToBoardRequest = {
          boardId: boardId,
          userEmail: invitesData[i].email,
          role: invitesData[i].role,
        };
        const reqWorkspace: InviteToSuperBoardRequest = {
          boardId: boardId,
          userEmail: invitesData[i].email,
          role: invitesData[i].role,
        };
        if (boardType === "workspace") {
          try {
            const resp = await InviteToSuperboard(reqWorkspace);
            if (resp.error)
              dispatch(
                asyncNotification({
                  notification: "Invitation failed.",
                  isActive: true,
                  isErrNotification: true,
                })
              );

            pushUsers.push({
              id: resp.response.result.inviteId,
              isNewUser: true,
              userEmail: invitesData[i].email,
              role: invitesData[i].role,
            });
          } catch (error) {
            shouldSendUsers = false;
            return;
          }
        } else {
          try {
            const resp = await InviteToBoard(reqBoard);
            if (resp.error)
              dispatch(
                asyncNotification({
                  notification: "Invitation failed.",
                  isActive: true,
                  isErrNotification: true,
                })
              );

            pushUsers.push({
              id: resp.response.result.inviteId,
              isNewUser: true,
              userEmail: invitesData[i].email,
              role: invitesData[i].role,
            });
          } catch (error) {
            shouldSendUsers = false;
            return;
          }
        }
      }
      if (shouldSendUsers) {
        onInvite(pushUsers);
        setInvites([{ id: 0 }]);
        setInvitesData({});
        setIndex(1);
        setRestartState(true);
        dispatch(
          asyncNotification({
            notification: "Invitation sent.",
            isActive: true,
            isErrNotification: false,
          })
        );
      }
    } else {
      setUpgradeOpen(true);
    }
  };
  const restartCb = () => {
    setRestartState(false);
  };

  const update = (id: any, email: string, role: string) => {
    setInvitesData((prev: any) => {
      return { ...prev, [id]: { email: email, role: role } };
    });
  };
  const ClickMe = () => {
    onInviteWithLink();
  };

  const displayInvites = invites.map((invite: any) => (
    <SingleInvite
      restartCb={restartCb}
      restart={restartState}
      key={invite.id}
      index={invite.id}
      update={update}
    />
  ));

  return (
    <>
      <div className={`${lightMode} intro invite-people`}>
        <UpgradePlanModal
          isOpen={upgradeOpen}
          onClose={() => setUpgradeOpen(false)}
          type="workspaceUserLimit"
        />
        <div>
          <h2>Invite people</h2>
          <h3>Invite people to your {boardType}.</h3>
        </div>
      </div>
      <div className={`${lightMode} titles`}>
        {" "}
        <label
          htmlFor="email-inv"
          className={`${lightMode} label-class label-class-email`}
        >
          Email
        </label>
        <label
          htmlFor="role"
          className={`${lightMode} label-class label-class-role`}
        >
          Role
        </label>
      </div>
      <div className={`${lightMode} all-invites`}>
        {displayInvites}
      </div>

      <button className={`${lightMode} add-another`} onClick={newInvite}>
        <img
          src={lightMode === "light" ? plus : plusDark}
          className={`${lightMode} plus`}
          alt=""
        />{" "}
        Add another
      </button>
      <div className={`${lightMode} down-area`}>
        <button className={`${lightMode} save-button`} onClick={sendInvites}>
          Invite
        </button>
      </div>
    </>
  );
};
export default InvitePeople;
