import { Fetch, FetchInput, Method } from "../../Fetch";

export interface DeleteFromCollectionRequest {
  contentIdList: Array<string>;
  collectionId: string;
  signal?: AbortSignal;
}

export interface DeleteFromCollectionResponse {
  error: string;
}

export async function DeleteFromCollection(
  request: DeleteFromCollectionRequest
): Promise<DeleteFromCollectionResponse> {
  const DeleteFromCollectionURL = "/me/collection/delete_content_list";
  const requestBody = JSON.stringify({
    contentIdList: request.contentIdList,
    collectionId: request.collectionId,
  });
  try {
    const input: FetchInput = {
      method: Method.Patch,
      body: requestBody,
      url: DeleteFromCollectionURL,
      signal: request.signal,
    };
    const result = await Fetch(input);
    if (result.isOk) {
      const response = await result;
      if (response) {
        return {
          error: "",
        };
      }
      return {
        error: "Server side error",
      };
    } else {
      return {
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      error: "error",
    };
  }
}
