import { Fetch, FetchInput, Method } from "../../Fetch";

export interface DeleteCollectionRequest {
	collectionId: string;
	signal?: AbortSignal;
}

export interface DeleteCollectionResponse {
	error: string;
}

export async function DeleteCollection(
	request: DeleteCollectionRequest
): Promise<DeleteCollectionResponse> {
	const DeleteFromCollectionURL = `/me/collection?collectionId=${request.collectionId}`;
	const requestBody = JSON.stringify({
		collectionId: request.collectionId,
	});
	try {
		const input: FetchInput = {
			method: Method.Delete,
			body: requestBody,
			url: DeleteFromCollectionURL,
			signal: request.signal,
		};
		const result = await Fetch(input);
		if (result.isOk) {
			const response = await result;
			if (response) {
				return {
					error: "",
				};
			}
			return {
				error: "Server side error",
			};
		} else {
			return {
				error: result.error?.message ?? "",
			};
		}
	} catch (err) {
		console.error(err);
		return {
			error: "error",
		};
	}
}
