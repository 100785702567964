import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import checked from "../../icons/checkedLight.svg";
import checkedDark from "../../icons/checkedDark.svg";
import minus from "../../icons/minus.svg";
import plus from "../../icons/pl.svg";
import { useSearchParams } from "react-router-dom";
import {
  SendPlan,
  SendPlanRequest,
} from "../../utils/backend/SendPlan/SendPlan";

interface PlanProps {
  projId?: string;
  isUserOnFreePlan?: boolean;
}

const inclusions = [
  "Unlimited screens",
  "Unlimited companies, flows, and elements",
  "Unlimited boards",
  "Unlimited collections",
  "Team management",
  "Shareable team collections",
  "One-time payment",
  "Minimum 2 accounts",
];

const TeamPlan = ({ projId, isUserOnFreePlan }: PlanProps) => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  const [counter, setCounter] = useState<number>(
    isUserOnFreePlan === true ? 2 : 1
  );
  const [searchParams] = useSearchParams();
  const workspaceId = useAppSelector(
    (state) => state.boards.selectedProject?.id
  );
  const quantity = searchParams.get("quantity");

  useEffect(() => {
    if (quantity) {
      const actualQuantityValue = parseInt(quantity);
      if (
        actualQuantityValue &&
        (actualQuantityValue > 1 || isUserOnFreePlan === false)
      ) {
        setCounter(actualQuantityValue);
      }
    }
  }, [isUserOnFreePlan, quantity]);

  const upgradePlan = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    const req: SendPlanRequest = {
      CancelURL: `${window.location.origin}/Navigation/Screens/?success=false&quantity=${counter}`,
      quantity: counter,
      SuccessURL: `${window.location.origin}/Navigation/Screens/?success=true&quantity=${counter}`,
      SuperBoardID:
        projId || workspaceId! || localStorage.getItem("projectId")!,
      signal: signal,
    };

    const result = await SendPlan(req);
    if (result.response && result.response) {
      window.location.href = result.response;
    }
  };

  const reduceCounter = () => {
    if (counter > 2 || (isUserOnFreePlan === false && counter > 1)) {
      setCounter((prev) => prev - 1);
    } else {
      return;
    }
  };

  return (
    <div className={`${lightMode} plan-card-container`}>
      <div className={`${lightMode} plan-card-header`} id={"team-header"}>
        <h1 className={`${lightMode} plan-card-header-large team`}>
          Lifetime License <div className="pill">20% OFF</div>
        </h1>
        <h2 className={`${lightMode} plan-card-header-small`}>
          USD ${77 * counter}
          <p className={`${lightMode} plan-card-header-small gray`}>once</p>
        </h2>
      </div>
      <div className={`${lightMode} plan-card-inclusion`} id={"team-body"}>
        {inclusions.map((e) => {
          return (
            <div className={`${lightMode} one-incl`}>
              <img
                src={lightMode === "light" ? checked : checkedDark}
                alt={"checked"}
              />
              <p
                style={
                  e === "One-time payment" || e === "Minimum 2 accounts"
                    ? { margin: 0, fontWeight: 600 }
                    : { margin: 0 }
                }
              >
                {e}
              </p>
            </div>
          );
        })}
      </div>
      <div className={`${lightMode} plan-card-footer team`}>
        <div className={`${lightMode} plan-card-counter`}>
          <div
            className={`${lightMode} plan-card-minus`}
            onClick={() => reduceCounter()}
          >
            {" "}
            <img src={minus} alt={"minus"} />
          </div>
          {counter + ` license${counter > 1 ? "s" : ""} included`}
          <div
            className={`${lightMode} plan-card-minus`}
            onClick={() => setCounter((prev) => prev + 1)}
          >
            <img src={plus} alt={"plus"} />
          </div>
        </div>
        <button
          className={`${lightMode} plan-card-button activate`}
          onClick={upgradePlan}
        >
          Continue to checkout
        </button>
      </div>
    </div>
  );
};

export default TeamPlan;
