import { useAppSelector } from "../../../store/hooks";
import {
	UpdateCollection,
	UpdateCollectionRequest,
} from "../../../utils/backend/Collection/UpdateCollection";
import { Collection } from "../../../utils/types/collection";
import { useState } from "react";

interface EditCollectionProps {
	collection: Collection;
	closed: boolean;
	onClose(): void;
	onUpdateSuccessfully(newCollection: Collection): void;
}

const EditCollection = ({
	closed,
	onClose,
	collection,
	onUpdateSuccessfully,
}: EditCollectionProps) => {
	const [name, setName] = useState<string>(collection.name);
	const lightMode = useAppSelector((state) => state.navigation.lightMode);

	const saveEdit = async () => {
		const req: UpdateCollectionRequest = {
			id: collection.id,
			name: name,
		};
		try {
			const result = await UpdateCollection(req);
			if (result.error.length > 0) {
				// TODO: add error message to user screen
				console.error(result.error);
				return;
			}
			onUpdateSuccessfully({
				...collection,
				name: name,
			});
			onClose();
		} catch (e) {
			// TODO: add error message to user screen
			console.error(e);
		}
	};

	const handleChange = (e: any) => {
		setName(e.target.value);
	};

	return (
		<div className={`${lightMode} edit-container edit-container`}>
			<div className={`${lightMode} edit-collection`}>
				<h1 className={`${lightMode} create-board-modal-title`}>
					Edit collection
				</h1>
				<h3 className={`${lightMode} create-board-modal-sub`}>
					Allow people to join your board with the link below. Anyone who joins
					with this link will have the Member role..
				</h3>
				<h2 className={`${lightMode} create-board-modal-small-title `}>
					Collection name
				</h2>
				<input
					type="text"
					placeholder="Enter your collection name"
					className={`${lightMode} board-name-input collection-modal`}
					value={name}
					onChange={(e) => {
						setName(e.target.value);
					}}
				/>
				<div className={`${lightMode} edit-btns`}>
					<button
						className={`${lightMode} save-button no-margins`}
						onClick={saveEdit}>
						Save
					</button>
				</div>
			</div>
		</div>
	);
};
export default EditCollection;
