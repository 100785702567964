import React, { useState } from "react";
import EditCollection from "./EditCollection";
import { Collection } from "../../../utils/types/collection";
import { useAppSelector } from "../../../store/hooks";

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	children?: React.ReactNode;
	collection: Collection;
	onUpdateSuccessfully(newCollection: Collection): void;
}

interface FormValues {
	name: string;
	industry: string;
	image: any;
	website: string;
	id: string;
	signal: any;
}

const initialValues: FormValues = {
	name: "",
	industry: "",
	image: null,
	website: "",
	id: "",
	signal: null,
};
const EditCollectionModal: React.FC<ModalProps> = ({
	isOpen,
	onClose,
	children,
	collection,
	onUpdateSuccessfully,
}) => {
	const lightMode = useAppSelector((state) => state.navigation.lightMode);

	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	if (!isOpen) return null;

	return (
		<div className={`${lightMode} modal-overlay`} onClick={handleOverlayClick}>
			<div className={`${lightMode} modal`}>
				<div className={`${lightMode} modal-content`}>
					<EditCollection
						closed={!isOpen}
						collection={collection}
						onUpdateSuccessfully={onUpdateSuccessfully}
						onClose={() => onClose()}
					/>
				</div>
			</div>
		</div>
	);
};

export default EditCollectionModal;
