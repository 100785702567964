import { Company as CompanyType } from "../../../utils/types/company";
import { Tag } from "../../../utils/types/tag";
import ErrorPage from "../../ErrorPage/ErrorPage";
import Filters from "../../FiltersComponent/Filters";
import { StyledGridDivSourcePage } from "./SourcePage.styles";
import Company from "../../companies/Company/Company";
import { useEffect, useRef, useState } from "react";
import Loader from "../../Loader/Loader";
import { useAppSelector } from "../../../store/hooks";
import NoResultsFoundPage from "../../NoResultsFoundPage/NoResultsFoundPage";

interface SourceListProps {
  source: CompanyType[];
  error?: string;
  noMoreData: boolean;
  isLoadingMore: boolean;
  tags: Tag[];

  onLoadMore(): void;

  shouldDisplayFilters: boolean;
  pages: number;
  filteredSource: CompanyType[];
}

const SourceDisplay = ({
  error,
  onLoadMore,
  isLoadingMore,
  shouldDisplayFilters,
  pages,
  filteredSource,
}: SourceListProps) => {
  const [innerWidth, setInnerWidth] = useState<number>(
    document.body.clientWidth
  );
  const lightMode = useAppSelector((state) => state.navigation.lightMode);

  const handleResize = () => {
    setInnerWidth(document.body.clientWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isLoadingMore, shouldDisplayFilters, filteredSource]);

  const displayCompanies = () => {
    return filteredSource.map((company: CompanyType, index) => {
      if (index < (pages + 1) * 36)
        return <Company key={company.id} company={company} />;
    });
  };
  const loader = useRef(null);

  const loadMoreImages = async () => {
    onLoadMore();
  };

  const handleObserver = (entities: IntersectionObserverEntry[]) => {
    const target = entities[0];
    if (target.isIntersecting) {
      loadMoreImages();
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, []);

  if (error && error.length > 0) {
    return <ErrorPage />;
  }

  return (
    <>
      <div className={`${lightMode} flows source`}>
        <div
          className={`${lightMode} grid-container`}
          id="grid-container"
          style={shouldDisplayFilters ? { display: "flex" } : {}}
        >
          {filteredSource.length === 0 && isLoadingMore && <Loader />}
          {filteredSource.length === 0 && !isLoadingMore && !error && (
            <NoResultsFoundPage />
          )}
          {error && error.length > 0 && <ErrorPage />}
          {filteredSource.length > 0 && (
            <StyledGridDivSourcePage
              areFiltersOpen={shouldDisplayFilters}
              innerWidth={innerWidth}
            >
              {displayCompanies()}
            </StyledGridDivSourcePage>
          )}
          <Filters displaysFrom="source" />
        </div>

        <div ref={loader} style={{ height: "10px" }}></div>
      </div>
    </>
  );
};

export default SourceDisplay;
