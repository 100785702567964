import { store } from "../../../store/store";
import { Fetch, FetchInput, Method } from "../../Fetch";
import { validate as isValidUUID } from "uuid";

export interface CreateCollectionRequest {
	name: string;
	signal?: AbortSignal;
}

export interface CreateCollectionResponse {
	error: string;
	collectionId?: string;
}

export async function CreateCollection(
	request: CreateCollectionRequest
): Promise<CreateCollectionResponse> {
	const state = store.getState();
	const boardId = state.navigation.boardId;
	if (!boardId || boardId.length === 0) {
		return {
			error: "No board id selected",
		};
	}
	const CreateCollectionURL = "/me/collection";
	const requestBody = JSON.stringify({
		name: request.name,
		workspaceId: boardId,
	});
	try {
		const input: FetchInput = {
			method: Method.Post,
			body: requestBody,
			url: CreateCollectionURL,
			signal: request.signal,
		};
		const result = await Fetch(input);
		if (result.isOk) {
			const response = await result;
			if (response && response.result.id && isValidUUID(response.result.id)) {
				return {
					collectionId: response.result.id,
					error: "",
				};
			}
			return {
				error: "Server side error",
			};
		} else {
			return {
				error: result.error?.message ?? "",
			};
		}
	} catch (err) {
		console.error(err);
		return {
			error: "error",
		};
	}
}
