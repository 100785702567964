import { Fetch, FetchInput, Method } from "../../Fetch";

export interface CreateWorkspaceRequest {
	name: string;
	industry: string;
	image: any;
	website: string;
	signal?: AbortSignal;
	id: string;
}

export interface CreateWorkspaceResponse {
	error: string;
	response: any;
}

export async function CreateWorkspace(
	request: CreateWorkspaceRequest
): Promise<CreateWorkspaceResponse> {
	const CreateWorkspaceURL = "/me/workspace";
	const requestBody = JSON.stringify({
		name: request.name,
		industry: request.industry,
		image: request.image,
		website: request.website,
		projectId: request.id,
	});
	try {
		const input: FetchInput = {
			method: Method.Post,
			url: CreateWorkspaceURL,
			signal: request.signal,
			body: requestBody,
		};
		const result = await Fetch(input);

		if (result.isOk) {
			const response = await result;
			if (response) {
				return {
					error: "",
					response: response.result.id,
				};
			}

			return {
				error: "Server side error",
				response: "",
			};
		} else {
			return {
				error: result.error?.message ?? "",
				response: "",
			};
		}
	} catch (err) {
		console.error(err);
		return {
			error: "error",
			response: "",
		};
	}
}
