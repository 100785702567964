import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import "./SignUp.css";
import { Link, useNavigate } from "react-router-dom";
import {
  FetchSignUp,
  RegisterRequest,
} from "../../utils/backend/SignIn SignUp/FetchSignUp";
import { GetUser } from "../../utils/backend/Profile/GetUser";
import { useAppSelector } from "../../store/hooks";
import ShowIcon from "../../icons/Show.svg";
import HideIcon from "../../icons/Hide.svg";
import posthog from "posthog-js";

interface SignSwitcherProps {
  setSwitcher: (switchState: string) => void;
}

function SignUp({ setSwitcher }: SignSwitcherProps) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLongEnough, setIsLongEnough] = useState<boolean>(false);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();

  const [isPasswordVisible, setIsPasswordVisible] = useState({
    repeat: false,
    pwd: false,
  });

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
  };

  useEffect(() => {
    setIsEmailValid(true);
  }, [email]);

  useEffect(() => {
    const currentUserToken = localStorage.getItem("token");
    if (!currentUserToken) {
      return;
    }
    const handleGetUser = async function () {
      const result = await GetUser();
      if (result.error.length > 0) {
        setError(
          "Something unexpected happened, try to reload page or contact us."
        );
        console.error(result.error);
        return;
      }

      if (result.user) {
        navigate("/Navigation/Screens");
      }
    };
    try {
      handleGetUser();
    } catch (e) {
      setError(
        "Something unexpected happened, try to reload page or contact us."
      );
      console.error(e);
    }
  }, [navigate]);

  useEffect(() => {
    setDoPasswordsMatch(true);
    setIsLongEnough(password.length > 7);
  }, [password]);

  useEffect(() => {
    setDoPasswordsMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  const checkPassword = useCallback(() => {
    return !!(password && password === confirmPassword);
  }, [confirmPassword, password]);

  const lengthCheck = useCallback(() => {
    let len = password.length;
    if (password) {
      if (len < 8) {
        return `Password must contain at least 8 characters`;
      } else {
        return "";
      }
    }
  }, [password]);

  const signUp = useCallback(() => {
    const regReq: RegisterRequest = {
      Email: email,
      Password: password,
      fullName: name,
    };

    FetchSignUp(regReq)
      .then((response) => response)
      .then((data) => {
        if (data.userId) {
          setIsEmailValid(true);
          localStorage.setItem("pwd", password);
          posthog.capture("userRegistered", {
            title: email,
          });
          navigate(`/verification?email=${encodeURIComponent(email)}`);
        } else {
          setIsEmailValid(false);
        }
      })
      .catch((err) => {
        if ((err = "409")) {
          setIsEmailValid(false);
        } else {
          console.error(err);
        }
      });
  }, [email, name, navigate, password]);

  const checkBeforeSignUp = useCallback(() => {
    if (isLongEnough && checkPassword()) {
      signUp();
    }

    if (!checkPassword()) {
      setDoPasswordsMatch(false);
    }
  }, [checkPassword, isLongEnough, signUp]);

  const lightMode = useAppSelector((state) => state.navigation.lightMode);

  useEffect(() => {
    function handleLogIn(ev: KeyboardEvent) {
      if (ev.key === "Enter") {
        checkBeforeSignUp();
      }
    }

    window.addEventListener("keypress", handleLogIn);

    return () => {
      window.removeEventListener("keypress", handleLogIn);
    };
  }, [checkBeforeSignUp]);

  return (
    <div className={`${lightMode} sign-page`}>
      <div className={`${lightMode} sign-container`}>
        <div className={`${lightMode} form`}>
          <div style={{ marginBottom: "20px" }}>
            <label htmlFor="email" className={`${lightMode} label`}>
              Name
            </label>
            <input
              type="text"
              id="name"
              className={`${lightMode} email sign-in-input`}
              onChange={handleNameChange}
              placeholder="Enter your name"
              style={{ marginBottom: "20px" }}
            />
            <label htmlFor="email" className={`${lightMode} label`}>
              Email
            </label>
            <input
              type="email"
              id="email"
              className={`${lightMode} email sign-in-input`}
              onChange={handleEmailChange}
              placeholder="Enter your email"
            />

            {!isEmailValid && (
              <h2 className={`${lightMode} error`}>
                This email is already in use
              </h2>
            )}
          </div>
          <label htmlFor="password" className={`${lightMode} label pass-label`}>
            Password
          </label>
          <div style={{ marginBottom: "20px" }}>
            <input
              type={isPasswordVisible.pwd ? "text" : "password"}
              id="password"
              className={`${lightMode} password sign-in-input`}
              onChange={handlePasswordChange}
              placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
            />
            <img
              src={isPasswordVisible.pwd ? HideIcon : ShowIcon}
              onClick={() =>
                setIsPasswordVisible({
                  ...isPasswordVisible,
                  pwd: !isPasswordVisible.pwd,
                })
              }
              className={`${lightMode} show-hide-image-signin sign-up-pwd-icon`}
            />
            {password && (
              <h2 className={`${lightMode} error`}> {lengthCheck()}</h2>
            )}
          </div>

          <label
            htmlFor="confirm-password"
            className={`${lightMode} label pass-label`}
          >
            Password confirmation
          </label>
          <div>
            <input
              type={isPasswordVisible.repeat ? "text" : "password"}
              id="confirm-password"
              className={`${lightMode} password sign-in-input`}
              onChange={handleConfirmPasswordChange}
              placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
            />
            <img
              src={isPasswordVisible.repeat ? HideIcon : ShowIcon}
              onClick={() =>
                setIsPasswordVisible({
                  ...isPasswordVisible,
                  repeat: !isPasswordVisible.repeat,
                })
              }
              className={`${lightMode} show-hide-image-signin sign-up-pwd-icon`}
            />
          </div>

          {!doPasswordsMatch && (
            <h2 className={`${lightMode} error match-error`}>
              Passwords do not match
            </h2>
          )}
        </div>

        <div className={`${lightMode} sign-up-buttons`}>
          <button
            className={`${lightMode} sign-btn up`}
            onClick={checkBeforeSignUp}
          >
            Sign up
          </button>
          {/* <button className={`${lightMode} sign-btn google`}>
						<img src={GoogleIcon} className={`${lightMode} google-icon`}></img>
						Sign up with Google
					</button> */}
        </div>

        <div className={`${lightMode} already-acc`}>
          <h2>Already have an account?</h2>
          <Link
            className={`${lightMode} already-acc-link`}
            to="/SignIn"
            onClick={() => setSwitcher("log-in")}
          >
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
