import { useAppSelector } from "../../store/hooks";

import "./UpgradePlanStyles.css";
import { PackageType } from "../SetupProfile/InitialBuyPlanPage";

interface SignSwitcherProps {
  setSwitcher: (switchState: PackageType) => void;
  switchState: PackageType;
}

const UpgradePlanSwitcher = ({
  setSwitcher,
  switchState,
}: SignSwitcherProps) => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);

  return (
    <div className={`${lightMode} switcher-container-upgrade-plan`}>
      <button
        id="personal"
        onClick={() => setSwitcher(PackageType.Personal)}
        className={
          switchState === "personal"
            ? `${lightMode} switcher-all-screens-sign-page upgrade-plan active`
            : `${lightMode} switcher-all-screens-sign-page upgrade-plan`
        }
      >
        Personal
      </button>
      <button
        id="team"
        onClick={() => setSwitcher(PackageType.Team)}
        className={
          switchState === "team"
            ? `${lightMode} switcher-all-screens-sign-page upgrade-plan active`
            : `${lightMode} switcher-all-screens-sign-page upgrade-plan`
        }
      >
        Team
      </button>
    </div>
  );
};

export default UpgradePlanSwitcher;
