import styled from "styled-components";

interface ContextMenuProps {
  top: number;
  left: number;
  theme: "light" | "dark";
}

export const ContextMenu = styled.div<ContextMenuProps>`
	position: absolute;
	width: 200px;
	background-color: #383838;
	border-radius: 8px;
	box-sizing: border-box;
	background-color: #fff;
	min-width: 200px;
	z-index: 1;
	color: #475467;
	font-size: 14px;
	border: 	background-color: ${({ theme }) =>
    theme === "light" ? "1px solid #eaecf0 " : "1px solid #292929"};
	background-color: ${({ theme }) => (theme === "light" ? "#fff" : "#2e2e2e ")};
	${({ top, left }) => `
		top: ${top}px;
		left: ${left}px;
	`}

	z-index: 1;
`;

export const ContextMenuCompanyItem = styled.div<ContextMenuProps>`
	position: absolute;
	width: 200px;
	background-color: #383838;
	border-radius: 8px;
	box-sizing: border-box;
	background-color: #fff;
	min-width: 200px;
	z-index: 1;
	color: #475467;
	font-size: 14px;
	border: 	background-color: ${({ theme }) =>
    theme === "light" ? "1px solid #eaecf0 " : "1px solid #292929"};
	background-color: ${({ theme }) => (theme === "light" ? "#fff" : "#2e2e2e ")};
	${({ top, left }) => `
		top: ${top}px;
		left: ${left}px;
	`}
	z-index: 1;
`;
