import {
	FetchScreensByTags,
	FetchScreensByTagsRequest,
} from "../../../utils/backend/Screen/FetchScreensByTags";
import { queryData } from "../../../utils/types/queryData";
import { Screen as ScreenType } from "../../../utils/types/screen";

interface fetchScreensInput {
	signal: AbortSignal;
	queryState: queryData;
	activeFilters: string[];
	seedValue: number;

	setError(err: string): void;

	setScreens: React.Dispatch<React.SetStateAction<ScreenType[]>>;
	setIsLastPage: React.Dispatch<React.SetStateAction<boolean>>;
}

export async function fetchScreens({
	activeFilters,
	queryState,
	signal,
	setError,
	setScreens,
	setIsLastPage,
	seedValue,
}: fetchScreensInput) {
	const req: FetchScreensByTagsRequest = {
		tags: activeFilters,
		page: queryState.page,
		limit: queryState.limit,
		shouldBeRandom: true,
		seedValue: seedValue,
		signal,
	};

	try {
		const result = await FetchScreensByTags(req);
		if (result.error.length > 0) {
			if (result.error === "The user aborted a request.") return;
			setError(
				"Something unexpected happened, try to reload page or contact us."
			);
			console.error(result.error);
			return;
		}

		setScreens((prev) => {
			return [...prev, ...result.response];
		});

		if (result.response.length < queryState.limit) {
			setIsLastPage(true);
		}
	} catch (e) {
		setError(
			"Something unexpected happened, try to reload page or contact us."
		);
		console.error(e);
	}
}
