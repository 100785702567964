import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NoResultsFound from "../../icons/NoResultsFound.svg";
import NoResultsFoundDark from "../../icons/NoResultsFoundDark.svg";
import "../NoResultsFoundPage/NoResultsFoundPage.css";
import search from "../../icons/SearchBlack.svg";
import plus from "../../icons/PlusWhite.svg";
import CreateCollectionModal from "./CreateCollectionModal";
import { useAppSelector } from "../../store/hooks";
import UpgradePlanModal from "../UpgradePlanModal/UpgradePlanModal";

interface NoCollectionsFoundProps {
  createCollection: Function;
  isPlanLimitExceeded: boolean;
}

const NoCollectionsFound = ({
  createCollection,
  isPlanLimitExceeded,
}: NoCollectionsFoundProps) => {
  const navigate = useNavigate();
  const [createCollectionModalOpen, setCreateCollectionModalOpen] =
    useState<boolean>(false);
  const [isUpgradePlanModalOpened, setIsUpgradePlanModalOpened] =
    useState<boolean>(false);

  const isCreated = () => {
    setCreateCollectionModalOpen(!createCollectionModalOpen);
    createCollection();
  };

  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  return (
    <>
      {createCollectionModalOpen && !isPlanLimitExceeded && (
        <CreateCollectionModal
          close={() => {
            setCreateCollectionModalOpen(false);
          }}
          created={isCreated}
        />
      )}
      <UpgradePlanModal
        onClose={() => setIsUpgradePlanModalOpened(false)}
        isOpen={isUpgradePlanModalOpened}
        type="limit"
      />
      <div className={`${lightMode} no-results-container`}>
        <div className={`${lightMode} no-results-img-container`}>
          <img
            alt={"no results found"}
            style={{ height: "20px", width: "20px" }}
            src={lightMode === "light" ? NoResultsFound : NoResultsFoundDark}
          />
        </div>
        <div className={`${lightMode} no-results-text`}>No collections yet</div>
        <div className={`${lightMode} no-results-subtext`}>
          Explore the app and add your first examples to the collection.
        </div>
        <div className={`${lightMode} no-collection-btn-container`}>
          <button
            className={`${lightMode} no-collections-black`}
            onClick={() => {
              if (isPlanLimitExceeded) {
                setIsUpgradePlanModalOpened(true);
              } else {
                setCreateCollectionModalOpen(true);
              }
            }}
          >
            <img src={plus} alt={"create"} />
            Create collection
          </button>
          <button
            className={`${lightMode} no-collections-white`}
            onClick={() => navigate("/Navigation/Flows")}
          >
            <img src={search} alt={"search"} />
            Explore examples
          </button>
        </div>
      </div>
    </>
  );
};

export default NoCollectionsFound;
