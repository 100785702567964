import NoResultsFound from "../../icons/NoResultsFound.svg";
import NoResultsFoundDark from "../../icons/NoResultsFoundDark.svg";
import { useAppSelector } from "../../store/hooks";
import "./NoResultsFoundPage.css";
import LockImg from "../../icons/lock.svg";
import LockImgDark from "../../icons/lock-dark.svg";
import { useState } from "react";
import UpgradePlanModal from "../UpgradePlanModal/UpgradePlanModal";

interface NoResultsFoundPageProps {
  planLimitExceeded?: boolean;
}

const NoResultsFoundPage = ({ planLimitExceeded }: NoResultsFoundPageProps) => {
  const [isUpgradePlanModalOpened, setIsUpgradePlanModalOpened] =
    useState<boolean>(false);

  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  return (
    <>
      <UpgradePlanModal
        onClose={() => setIsUpgradePlanModalOpened(false)}
        isOpen={isUpgradePlanModalOpened}
        type="limit"
      />
      <div className={`${lightMode} no-results-container`}>
        {!planLimitExceeded && (
          <>
            <div className={`${lightMode} no-results-img-container`}>
              <img
                style={{ height: "20px", width: "20px" }}
                src={
                  lightMode === "light" ? NoResultsFound : NoResultsFoundDark
                }
              />
            </div>
            <div className={`${lightMode} no-results-text`}>
              No results found here.
            </div>
            <div className={`${lightMode} no-results-subtext`}>
              Remove filters to show your items.
            </div>
          </>
        )}
        {planLimitExceeded && (
          <button
            className={`${lightMode} purchase-plan-locked no-results-locked`}
            onClick={() => setIsUpgradePlanModalOpened(true)}
          >
            <img
              src={lightMode === "light" ? LockImg : LockImgDark}
              alt={"locked"}
            />
            Upgrade to continue using UXMaps
          </button>
        )}
      </div>
    </>
  );
};

export default NoResultsFoundPage;
