import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import UpgradePlanSwitcher from "../UpgradePlanModal/UpgradePlanSwitcher";
import FreePlan from "../UpgradePlanModal/FreePlan";
import PersonalPlan from "../UpgradePlanModal/PersonalPlan";
import TeamPlan from "../UpgradePlanModal/TeamPlan";
import { useNavigate } from "react-router-dom";
import { Project } from "../../utils/types/project";
import { GetAllProjects } from "../../utils/backend/Project/GetAllProjects";
import Loader from "../Loader/Loader";
import ErrorPage from "../ErrorPage/ErrorPage";
import closeIconDark from "../../icons/close.svg";
import closeIcon from "../../icons/closeWhite.svg";
import { asyncNotification } from "../../store/slice/notificationSlice";

export enum PackageType {
  Personal = "personal",
  Team = "team",
}

const InitialBuyPlanPage = () => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  const [selectedPackageType, setSelectedPackageType] = useState<PackageType>(
    PackageType.Personal
  );
  const [latestProject, setLatestProject] = useState<Project>();
  const navigate = useNavigate();
  const [error, setError] = useState<string>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getLatestProject = async () => {
      const controller = new AbortController();
      const signal = controller.signal;
      const res = await GetAllProjects(signal);
      if (res.error) {
        setError(res.error);
        return;
      }
      if (res.response.length === 0) {
        setError("unexpected error occurred, please try to refresh screen");
        return;
      }

      setLatestProject(
        res.response.reduce((minItem, currentItem) => {
          return currentItem.createdAt < minItem.createdAt
            ? currentItem
            : minItem;
        })
      );
    };
    if (!latestProject) {
      getLatestProject().catch((err) => console.error(err));
    }
  }, [latestProject]);

  if (!latestProject) {
    return <Loader />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <div className={`${lightMode} upgrade-plan-page`}>
      <div className={`${lightMode} modal-content upgrade-plan page`}>
        <div className={`${lightMode} modal-content upgrade-plan`}>
          <div className={`${lightMode} upgrade-plan-header`}>
            <p className={`${lightMode} p-modal-header`}>Choose your plan</p>

            <div className={`${lightMode} upgrade-plan-header-right`}>
              <UpgradePlanSwitcher
                setSwitcher={(switchState: PackageType) =>
                  setSelectedPackageType(switchState)
                }
                switchState={selectedPackageType}
              />
              <div
                className={`${lightMode} filters-img-container upgrade-plan`}
                onClick={() => navigate("/Navigation/Screens")}
              >
                <img
                  alt="close"
                  src={lightMode === "light" ? closeIconDark : closeIcon}
                />
              </div>
            </div>
          </div>

          <div className={`${lightMode} upgrade-plan-content`}>
            <FreePlan
              activePlanType={selectedPackageType}
              isInitialPlanChoosing
              onGetStartedClicked={() => {
                dispatch(
                  asyncNotification({
                    notification: "Lifetime license ($97) available.",
                    isActive: true,
                  })
                );
                navigate("/Navigation/Screens");
              }}
            />
            {selectedPackageType === PackageType.Personal ? (
              <PersonalPlan />
            ) : (
              <TeamPlan isUserOnFreePlan={true} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialBuyPlanPage;
