import "../../companies/Companies/Companies.css";
import React, { useEffect, useState } from "react";
import { Company as CompanyType } from "../../../utils/types/company";
import { getCompaniesDataForDisplay } from "../ProductPage/initializeDataCompanies";
import { Tag } from "../../../utils/types/tag";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { usePrevious } from "../../../utils/usePrevious";
import { TagTypeType } from "../../../utils/backend/Company/TagByTagType";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { queryData } from "../../../utils/types/queryData";
import SourceDisplay from "./SourceDisplay";
import { setActiveFilters } from "../../../store/slice/filtersSlice";
import Loader from "../../Loader/Loader";
import NoResultsFoundPage from "../../NoResultsFoundPage/NoResultsFoundPage";
import ErrorPage from "../../ErrorPage/ErrorPage";

function compare(a: Tag, b: Tag) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

const SourcePage = () => {
  const allTagTypesWithTags = useAppSelector(
    (state) => state.tags.allTagTypesWithTags
  );
  const [tags, setTags] = useState<Tag[]>([]);
  const [industryTags, setIndustryTags] = useState<Tag[]>([]);
  const [source, setSource] = useState<CompanyType[]>([]);
  const [filteredSource, setFilteredSource] = useState<CompanyType[]>([]);
  const [queryState, setQueryState] = useState<queryData>({
    limit: -1,
    page: 0,
  });
  const shouldDisplayFilters: any = useAppSelector(
    (state) => state.filters.isFilterOpened
  );
  const [type, setType] = useState<string>("");
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("search-value");
  const industryIdParam = searchParams.get("tagList");
  const [error, setError] = useState<string | undefined>();
  const activeFilters = useAppSelector((state) => state.filters.activeFilters);
  const [isLoadingMore, setIsLoadingMore] = useState(true);
  const previousSearchParam = usePrevious(searchValue);
  const previousType = usePrevious(type);
  const [previousUrl, setPreviousUrl] = useState<Location>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    if (tags.length === 0) {
      setAllTags().catch((err) => {
        setError(err);
      });
    }
  }, [tags]);

  useEffect(() => {
    if (location.pathname !== previousUrl?.pathname) {
      setTags([]);
      setIndustryTags([]);
      setSource([]);
      setFilteredSource([]);
      setIsLoadingMore(true);
      setQueryState({ limit: -1, page: 0 });
    }
  }, [navigate, type]);

  useEffect(() => {
    if (source.length > 0 && industryIdParam) {
      setIsLoadingMore(true);
      dispatch(setActiveFilters([...activeFilters, industryIdParam]));
    }
  }, [industryIdParam, source]);

  const setAllTags = async () => {
    setTags(
      (
        allTagTypesWithTags.find((tt) => {
          if (window.location.pathname.includes("/Design")) {
            setType("/Design");
            return tt.tagType.name === TagTypeType.TagTypeDesignElements;
          } else {
            setType("/Companies");
            return tt.tagType.name === TagTypeType.TagTypeCompany;
          }
        })?.tagList ?? []
      )
        .filter((tag) => {
          return tag.noOfScreens && tag.noOfScreens > 0;
        })
        .sort(compare)
    );
    setIndustryTags(
      allTagTypesWithTags.find((tt) => {
        return tt.tagType.name === TagTypeType.TagTypeIndustry;
      })?.tagList ?? []
    );
  };

  const getCompaniesBySearchValue = async (
    selectedIndustryFilters: string[]
  ) => {
    let newCompaniesIds: string[] = [];

    if (
      searchValue &&
      window.location.pathname.includes("/Navigation/Design")
    ) {
      const tagList: Array<Tag> = [];

      tags.map((screen: any) =>
        screen.name
          .toLowerCase()
          .split(" ")
          .map((word: string) => {
            if (word.startsWith(searchValue.toLowerCase())) {
              tagList.push(screen);
              return true;
            }

            return false;
          })
      );

      newCompaniesIds = tagList.map((el: any) => el.id);
    } else {
      tags.map((company) => {
        selectedIndustryFilters.map((filter) => {
          if (company.tagList.some((tag) => tag.id === filter)) {
            newCompaniesIds.push(company.id);
          }
        });
      });
    }
    if (newCompaniesIds.length === 0) return;
    const result = await getCompaniesDataForDisplay(newCompaniesIds);
    const companyList: CompanyType[] = [];

    result.forEach((data, key) => {
      const actualCompany = tags.find((c) => c.id === key);

      if (!actualCompany) return;
      const newCompany: CompanyType = {
        id: key,
        contentList: result.get(key) || [],
        name: actualCompany.name,
        image: actualCompany.imageS3URL ?? "",
        description: actualCompany.description ?? "",
      };
      companyList.push(newCompany);
    });

    setSource(
      companyList.sort((a: CompanyType, b: CompanyType) => {
        if (a.name > b.name) return 1;
        return -1;
      })
    );
    setPreviousUrl(location);
    setIsLoadingMore(false);
  };

  const getCompaniesBasedOnActiveFilters = async (
    selectedIndustryFilters: string[],
    selectedCompanyFilters: string[]
  ) => {
    setIsLoadingMore(true);
    let newCompaniesIds: Array<string> = [];
    if (selectedIndustryFilters.length === 0) {
      newCompaniesIds = tags
        .filter((obj) => selectedCompanyFilters.includes(obj.id))
        .map((c) => c.id);
    } else if (
      selectedIndustryFilters.length > 0 &&
      selectedCompanyFilters.length === 0
    ) {
      newCompaniesIds = tags
        .filter((obj) =>
          obj.tagList.some(
            (tag) =>
              selectedIndustryFilters.length === 0 ||
              selectedIndustryFilters.includes(tag.id)
          )
        )
        .map((c) => {
          return c.id;
        });
    } else {
      newCompaniesIds = tags
        .filter((obj) =>
          obj.tagList.some(
            (tag) =>
              selectedIndustryFilters.length === 0 ||
              selectedIndustryFilters.includes(tag.id)
          )
        )
        .filter((obj) => selectedCompanyFilters.includes(obj.id))
        .map((c) => c.id);
    }

    if (newCompaniesIds.length === 0) {
      setIsLoadingMore(false);
    }
    let companyList: CompanyType[] = [];

    const copiedSource = [...source];

    companyList = copiedSource
      .filter((source: CompanyType) => newCompaniesIds.includes(source.id))
      .sort((a: CompanyType, b: CompanyType) => {
        if (a.name > b.name) return 1;
        return -1;
      });

    setFilteredSource(
      activeFilters.length > 0
        ? companyList.sort((a: CompanyType, b: CompanyType) => {
            if (a.name > b.name) return 1;
            return -1;
          })
        : source
    );
    setIsLoadingMore(false);
  };

  useEffect(() => {
    setAllTags();
  }, [allTagTypesWithTags]);

  const getNewCompaniesToDisplay = async () => {
    if (tags.length === 0) {
      setAllTags();
    }
    const selectedIndustryFilters =
      source.length === 0
        ? []
        : activeFilters.filter((filter) => {
            return !!industryTags.find((tag) => {
              return tag.id === filter;
            });
          });

    let newCompaniesIds: Array<string> = [];

    if (selectedIndustryFilters.length > 0) {
      newCompaniesIds = tags
        .filter((obj) =>
          obj.tagList.some(
            (tag) =>
              selectedIndustryFilters.length === 0 ||
              selectedIndustryFilters.includes(tag.id)
          )
        )
        .map((c) => c.id);
    } else {
      newCompaniesIds =
        queryState.limit !== -1 ? tags.map((c) => c.id) : tags.map((c) => c.id);
    }
    if (newCompaniesIds.length === 0 && source.length === 0) {
      return;
    }
    let companyList: CompanyType[] = [];
    const newCompanies = await getCompaniesDataForDisplay(newCompaniesIds);
    newCompanies.forEach((data, key) => {
      const actualCompany = tags.find((c) => c.id === key);

      if (!actualCompany) return;
      const newCompany: CompanyType = {
        id: key,
        contentList: newCompanies.get(key) || [],
        name: actualCompany.name,
        image: actualCompany.imageS3URL ?? "",
        description: actualCompany.description ?? "",
      };
      companyList.push(newCompany);
    });
    setSource((prev) => {
      return [
        ...prev,
        ...companyList.filter((company) => {
          return !prev.some((prevCompany) => prevCompany.id === company.id);
        }),
      ].sort((a: CompanyType, b: CompanyType) => {
        if (a.name > b.name) return 1;
        return -1;
      });
    });

    setPreviousUrl(location);
    setIsLoadingMore(false);
  };

  const getSourceWithSearchValue = () => {
    if (tags.length === 0) return;

    const dataArray: any = [];
    industryTags.map((tag: any) =>
      tag.name
        .toLowerCase()
        .split(" ")
        .map((word: any) => {
          if (word.startsWith(searchValue?.toLowerCase())) {
            dataArray.push(tag.id);
            return true;
          }

          return false;
        })
    );

    getCompaniesBySearchValue(dataArray);
  };

  const getSources = () => {
    getNewCompaniesToDisplay();
  };

  useEffect(() => {
    setIsLoadingMore(true);
    if (tags.length === 0) {
      return;
    }

    if (previousType === type) {
      if (source.length === 0 && !previousUrl) {
        if (searchValue) {
          getSourceWithSearchValue();
        } else {
          getSources();
        }
      } else if (location.pathname !== previousUrl?.pathname) {
        setSource([]);
        if (searchValue) {
          getSourceWithSearchValue();
        } else {
          getSources();
        }
      } else {
        if (searchValue || previousSearchParam !== searchValue) {
          getSourceWithSearchValue();
        } else {
          getSources();
        }
      }
    }
  }, [tags, searchValue]);

  useEffect(() => {
    if (source.length > 0) {
      if (activeFilters.length > 0) {
        const selectedIndustryFilters = activeFilters.filter((filter) => {
          return !!industryTags.find((tag) => {
            return tag.id === filter;
          });
        });
        const selectedCompanyFilters = activeFilters.filter((filter) => {
          return !!tags.find((tag) => {
            return tag.id === filter;
          });
        });
        getCompaniesBasedOnActiveFilters(
          selectedIndustryFilters,
          selectedCompanyFilters
        );

        return;
      } else {
        setFilteredSource(source);
      }
    }
  }, [activeFilters, source, industryIdParam]);

  const loadMore = () => {
    setQueryState((prev) => {
      return {
        page: isLoadingMore ? prev.page : prev.page + 1,
        limit: -1,
      };
    });
  };

  if (error) {
    return <ErrorPage />;
  }

  if (source.length === 0 && !isLoadingMore) {
    return <NoResultsFoundPage />;
  }

  return (
    <>
      {isLoadingMore ? (
        <Loader />
      ) : (
        <SourceDisplay
          onLoadMore={loadMore}
          source={source}
          filteredSource={filteredSource}
          error={error}
          tags={tags}
          pages={queryState.page}
          shouldDisplayFilters={shouldDisplayFilters}
          noMoreData={
            source.length !== queryState.limit * (queryState.page + 1) ||
            activeFilters.length !== 0
          }
          isLoadingMore={isLoadingMore}
        />
      )}
    </>
  );
};

export default SourcePage;
