import { store } from "../../../store/store";
import { Fetch, FetchInput, Method } from "../../Fetch";
import { Flow, toFlow } from "../../types/flow";

export interface FetchFlowRequest {
  id: string;
  signal?: AbortSignal;
}

export interface FetchFlowResponse {
  response?: Flow;
  error: string;
}

export async function FetchFlow(
  request: FetchFlowRequest
): Promise<FetchFlowResponse> {
  const state = store.getState();
  const projectId = state.navigation.projectId;

  if (!projectId || projectId.length === 0) {
    return {
      error: "No project id selected",
    };
  }
  const FetchFlowURL = `/me/example?exampleId=${request.id}&projectId=${projectId}`;
  try {
    const input: FetchInput = {
      method: Method.Get,
      url: FetchFlowURL,
      signal: request.signal,
    };

    const result = await Fetch(input);
    if (result.isOk) {
      const response = await result;
      if (response) {
        return {
          response: await toFlow(response.result.result),
          error: "",
        };
      }
      return {
        error: "Server side error",
      };
    } else {
      return {
        error: result.error?.message ?? "",
      };
    }
  } catch (err) {
    console.error(err);
    return {
      error: "error",
    };
  }
}
