import "./BoardSettings.css";
import { useEffect, useState } from "react";
import {
  UpdateWorkspace,
  UpdateWorkspaceRequest,
} from "../../utils/backend/Workspace/UpdateWorkspace";
import {
  WorkspaceById,
  WorkspaceByIdRequest,
} from "../../utils/backend/Workspace/WorkspaceById";
import { Workspace } from "../../utils/types/workspace";
import Sidebar from "../SettingsSideBar/Sidebar";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setSelectedBoard,
  setSelectedProject,
} from "../../store/slice/workspacesSlice";
import {
  getImageFromCacheOrFromURL,
  imageDataToBase64String,
} from "../../utils/image";
import { asyncNotification } from "../../store/slice/notificationSlice";
import ErrorPage from "../ErrorPage/ErrorPage";
import InvitePeople from "./InvitePeople";
import Team from "./Team/Team";
import { RemoveInvitation } from "../../utils/backend/Workspace/RemoveInvitation";
import Loader from "../Loader/Loader";
import {
  InviteToBoardRequestWithLink,
  InviteToBoardWithLink,
} from "../../utils/backend/Invites/InviteToBoardWithLink";
import Avatar from "react-avatar";

const Superboard = () => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  const boardId = useAppSelector((state) => state.navigation.boardId);
  const [workspace, setWorkspace] = useState<Workspace>();
  const [error, setError] = useState<string>();
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(
    (state) => state.boards.selectedProject
  );
  const selectedBoard = useAppSelector((state) => state.boards.selectedBoard);
  const [isLoading, setIsLoading] = useState(false);
  const userId = useAppSelector((state) => state.auth.id);
  const userRole = useAppSelector(
    (state) =>
      state.boards.selectedBoard?.members.find((u) => u.id === userId)?.role
  );
  const watchedBoard =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const init = async function () {
      try {
        setIsLoading(true);
        const req: WorkspaceByIdRequest = {
          id: watchedBoard ? watchedBoard : boardId,
          signal,
        };
        const result = await WorkspaceById(req);
        if (result.error.length > 0) {
          setError(
            "Something unexpected happened, try to reload page or contact us."
          );
          console.error(result.error);
          return;
        }

        if (result.response.image) {
          const img = await getImageFromCacheOrFromURL(
            result.response.image,
            result.response.id
          );
          if (img) {
            result.response.imageData = img;
          }
        }
        setWorkspace(result.response);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setError(
          "Something unexpected happened, try to reload page or contact us."
        );
        console.error(e);
      }
    };

    init().catch((e) => {
      setError(
        "Something unexpected happened, try to reload page or contact us."
      );
      console.error(e);
    });
  }, [boardId, watchedBoard]);

  const showUpdateBtn = () => {
    dispatch(
      asyncNotification({ notification: "Board updated.", isActive: true })
    );
  };

  const saveChanges = async () => {
    setIsLoading(true);
    let imageBase64: string | undefined = undefined;

    if (!workspace) return;

    if (workspace.image) {
      imageBase64 = await imageDataToBase64String(workspace.image);
    }

    const UpdateWorkspaceReq: UpdateWorkspaceRequest = {
      id: workspace.id,
      name: workspace?.name,
      image: imageBase64,
      website: workspace.website,
    };

    try {
      const result = await UpdateWorkspace(UpdateWorkspaceReq);
      if (result.error.length > 0) {
        setError(
          "Something unexpected happened, try to reload page or contact us."
        );
        console.error(result.error);
        return;
      }
      showUpdateBtn();

      if (selectedProject)
        dispatch(
          setSelectedProject({
            ...selectedProject,
            boards: selectedProject.boards?.map((b) => {
              if (b.id !== workspace.id) return b;
              return {
                ...b,
                name: workspace.name,
                imageData: workspace.imageData,
                image: imageBase64,
                website: workspace.website,
              };
            }),
          })
        );

      if (selectedBoard && selectedBoard.id === workspace.id) {
        dispatch(
          setSelectedBoard({
            ...selectedBoard,
            name: workspace.name,
            imageData: workspace.imageData,
            website: workspace.website,
            image: imageBase64 ?? undefined,
          })
        );
      }
    } catch (e) {
      setError(
        "Something unexpected happened, try to reload page or contact us."
      );
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const loadFile = async (event: any) => {
    const image = document.getElementById("output") as HTMLImageElement;
    const newOmg = URL.createObjectURL(event.target.files[0]);

    if (image !== null) {
      image.src = newOmg;
    }
    setWorkspace((prevState) => {
      if (!prevState) return prevState;
      return { ...prevState, image: newOmg };
    });
  };

  const onInvite = (users: any) => {
    if (workspace) {
      setWorkspace({ ...workspace, invites: [...workspace.invites, ...users] });
      dispatch(
        setSelectedBoard({
          ...workspace,
          invites: [...workspace.invites, ...users],
        })
      );
    }
  };

  const removeInv = async (member: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      let req;
      if (workspace && member) {
        req = {
          inviteID: member.id,
          boardID: workspace.id,
          signal: signal,
        };
        const result = await RemoveInvitation(member.id, workspace.id, signal)
          .then((res) => {
            setWorkspace({
              ...workspace,
              invites: workspace.invites.filter((inv) => inv.id !== member.id),
            });
            dispatch(
              setSelectedBoard({
                ...workspace,
                invites: workspace.invites.filter(
                  (inv) => inv.id !== member.id
                ),
              })
            );
            dispatch(
              asyncNotification({
                notification: "Invitation removed.",
                isActive: true,
              })
            );
          })
          .catch((err) => {
            dispatch(
              asyncNotification({
                notification: "Invitation removal failed.",
                isActive: true,
              })
            );
          });
      }
    } catch (error) {}
  };

  const onInviteWithLink = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      const req: InviteToBoardRequestWithLink = {
        boardId: watchedBoard,
        role: "member",
        signal: signal,
      };
      InviteToBoardWithLink(req);
    } catch (error) {}
  };

  if (isLoading) return <Loader />;
  return (
    <>
      <div className={`${lightMode} board-settings`}>
        <Sidebar />
        {(error && error.length > 0) || !workspace ? (
          <ErrorPage />
        ) : (
          <div
            style={{
              marginLeft: "20px",
              display: "grid",
              gap: "25px",
              justifyItems: "center",
              width: "100%",
            }}
          >
            <div className={`${lightMode} customize-board-section`}>
              <div className={`${lightMode} intro`}>
                <h2>Customize board</h2>
                <h3>Customize how you appear to your teammates.</h3>
              </div>
              <h2 className={`${lightMode} settings-header-photo`}>
                Board photo
              </h2>
              <div className={`${lightMode} img-details`}>
                {workspace.image ? (
                  <img
                    id="output"
                    width="40px"
                    alt="user-icon"
                    src={workspace.image || ""}
                  />
                ) : (
                  <Avatar
                    key={"output"}
                    name={workspace.name ? workspace.name[0].toUpperCase() : ""}
                    initials={
                      workspace.name ? workspace.name[0].toUpperCase() : ""
                    }
                    className={`${lightMode} workspace-avatar small-rounded`}
                    value="1"
                    maxInitials={1}
                    textSizeRatio={2}
                    color="#f2f4f7"
                    size="40px"
                    fgColor="rgba(71, 84, 103, 1)"
                    src={workspace.image ?? ""}
                  />
                )}

                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  id="file"
                  hidden
                  onChange={(e) => loadFile(e)}
                />
                {userRole === "admin" && (
                  <label
                    htmlFor="file"
                    className={`${lightMode} change-img-button`}
                  >
                    Change
                  </label>
                )}
              </div>
              <label htmlFor="name" className={`${lightMode} label-class`}>
                Name
              </label>
              <input
                disabled={userRole !== "admin"}
                type="text"
                id="name"
                placeholder="UXMaps"
                className={`${lightMode} board-name-input`}
                onChange={(e) => {
                  setWorkspace((prev) => {
                    if (!prev) return undefined;
                    return {
                      ...prev,
                      name: e.target.value,
                    };
                  });
                }}
                value={workspace.name}
              />
              <h4 className={`${lightMode} just-another-random-div`}>
                Names can be up to 32 characters
              </h4>
              <div className={`${lightMode} save-section-dash`}></div>
              <div className={`${lightMode} save-button-container`}>
                {userRole === "admin" && (
                  <button
                    className={`${lightMode} save-button`}
                    onClick={saveChanges}
                    disabled={isLoading || userRole !== "admin"}
                    style={{ marginBottom: "10px" }}
                  >
                    {!isLoading ? "Save" : "Loading..."}
                  </button>
                )}
              </div>
            </div>
            <div className={`${lightMode} invite-section`}>
              {workspace.members && (
                <Team
                  userRole={userRole}
                  teamType="board"
                  members={workspace.members}
                  removeInvitation={(member) => removeInv(member)}
                  invites={workspace.invites}
                />
              )}
              {workspace.members.find((user) => user.id === userId) &&
                userRole === "admin" && (
                  <InvitePeople
                    boardId={workspace.id}
                    boardType="board"
                    onInvite={(user: any) => onInvite(user)}
                    onInviteWithLink={() => onInviteWithLink()}
                  />
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Superboard;
