import down from "../../icons/chevron-right.svg";
import downDark from "../../icons/chevron-right-dark.svg";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";

const SingleInvite = (props: any) => {
	const [state, setState] = useState({
		id: props.index,
		email: "",
		role: "member",
	});
	const lightMode = useAppSelector((state) => state.navigation.lightMode);
	const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
		setState((prev) => {
			return { ...prev, email: event.target.value };
		});
	};

	useEffect(() => {
		if (props.restart) {
			setState({ id: 0, email: "", role: "member" });
			props.restartCb();
		}
	}, [props.restart]);

	const handleRoleChange = (role: string) => {
		setState((prev) => {
			return { ...prev, role: role };
		});
	};

	useEffect(() => {
		props.update(state.id, state.email, state.role);
	}, [state]);

	useEffect(() => {
		const optionsContainer = document.querySelector(
			`.board-role-options-${props.index} `
		);
		const selectedRole = document.querySelector(
			`.selected-board-role-${props.index}`
		);
		const head = document.querySelector(`.board-role-input-${props.index}`);
		head?.addEventListener("click", () => {
			optionsContainer?.classList.toggle("hide-board-role-options");
		});

		const options = document.querySelectorAll(
			`.board-role-option-${props.index}`
		);
		options.forEach((option) => {
			option.addEventListener("click", () => {
				options.forEach((opt) => {
					if (opt.classList.contains(`selected-role-option`)) {
						opt.classList.remove(`selected-role-option`);
					}
				});
				option.classList.add(`selected-role-option`);
				if (selectedRole && selectedRole.textContent !== null && selectedRole) {
					selectedRole.textContent = option.textContent;

					handleRoleChange(selectedRole?.textContent ?? "");
				}

				optionsContainer?.classList.add("hide-board-role-options");
			});
		});
	}, []);
	return (
		<div className={`invitation invitation-${props.index} `}>
			<div className={`${lightMode} email-container`}>
				<input
					type="text"
					id="email-inv"
					placeholder="Email address"
					className={`${lightMode} board-email-input board-email-input-${props.index}`}
					onChange={handleEmailChange}
					value={state.email}
				/>
			</div>
			<div className={`${lightMode} role-container`}>
				<div className={`${lightMode} member-role-container`}>
					<div className={`${lightMode} role-container`}>
						<div className={`${lightMode} member-role-container`}>
							<div
								className={`${lightMode} board-role-input board-role-input-${props.index}`}>
								<h3
									className={`selected-board-role selected-board-role-${props.index}`}>
									Member
								</h3>
								<img
									className={`${lightMode} down`}
									src={lightMode === "light" ? down : downDark}
									alt=""
								/>
							</div>
							<div
								className={`board-role-options board-role-options-${props.index} hide-board-role-options`}>
								<h3
									className={`board-role-option board-role-option-${props.index}`}>
									Member
								</h3>
								<h3
									className={`board-role-option board-role-option-${props.index}`}>
									Admin
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default SingleInvite;
