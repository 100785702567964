import React from "react";
import { useAppSelector } from "../../store/hooks";
import checked from "../../icons/checkedLight.svg";
import checkedDark from "../../icons/checkedDark.svg";
import {
  SendPlan,
  SendPlanRequest,
} from "../../utils/backend/SendPlan/SendPlan";

const inclusions = [
  "Unlimited screens",
  "Unlimited companies, flows, and elements",
  "Unlimited boards",
  "Unlimited collections",
  "One-time payment",
];

interface PlanProps {
  projId?: string;
}

const PersonalPlan = ({ projId }: PlanProps) => {
  const lightMode = useAppSelector((state) => state.navigation.lightMode);
  const workspaceId = useAppSelector(
    (state) => state.boards.selectedProject?.id
  );

  const upgradePlan = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    const req: SendPlanRequest = {
      CancelURL: `${window.location.origin}/Navigation/Screens/?success=false&quantity=1`,
      quantity: 1,
      SuccessURL: `${window.location.origin}/Navigation/Screens/?success=true&quantity=1`,
      SuperBoardID: workspaceId! || projId!,
      signal: signal,
    };

    const result = await SendPlan(req);
    if (result.response && result.response) {
      window.location.href = result.response;
    }
  };

  return (
    <div className={`${lightMode} plan-card-container`}>
      <div className={`${lightMode} plan-card-header`} id={"personal-header"}>
        <h1 className={`${lightMode} plan-card-header-large`}>
          Lifetime License
        </h1>
        <h2 className={`${lightMode} plan-card-header-small`}>
          USD $97
          <p className={`${lightMode} plan-card-header-small gray`}>once</p>
        </h2>
      </div>
      <div className={`${lightMode} plan-card-inclusion`} id={"personal-body"}>
        {inclusions.map((e) => {
          return (
            <div className={`${lightMode} one-incl`}>
              <img src={lightMode === "light" ? checked : checkedDark} />
              <p
                style={
                  e === "One-time payment"
                    ? { margin: 0, fontWeight: 600 }
                    : { margin: 0 }
                }
              >
                {e}
              </p>
            </div>
          );
        })}
      </div>
      <div className={`${lightMode} plan-card-footer`}>
        <button
          onClick={upgradePlan}
          className={`${lightMode} plan-card-button activate`}
        >
          Unlock full access
        </button>
      </div>
    </div>
  );
};

export default PersonalPlan;
